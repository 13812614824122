import { UserModel } from '../models/user.model';
import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(private router: Router) { }

    public getUser(): UserModel {
        let token ;
        if(localStorage.getItem("AdminToken")){
             token = localStorage.getItem("AdminToken");
        }
        else{
             token = localStorage.getItem("AdminToken");
        }
       
        if (token == null) {
            this.router.navigate(["/auth/login"]);
        }
        let jwtToken = jwt_decode(token);

        
        return <UserModel>JSON.parse(JSON.stringify(jwtToken));
    }


    public jwtToUser(token:any):UserModel{
        let jwtToken = jwt_decode(token);
        return <UserModel>JSON.parse(JSON.stringify(jwtToken));
    }
    
}