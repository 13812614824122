import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-country',
  templateUrl: './create-country.component.html',
  styleUrls: ['./create-country.component.scss']
})
export class CreateCountryComponent implements OnInit {
  isAdding = false;
  CreateCountryModel: any = {};
  CountryList;
  myDate;

  constructor(private router: Router, private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    this.myDate = new Date();
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['CountryDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }


  AddCountry() {
    this.isAdding = true;
    const body = {
      Name: this.CreateCountryModel.Name, Code: this.CreateCountryModel.Code,Code3: this.CreateCountryModel.Code3, PhoneCode: this.CreateCountryModel.PhoneCode
    };
    this.http.post<any>(environment.url + 'Country/AddCountry', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Ülke Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/country-list"]);
      }
    },
      error => {
        this.toastr.error('Ülke Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
