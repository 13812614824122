import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstagramListComponent } from './instagram-list/instagram-list.component';
import { InstagramDetailComponent } from './instagram-detail/instagram-detail.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'instagram-list',
        component: InstagramListComponent,
        data: {
          title: "Instagram List",
          breadcrumb: "Instagram List"
        }
      },
      {
        path: 'instagram-detail',
        component: InstagramDetailComponent,
        data: {
          title: "Instagram List",
          breadcrumb: "Instagram List"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstagramRoutingModule { }
