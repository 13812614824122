import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { CMSRoutingModule } from './cms-routing.module';
import { CreatePageComponent } from './create-page/create-page.component';
import { EditPageComponent } from './edit-page/edit-page.component';
import { EditContactPageComponent } from './edit-contact-page/edit-contact-page.component';
import { TextContentsComponent } from './text-contents/text-contents.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { EditPopUpContentsComponent } from './pop-up-contents/edit-pop-up/edit-pop-up-content.component';
import { AddPopUpContentsComponent } from './pop-up-contents/add-pop-up/add-pop-up-content.component';
import { PopUpContentsComponent } from './pop-up-contents/pop-up-list/pop-up-contents.component';
import { ContactContentsComponent } from './contact-contents/contact-contents.component';
import { MailContentListComponent } from './mail-contents/mail-content-list/mail-content-list.component';
import { EditMailContentComponent } from './mail-contents/edit-mail-content/edit-mail-content.component';
import { CreateMailContentComponent } from './mail-contents/create-mail-content/create-mail-content.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [EditPageComponent, CreatePageComponent, TextContentsComponent,EditContactPageComponent,ContactContentsComponent,
    MailContentListComponent, EditMailContentComponent , CreateMailContentComponent,PopUpContentsComponent,EditPopUpContentsComponent,AddPopUpContentsComponent
  ],
  imports: [
    CommonModule,
    CMSRoutingModule,
    NgSelectModule,
    NgbModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgxDatatableModule
  ]
})
export class CMSModule { }
