import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {

  public menus = [];
  public selected = [];
  PaymentList;
  isLoading;
  isDeleting;
  DeletingItem;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['PaymentTypesDefinitions']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.GetPaymentList();
  }

  GetPaymentList() {
    this.http.get<any>(environment.url + 'Payment/GetPaymentTypes').subscribe(data => {
      this.PaymentList = data;
      this.isLoading = true;
    });
  }
  DeletePayment() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItem };
    this.http.post<any>(environment.url + 'Payment/DeletePaymentTypes', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetPaymentList();
      if (!data.Error) {
        this.modalService.dismissAll();
        this.toastr.success("Ödeme Tipi Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Ödeme Tipi Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  gotoPaymentEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/definitions/edit-payment'], navigatioExtra);
  }
  DeletePaymentModal(file,ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
}
