<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Account Menu Lists</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <div class="clearfix"></div>

            <div class="table-responsive-sm">
              <table class="table" style="text-align: center;">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Status</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Account</td>
                    <td><i class="fa fa-circle font-warning f-12"></i></td>
                    <td><a type="button" (click)="gotoAccountMenuEdit(1)"><i class="fa fa-edit"
                          style="font-size: 17px;"></i></a></td>
                    <td><a type="button" (click)="DeleteAccountMenu(1)"><i class="fa fa-trash"
                          style="font-size: 17px;"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>