import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';
import { animate, style, transition, trigger } from '@angular/animations';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-edit-contact-page',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './edit-contact-page.component.html',
  styleUrls: ['./edit-contact-page.component.scss']
})
export class EditContactPageComponent implements OnInit {
  ContactModel: any = {};
  id;
  isUploadImage;
  imageSrc;


  SocialMedias: any = [];
  isUploadSocialImage;
  EditSocailMediaItem: any = {};
  isEditingSocialMedia;
  EditSocialMediaRef;
  isLoadingContact;

  isEditingContactPage;

  DeletingItem: any = {};
  IsDeletedSocialMediaItem;

  constructor(private activeRoute: ActivatedRoute,
    private modalService: NgbModal, private router: Router, private toastr: ToastrService, private TokenService: TokenService, private http: HttpClient) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['ContactContents']) {
      router.navigateByUrl('/');
    }
  }




  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => { this.id = x["id"]; });
    this.GetContact();
  }

  GetContact() {
    this.isLoadingContact = false;
    this.http.get<any>(environment.url + 'ContactPage/GetContactPages').subscribe(data => {
      data.Data.forEach(element => {
        if (element.contactPage.Ref == this.id) {
          setTimeout(() => {
            this.ContactModel = element.contactPage;
            this.SocialMedias = element.socialMedias;
            this.imageSrc = element.contactPage.ImageLocation;
            this.isLoadingContact = true;
          }, 1000);

        }
      });
    });
  }


  addNewSocialMediaItem() {
    this.SocialMedias.push({ New: true, Ref: this.SocialMedias.length ? this.SocialMedias[this.SocialMedias.length - 1].Ref + 1 : 0, Status: false, OrderNo: null, SocialMedia: null, URL: null, SocialImageURL: null, ContactPageRef: Number(this.id) });
    this.isEditingSocialMedia = true;
    this.EditSocialMediaRef = this.SocialMedias[this.SocialMedias.length - 1].Ref;

  }
  EditSocialMedia(ref) {
    this.isEditingSocialMedia = true;
    this.EditSocialMediaRef = ref;

    let index = this.SocialMedias.indexOf(this.SocialMedias.find(x => x.Ref == ref));
    this.EditSocailMediaItem = this.SocialMedias[index];
  }
  SaveSocialMedia() {
    let index = this.SocialMedias.indexOf(this.SocialMedias.find(x => x.Ref == this.EditSocialMediaRef));
    this.EditSocailMediaItem.ContactPageRef = Number(this.id);
    this.EditSocailMediaItem.Ref = this.EditSocialMediaRef;
    this.SocialMedias[index] = this.EditSocailMediaItem;

    this.isEditingSocialMedia = false;
    this.EditSocialMediaRef = null;
    this.EditSocailMediaItem = {};

  }
  CancelSocialMedia(ref) {
    let index = this.SocialMedias.indexOf(this.SocialMedias.find(x => x.Ref === ref));
    if (this.SocialMedias[index].New) this.SocialMedias.splice(index, 1);

    this.isEditingSocialMedia = false;
    this.EditSocialMediaRef = null;
    this.EditSocailMediaItem = {};
  }

  DeleteSocialMediaItem(modal, item) { // Open Edit Activity Modal
    this.modalService.open(modal, { centered: true });
    this.DeletingItem = item;
  }
  DeleteSocialMedia() {
    this.IsDeletedSocialMediaItem = true;
    this.http.post<any>(environment.url + "ContactPage/DeleteSocialMedia" , this.DeletingItem).subscribe(data => {
      if (data.Success == true) {
        this.IsDeletedSocialMediaItem = false;
        this.modalService.dismissAll(); 
        let index = this.SocialMedias.indexOf(this.SocialMedias.find(x => x.Ref == this.DeletingItem.Ref));
        this.SocialMedias.splice(index, 1);
        this.toastr.success("Sosyal Medya Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.IsDeletedSocialMediaItem = false;
        this.toastr.error("Sosyal Medya Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }

    });
  }
  DeleteSocialMediaClose() { // Delete Modal Close
    this.modalService.dismissAll();
    this.DeletingItem = {};
  }



  EditContactPage() {
    this.isEditingContactPage = true;
    if (this.SocialMedias.length) {
      this.SocialMedias.forEach((element, index) => {

        if (element.New) {
          this.SocialMedias[index].Ref = 0;
        }
        if (this.SocialMedias.length - 1 == index) {

          const body = { Ref: this.id, contactPage: this.ContactModel, socialMedias: this.SocialMedias };
          this.http.post<any>(environment.url + 'ContactPage/EditContactPage', body).subscribe(data => {
            if (data) {
              this.isEditingContactPage = false;
              this.toastr.success("İletişim Sayfası Güncellendi.", '', {
                timeOut: 3000,
              });
            }
            else {
              this.isEditingContactPage = false;
              this.toastr.error("İletişim Sayfası Güncellenemedi.", 'Hata !', {
                timeOut: 3000,
              });
            }
          });
        }
      });
    }
    else {
      const body = { Ref: this.id, contactPage: this.ContactModel, socialMedias: this.SocialMedias };
      this.http.post<any>(environment.url + 'ContactPage/EditContactPage', body).subscribe(data => {
        if (data) {
          this.isEditingContactPage = false;
          this.toastr.success("İletişim Sayfası Güncellendi.", '', {
            timeOut: 3000,
          });
        }
        else {
          this.isEditingContactPage = false;
          this.toastr.error("İletişim Sayfası Güncellenemedi.", 'Hata !', {
            timeOut: 3000,
          });
        }
      });
    }

  }

  openImage() { // Click Image File Button
    let element: HTMLElement = document.getElementById('addimage') as HTMLElement;
    element.click();
  }
  addImageChange(files: FileList) { // Show Image File on change 
    this.isUploadImage = true;

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http.post<any>(environment.url + 'ContactPage/UploadImage', formData).subscribe(data => {
      this.isUploadImage = false;
      if (data) {
        if (files) {
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.ContactModel.ImageLocation = data;
          };
        }
        this.toastr.success("Dosya Yüklendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Dosya Yüklenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });

  }

  addSocialImage() { // Click Image File Button
    let element: HTMLElement = document.getElementById('addSocialimage') as HTMLElement;
    element.click();
  }
  addSocialImageChange(files: FileList) { // Show Image File on change 
    this.isUploadSocialImage = true;

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http.post<any>(environment.url + 'ContactPage/UploadImage', formData).subscribe(data => {
      this.isUploadSocialImage = false;
      if (data) {
        if (files) {
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
            let index = this.SocialMedias.indexOf(this.SocialMedias.find(x => x.Ref == this.EditSocialMediaRef));
            this.EditSocailMediaItem.SocialImageURL = data;
            this.SocialMedias[index].SocialImageURL = data;
          };
        }
        this.toastr.success("Dosya Yüklendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Dosya Yüklenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });

  }

}
