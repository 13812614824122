import { Component } from '@angular/core';
import { TokenService } from './shared/service/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vipstendo-adminui';
  FirstName;
  LastName;

  constructor(
    private tokenService: TokenService) { }


  ngOnInit(): void {

  }
}
