import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { InstagramRoutingModule } from './instagram-routing.module';
import { InstagramListComponent } from './instagram-list/instagram-list.component';
import { InstagramDetailComponent } from './instagram-detail/instagram-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [ InstagramDetailComponent,InstagramListComponent],
  imports: [
    CommonModule,
    InstagramRoutingModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    NgxDatatableModule
  ]
})
export class InstagramModule { }
