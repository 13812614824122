import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-additional-services',
  templateUrl: './create-additional-services.component.html',
  styleUrls: ['./create-additional-services.component.scss']
})
export class CreateAdditionalServicesComponent implements OnInit {
  isAdding = false;
  CreateAdditionalServicesModel: any = {};
  AdditionalServicesList;
  myDate;
  isUploadImage;
  imageSrc;

  constructor(private router: Router, private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    this.myDate = new Date();
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['ServiceDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }


  AddAdditionalServices() {
    this.isAdding = true;
    const body = {
      Ref : this.CreateAdditionalServicesModel.Ref , NameTR : this.CreateAdditionalServicesModel.NameTR ,
      NameEN : this.CreateAdditionalServicesModel.NameEN , NameRU : this.CreateAdditionalServicesModel.NameRU ,
      PhotoURL : this.CreateAdditionalServicesModel.PhotoURL 
    };
    this.http.post<any>(environment.url + 'AdditionalService/Add', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Hizmet Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/additional-services-list"]);
      }
    },
      error => {
        this.toastr.error('Hizmet Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

  openImage() { // Click Image File Button
    let element: HTMLElement = document.getElementById('addimage') as HTMLElement;
    element.click();
  }
  addImageChange(files: FileList) { // Show Image File on change 
    this.isUploadImage = true;

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http.post<any>(environment.url + 'AdditionalService/UploadImage', formData).subscribe(data => {
      this.isUploadImage = false;
      if (data) {
        if (files) {
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.CreateAdditionalServicesModel.PhotoURL = data;
          };
        }
        this.toastr.success("Resim Yüklendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Resim Yüklenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });

  }
}
