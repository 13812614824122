<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card">

        <ngb-tabset class="tab-coupon">
          <ngb-tab title="Mail Şablonları">
            <ng-template ngbTabContent>
              <div class="card-header">
                <h5>Mail Contents</h5>
              </div>
              <div class="card-body">
                <div class="custom-datatable">
                  <a class="btn btn-primary mr-1 mb-3" routerLinkActive="active" [routerLink]="'../create-mail-content'"
                    style="float: right;text-transform: none;font-size: 11px;font-weight: 500;">Yeni Mail Şablonu
                    Oluştur</a>
                  <div class="clearfix"></div>

                  <div class="table-responsive-sm" *ngIf="isLoading">
                    <table class="table" style="text-align: center;">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Mail Şablonu</th>
                          <th scope="col">Dil</th>
                          <th scope="col">Active</th>
                          <th scope="col">Düzenle</th>
                          <th scope="col">Sil</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of MailContents">
                          <td>{{item.Ref }}</td>
                          <td>{{item.TemplateName}} - {{item.LangCode }}</td>
                          <td>{{item.LangCode }}</td>
                          <td>
                            <div *ngIf="!item.IsActive">
                              <i class="fa fa-circle font-warning f-12"></i>
                            </div>
                            <div *ngIf="item.IsActive">
                              <i class="fa fa-circle font-success f-12"></i>
                            </div>
                          </td>
                          <td><a type="button" (click)="gotoMailEdit(item.Ref)"><i class="fa fa-edit"
                                style="font-size: 17px;"></i></a></td>
                          <td>
                            <a type="button" (click)="DeleteMailModal(deletemail,item.Ref)"
                              *ngIf="DeletingItemMail != item.Ref"><i class="fa fa-trash" style="font-size: 17px;"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                    style="left: 50%;position: relative;"></span>
                </div>
              </div>
            </ng-template>

          </ngb-tab>
          <ngb-tab title="SMTP Mail Tanımları">
            <ng-template ngbTabContent>
              <ngb-tabset class="tab-coupon">
                <ngb-tab title="SMTP">
                  <ng-template ngbTabContent>
                    <div class="card-body">
                      <a class="btn btn-primary mr-1 mb-3" (click)="OpenNewSMTP()"
                        style="float: right;text-transform: none;font-size: 11px;font-weight: 500;">Yeni SMTP Ekle</a>

                      <div class="row" [@slideIn] *ngIf="isAddingSMTP" style="padding: 5px 0;">
                        <div class="col-sm-6">
                          <form class="form-signin" name="smtpform" novalidate #smtpform="ngForm"
                            (ngSubmit)="smtpform.form.valid && AddNewSMTP()">
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Template Name</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="TemplateName" #TemplateName="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && TemplateName.invalid  }"
                                  [(ngModel)]="AddSMTPModel.TemplateName" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP Mail</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="email" name="SMTPMail" #SMTPMail="ngModel"
                                  [email]="true" [ngClass]="{  'is-invalid': smtpform.submitted && SMTPMail.invalid  }"
                                  [(ngModel)]="AddSMTPModel.SMTPMail" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP Server</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="SMTPServer" #SMTPServer="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && SMTPServer.invalid  }"
                                  [(ngModel)]="AddSMTPModel.SMTPServer" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP User</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="SMTPUser" #SMTPUser="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && SMTPUser.invalid  }"
                                  [(ngModel)]="AddSMTPModel.SMTPUser" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP Password</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="SMTPPass" #SMTPPass="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && SMTPPass.invalid  }"
                                  [(ngModel)]="AddSMTPModel.SMTPPass" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Use SSL</label>
                              </div>
                              <div class="col-sm-8">
                                <div class="checkbox checkbox-primary">
                                  <input type="checkbox" id="UseSSL" name="UseSSL" #UseSSL="ngModel"
                                    [(ngModel)]="AddSMTPModel.UseSSL">
                                  <label for="UseSSL"></label>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Port</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="Port" #Port="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && Port.invalid  }"
                                  [(ngModel)]="AddSMTPModel.Port" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Active</label>
                              </div>
                              <div class="col-sm-8">
                                <div class="checkbox checkbox-primary">
                                  <input type="checkbox" id="IsActive" name="IsActive" #IsActive="ngModel"
                                    [(ngModel)]="AddSMTPModel.IsActive">
                                  <label for="IsActive"></label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12" style="text-align: right;">
                                <button class="btn btn-primary mr-1 mb-3" type="submit" [disabled]="isAdding"
                                  style="text-transform: none;font-size: 11px;font-weight: 500;">Kaydet<span
                                    *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"></span></button>
                              </div>
                            </div>
                          </form>
                        </div>

                      </div>

                      <div class="row" [@slideIn] *ngIf="isEditingSMTP" style="padding: 5px 0;">
                        <div class="col-sm-6">
                          <form class="form-signin" name="smtpform" novalidate #smtpform="ngForm"
                            (ngSubmit)="smtpform.form.valid && EditSMTP()">
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Template Name</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="TemplateName" #TemplateName="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && TemplateName.invalid  }"
                                  [(ngModel)]="EditSMTPModel.TemplateName" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP Mail</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="email" name="SMTPMail" #SMTPMail="ngModel"
                                  [email]="true" [ngClass]="{  'is-invalid': smtpform.submitted && SMTPMail.invalid  }"
                                  [(ngModel)]="EditSMTPModel.SMTPMail" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP Server</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="SMTPServer" #SMTPServer="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && SMTPServer.invalid  }"
                                  [(ngModel)]="EditSMTPModel.SMTPServer" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP User</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="SMTPUser" #SMTPUser="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && SMTPUser.invalid  }"
                                  [(ngModel)]="EditSMTPModel.SMTPUser" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">SMTP Password</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="SMTPPass" #SMTPPass="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && SMTPPass.invalid  }"
                                  [(ngModel)]="EditSMTPModel.SMTPPass" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Use SSL</label>
                              </div>
                              <div class="col-sm-8">
                                <div class="checkbox checkbox-primary">
                                  <input type="checkbox" id="UseSSL" name="UseSSL" #UseSSL="ngModel"
                                    [(ngModel)]="EditSMTPModel.UseSSL">
                                  <label for="UseSSL"></label>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Port</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="Port" #Port="ngModel"
                                  [ngClass]="{  'is-invalid': smtpform.submitted && Port.invalid  }"
                                  [(ngModel)]="EditSMTPModel.Port" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Active</label>
                              </div>
                              <div class="col-sm-8">
                                <div class="checkbox checkbox-primary">
                                  <input type="checkbox" id="IsActive" name="IsActive" #IsActive="ngModel"
                                    [(ngModel)]="EditSMTPModel.IsActive">
                                  <label for="IsActive"></label>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12" style="text-align: right;">
                                <button class="btn btn-primary mr-1 mb-3" type="submit" [disabled]="isEditing"
                                  style="text-transform: none;font-size: 11px;font-weight: 500;">Kaydet<span
                                    *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"></span></button>
                              </div>
                            </div>
                          </form>
                        </div>

                      </div>

                      <div class="table-responsive-sm" *ngIf="isLoadingSMTP">
                        <table class="table" style="text-align: center;">
                          <thead>
                            <tr>
                              <th scope="col">SMTP Şablonu</th>
                              <th scope="col">From</th>
                              <th scope="col">SMTP Server</th>
                              <th scope="col">SMTP User</th>
                              <th scope="col">SMTP Port</th>
                              <th scope="col">Active</th>
                              <th scope="col">Düzenle</th>
                              <th scope="col">Sil</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of SMTPTemplates">
                              <td>{{item.TemplateName}}</td>
                              <td>{{item.SMTPMail }}</td>
                              <td>{{item.SMTPServer }}</td>
                              <td>{{item.SMTPUser }}</td>
                              <td>{{item.Port }}</td>
                              <td>
                                <div *ngIf="!item.IsActive">
                                  <i class="fa fa-circle font-warning f-12"></i>
                                </div>
                                <div *ngIf="item.IsActive">
                                  <i class="fa fa-circle font-success f-12"></i>
                                </div>
                              </td>
                              <td><a type="button" (click)="OpenEditSMTP(item)"><i class="fa fa-edit"
                                    style="font-size: 17px;"></i></a></td>
                              <td>
                                <a type="button" (click)="DeleteSMTPModal(deletesmtp,item.Ref)"
                                  *ngIf="DeletingItem != item.Ref"><i class="fa fa-trash"
                                    style="font-size: 17px;"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <span *ngIf="!isLoadingSMTP" class="spinner-border spinner-border-sm ml-1"
                        style="left: 50%;position: relative;"></span>
                    </div>

                  </ng-template>
                </ngb-tab>
                <ngb-tab title="CC List">
                  <ng-template ngbTabContent>
                    <div class="card-body">
                      <a class="btn btn-primary mr-1 mb-3" (click)="OpenNewCC()"
                        style="float: right;text-transform: none;font-size: 11px;font-weight: 500;">Yeni CC Ekle</a>

                      <div class="row" [@slideIn] *ngIf="isAddingCC" style="padding: 5px 0;">
                        <div class="col-sm-6">
                          <form class="form-signin" name="ccform" novalidate #ccform="ngForm"
                            (ngSubmit)="ccform.form.valid && AddNewCC()">
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Name</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="Name" #Name="ngModel"
                                  [ngClass]="{  'is-invalid': ccform.submitted && Name.invalid  }"
                                  [(ngModel)]="AddCCModel.Name" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Mail To</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="email" name="MailTo" #MailTo="ngModel" [email]="true"
                                  [ngClass]="{  'is-invalid': ccform.submitted && MailTo.invalid  }"
                                  [(ngModel)]="AddCCModel.MailTo" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12" style="text-align: right;">
                                <button class="btn btn-primary mr-1 mb-3" type="submit" [disabled]="isAdding"
                                  style="text-transform: none;font-size: 11px;font-weight: 500;">Kaydet<span
                                    *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"></span></button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>


                      <div class="row" [@slideIn] *ngIf="isEditingCC" style="padding: 5px 0;">
                        <div class="col-sm-6">
                          <form class="form-signin" name="ccform" novalidate #ccform="ngForm"
                            (ngSubmit)="ccform.form.valid && EditCC()">
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Name</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="text" name="Name" #Name="ngModel"
                                  [ngClass]="{  'is-invalid': ccform.submitted && Name.invalid  }"
                                  [(ngModel)]="EditCCModel.Name" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row mb-3" style="align-items: center">
                              <div class="col-sm-4">
                                <label class="label">Mail To</label>
                              </div>
                              <div class="col-sm-8">
                                <input class="form-control" type="email" name="MailTo" #MailTo="ngModel" [email]="true"
                                  [ngClass]="{  'is-invalid': ccform.submitted && MailTo.invalid  }"
                                  [(ngModel)]="EditCCModel.MailTo" style="font-size: 13px;" required>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-sm-12" style="text-align: right;">
                                <button class="btn btn-primary mr-1 mb-3" type="submit" [disabled]="isEditing"
                                  style="text-transform: none;font-size: 11px;font-weight: 500;">Kaydet<span
                                    *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"></span></button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div class="table-responsive-sm" *ngIf="isLoadingCCList">
                        <table class="table" style="text-align: center;">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">To</th>
                              <th scope="col">Düzenle</th>
                              <th scope="col">Sil</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of CCList">
                              <td>{{item.Name}}</td>
                              <td>{{item.MailTo }}</td>
                              <td><a type="button" (click)="OpenEditCC(item)"><i class="fa fa-edit"
                                    style="font-size: 17px;"></i></a></td>
                              <td>
                                <a type="button" (click)="DeleteCCModal(deletecc,item.Ref)"
                                  *ngIf="DeletingItemCC != item.Ref"><i class="fa fa-trash"
                                    style="font-size: 17px;"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <span *ngIf="!isLoadingCCList" class="spinner-border spinner-border-sm ml-1"
                        style="left: 50%;position: relative;"></span>

                    </div>


                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>

      </div>
    </div>
  </div>
</div>

<ng-template #deletesmtp let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="CloseDeleteModalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      SMTP Silmek İstediğinizden Emin misiniz ?
    </div>
    <div class="modal-footer" style="text-align: center;display: block;">
      <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeleting"
        (click)="DeleteSMTP()">Sil<span *ngIf="isDeleting"
          class="spinner-border spinner-border-sm ml-1"></span></button>
      <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
        (click)="CloseDeleteModalClose()">Vazgeç</button>
    </div>
  </div>
</ng-template>
<ng-template #deletecc let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="CloseDeleteModalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      CC Silmek İstediğinizden Emin misiniz ?
    </div>
    <div class="modal-footer" style="text-align: center;display: block;">
      <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeletingCC"
        (click)="DeleteCC()">Sil<span *ngIf="isDeletingCC"
          class="spinner-border spinner-border-sm ml-1"></span></button>
      <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
        (click)="CloseDeleteModalClose()">Vazgeç</button>
    </div>
  </div>
</ng-template>
<ng-template #deletemail let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="CloseDeleteModalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      Mail Şablonunu Silmek İstediğinizden Emin misiniz ?
    </div>
    <div class="modal-footer" style="text-align: center;display: block;">
      <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeleting"
        (click)="DeleteMail()">Sil<span *ngIf="isDeleting"
          class="spinner-border spinner-border-sm ml-1"></span></button>
      <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
        (click)="CloseDeleteModalClose()">Vazgeç</button>
    </div>
  </div>
</ng-template>