import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditUserComponent } from './edit-user/edit-user.component';

@NgModule({
  declarations: [ListUserComponent, CreateUserComponent,EditUserComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    UsersRoutingModule
  ]
})
export class UsersModule { }
