import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SettingRoutingModule } from './setting-routing.module';
import { FirmMultiplierComponent } from './firm-multipliers/firm-multipliers.component';
import { SharedModule } from '../../shared/shared.module';

import { CreateAdminUserComponent } from './admin-users/create-admin-user/create-admin-user.component';
import { EditAdminUserComponent } from './admin-users/edit-admin-user/edit-admin-user.component';
import { AdminUserListComponent } from './admin-users/admin-user-list/admin-user-list.component';

@NgModule({
  declarations: [FirmMultiplierComponent,CreateAdminUserComponent,EditAdminUserComponent,AdminUserListComponent],
  imports: [
    CommonModule,
    SettingRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SettingModule { }
