<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Add Mega Menu</h5>
                </div>
                <div class="card-body">
                    <form class="form-signin" name="createmegamenuform" novalidate #createmegamenuform="ngForm"
                        (ngSubmit)="createmegamenuform.form.valid && CreateMegaMenu()">
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4"><span>*</span>Mega Menu
                                İsmi</label>
                            <input class="form-control col-md-8" type="text" name="MenuName" #MenuName="ngModel"
                                [(ngModel)]="CreateMenuModel.MenuName" style="font-size: 13px;"
                                [ngClass]="{  'is-invalid': createmegamenuform.submitted && MenuName.invalid  }"
                                required>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4"><span>*</span>İçerik Dili</label>
                            <select class="form-control select col-md-8" style="font-size: 13px;"
                                [(ngModel)]="CreateMenuModel.LanguageCode" name="LanguageCode" required>
                                <option selected disabled [value]="undefined">Seçiniz</option>
                                <option value="TR">Türkçe</option>
                                <option value="EN">English</option>
                                <option value="RU">Russian</option>
                            </select>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Status</label>
                            <div class="checkbox checkbox-primary col-xl-9 col-md-8">
                                <input id="checkbox-primary-2" type="checkbox" name="IsActive" #IsActive="ngModel" [(ngModel)]="CreateMenuModel.IsActive"
                                    data-original-title="" title="">
                                <label for="checkbox-primary-2" class="ml-1">Enable the Mega Menu</label>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" [attr.disabled]="loading ? '' : null">Kaydet<span
                                *ngIf="loading" class="spinner-border spinner-border-sm ml-1"
                                style="margin-bottom: 2px;"></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->