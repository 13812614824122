import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-transport',
  templateUrl: './edit-transport.component.html',
  styleUrls: ['./edit-transport.component.scss']
})
export class EditTransportComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditTransportModel: any = {};
  MenuList;
  isUploadImage;
  imageSrc;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['ShippingMethodDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetTransportDetail();
    });
  }

  GetTransportDetail() {
    this.http.get<any>(environment.url + 'TransportWays/GetTransportWays').subscribe(data => {
      if (data.length) {
        data.forEach(element => {
          if (element.TransportWayRef == this.id) {
            this.EditTransportModel.TransportWayRef = element.TransportWayRef;
            this.EditTransportModel.TransportWayDefinitionTR = element.TransportWayDefinitionTR;
            this.EditTransportModel.TransportWayDefinitionEN = element.TransportWayDefinitionEN;
            this.EditTransportModel.TransportWayDefinitionRU = element.TransportWayDefinitionRU;
            this.EditTransportModel.ImageURL = element.ImageURL;
          }
        });
      }

    });
  }



  EditTransport() {
    this.isEditing = true;
    const body = {
      TransportWayRef: this.EditTransportModel.TransportWayRef, TransportWayDefinitionTR: this.EditTransportModel.TransportWayDefinitionTR,
      TransportWayDefinitionEN: this.EditTransportModel.TransportWayDefinitionEN, TransportWayDefinitionRU: this.EditTransportModel.TransportWayDefinitionRU,
      ImageURL: this.EditTransportModel.ImageURL
    };
    this.http.post<any>(environment.url + 'TransportWays/UpdateTransportWay', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Transfer Yolu Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Transfer Yolu Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Transfer Yolu Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }

  openImage() { // Click Image File Button
    let element: HTMLElement = document.getElementById('addimage') as HTMLElement;
    element.click();
  }
  addImageChange(files: FileList) { // Show Image File on change 
    this.isUploadImage = true;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http.post<any>(environment.url + 'TransportWays/UploadTransportImage', formData).subscribe(data => {
      this.isUploadImage = false;
      if (data) {
        if (files) {
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.EditTransportModel.ImageURL = data;
          };
        }
        this.toastr.success("İcon Yüklendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("İcon Yüklenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });

  }
}
