import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	auth?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Anasayfa', icon: 'home', type: 'link', badgeType: 'primary', active: false, path: 'dashboard'
		},
		{
			title: 'Üyeler', icon: 'user-plus', auth: 'Member', type: 'sub', active: false, children: [
				{ title: 'Üye Listesi', auth: 'MemberList', type: 'link', path: 'users/list-user' },
				{ title: 'E-Bülten Listesi', auth: 'Member', type: 'link' },
			]
		},
		{
			title: 'Siparişler', icon: 'dollar-sign', auth: 'Orders', type: 'sub', active: false, children: [
				{ title: 'Sipariş Listesi', auth: 'OrderList', type: 'link', path: 'orders/order-list' },
				{ title: 'Tamamlanmamış Siparişler', auth: 'Orders', type: 'link' },
				// { title: 'Üretim Talebi', auth: 'ProductionRequest', type: 'link' },
			]
		},
		{
			title: 'Ürünler', icon: 'box', auth: 'Products', type: 'sub', active: false, children: [

				{
					title: 'Ürün Listesi', auth: 'ProductList', type: 'sub', children: [
						{ title: 'Tüm Ürünler', auth: 'ProductList', type: 'link', path: 'products/product-list' },
						{ title: 'Yayındaki Ürünler', auth: 'ProductList', type: 'link' },
						{ title: 'Üretim Talebi Ürünleri', auth: 'ProductList', type: 'link' },
						{ title: 'Yayına Kapalı Ürünler', auth: 'ProductList', type: 'link' },
						{ title: 'Kampanya Ürünleri', auth: 'ProductList', type: 'link' },
					]
				},
				{ title: 'Favoriler', auth: 'FavoriteProducts', type: 'link' },
		//		{ title: 'Yeni Ürün Ekle', auth: 'ProductAdd', type: 'link' },
			]
		},
		{
			title: 'İnstagram Shop', icon: 'instagram', auth: 'InstagramShop', type: 'link' ,path: 'instagrams/instagram-list'
		},
		// {
		// 	title: 'Toplu İşlemler', icon: 'tag', auth: 'BatchProcess', type: 'sub', active: false, children: [
		// 		{ title: 'Stok İşlemleri', auth: 'ProductProcess', type: 'link' },
		// 		{ title: 'Fiyat İşlemleri', auth: 'PriceProcess', type: 'link' },
		// 	]
		// },
		{
			title: 'CMS', icon: 'box', auth: 'CMS', type: 'sub', active: false, children: [
				{ title: 'Metin İçerikleri', auth: 'TextContents', type: 'link', path: 'cms/text-contents' },
				{ title: 'İletişim İçerikleri', auth: 'ContactContents', type: 'link', path: 'cms/contact-contents' },
			//	{ title: 'Sosyal Medya Linkleri', auth: 'SocialMediaContents', type: 'link' },
				{ title: 'Pop-up Yönetimi', auth: 'PopupContents', type: 'link', path: 'cms/pop-up-contents' },
				{ title: 'Mail Yönetimi', auth: 'MailContents', type: 'link', path: 'cms/mail-content-list' },
		//		{ title: 'Yeni Sayfa Ekle', auth: 'NewPageContents', type: 'link' },
			]
		},
		{
			title: 'Menü Yönetimi', icon: 'align-left', auth: 'MenuManagement', type: 'sub', active: false, children: [
				{ title: 'Mega	Menü', auth: 'MegaMenu', type: 'link', path: 'menus/mega-menu' },
			//	{ title: 'Hesabım Menüsü', auth: 'AccountMenu', type: 'link', path: 'menus/account-menu' },
				{ title: 'Footer Menü', auth: 'FooterMenu', type: 'link', path: 'menus/footer-menu' },
				{ title: 'SEO Yönetimi', auth: 'SEOManagement', type: 'link', path: 'menus/seo', }
			]
		},
		{
			title: 'Banner/Slider', icon: 'clipboard', auth: 'BannerSliderManagement', type: 'sub', active: false, children: [
				{ title: 'Banner Yönetimi', auth: 'BannerManagement', type: 'link', path: 'banner-slider/banner-list' },
				{ title: 'Carousel Yönetimi', auth: 'BannerManagement', type: 'link', path: 'banner-slider/carousel-list' },
				{ title: 'Slider Yönetimi', auth: 'SliderManagement', type: 'link', path: 'banner-slider/slider-list' },
			]
		},
		{
			title: 'Tercümeler', icon: 'chrome', auth: 'Translates', type: 'sub', active: false, children: [
				{ title: 'Web Sitesi Tercümeleri', auth: 'WebSiteTranslate', type: 'link', path: 'localization/web-translations' },
			//	{ title: 'Admin Panel Tercümeleri', auth: 'AdminPanelTranslate', type: 'link', path: 'localization/admin-translations' },
			]
		},
		// {
		// 	title: 'Raporlar', icon: 'bar-chart', auth: 'Reports', type: 'sub', active: false
		// },
		{
			title: 'Tanımlar', icon: 'archive', auth: 'Definitions', type: 'sub', active: false, children: [
				{ title: 'Rol Tanımları', auth: 'IsAdmin', type: 'link', path: 'definitions/role-list' },
		//		{ title: 'Dil Tanımları', auth: 'LanguageDefinitions', type: 'link' },
		//		{ title: 'Para Tanımları', auth: 'CurrencyDefinitions', type: 'link' },
		//		{ title: 'Markalar', auth: 'MarkDefinitions', type: 'link' },
		//		{ title: 'Renkler', auth: 'Colors', type: 'link' },
		//		{ title: 'Renk Tipleri', auth: 'ColorTypes', type: 'link' },
		//		{ title: 'Renk Grupları', auth: 'ColorGroups', type: 'link' },
		//		{ title: 'Kategoriler', auth: 'Categories', type: 'link' },
		//		{ title: 'Özellikler', auth: 'Properties', type: 'link' },
		//		{ title: 'Kategori Özellik Eşleştirme', auth: 'CategoryProperties', type: 'link' },
				{ title: 'Kargo Tipi Tanımları', auth: 'CargoTypeDefinitions', type: 'link', path: 'definitions/shipment-list' },
				{ title: 'Banner Lokasyon Tanımları', auth: 'BannerManagement', type: 'link', path: 'definitions/banner-type-list' },
		//		{ title: 'Kargo Fiyat Listeleri', auth: 'CargoPriceList', type: 'link' },
				{ title: 'Ödeme Tipi Tanımları', auth: 'PaymentTypesDefinitions', type: 'link', path: 'definitions/payment-list' },
				{ title: 'Transfer Yolu Tanımları', auth: 'ShippingMethodDefinitions', type: 'link', path: 'definitions/transport-list' },
				{ title: 'Sipariş Durumları', auth: 'OrderStatus', type: 'link', path: 'definitions/order-status-list' },
				{ title: 'Ülke Tanımları', auth: 'CountryDefinitions', type: 'link', path: 'definitions/country-list' },
				{ title: 'Hizmet Tanımları', auth: 'ServiceDefinitions', type: 'link', path: 'definitions/additional-services-list' },
			]
		},
		{
			title: 'Ayarlar', icon: 'settings', auth: 'Settings', type: 'sub', children: [
				{ title: 'Kullanıcılar', type: 'link', path: 'setting/admin-user-list', auth: 'IsAdmin' },
				{ title: 'Bağlantı Ayarları', type: 'link', auth: 'IsAdmin' },
				{ title: 'Entegrasyon Ayarları', auth: 'SeoFormulas', type: 'link' },
				{ title: 'Firma Çarpanları', auth: 'CompanyFactors', path: 'setting/firm-multiplier', type: 'link' },
			//	{ title: 'SEO Formülleri', auth: 'SeoFormulas', type: 'link' },
			//	{ title: 'III. Parti Eklenti ve Scriptler', auth: 'ThirdPartyExtensions', type: 'link' },
			]
		}
		// {
		// 	title: 'Çıkış Yap', path: '/auth/login', icon: 'log-out', type: 'link', active: false
		// }
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
