import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { OrderRoutingModule } from './order-routing.module';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderListComponent } from './order-list/order-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { Daterangepicker } from 'ng2-daterangepicker';

@NgModule({
  declarations: [OrderListComponent, OrderDetailComponent],
  imports: [
    CommonModule,
    OrderRoutingModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    Daterangepicker,
    NgxDatatableModule
  ]
})
export class OrderModule { }
