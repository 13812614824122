import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-additional-services-list',
  templateUrl: './additional-services-list.component.html',
  styleUrls: ['./additional-services-list.component.scss']
})
export class AdditionalServicesListComponent implements OnInit {

  public menus = [];
  public selected = [];
  AdditionalServicesList;
  isLoading;
  isDeleting;
  DeletingItem;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['ServiceDefinitions']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.GetAdditionalServicesList();
  }

  GetAdditionalServicesList() {
    this.http.get<any>(environment.url + 'AdditionalService/GetAdditionalServices').subscribe(data => {
      this.AdditionalServicesList = data;
      this.isLoading = true;
    });
  }
  DeleteAdditionalServices() {
    this.isDeleting = true;
    var body = { StatusRef: this.DeletingItem };
    this.http.post<any>(environment.url + 'AdditionalService/Remove', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetAdditionalServicesList();
      if (!data.Error) {
        this.modalService.dismissAll();
        this.toastr.success("Ulaşım Yolu Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Ulaşım Yolu Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  gotoAdditionalServicesEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/definitions/edit-additional-services'], navigatioExtra);
  }
  DeleteAdditionalServicesModal(file,ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
}
