import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

const DEFAULT_PAGE_SIZE: number = 10;
declare var $: any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  OrderList;
  isLoading = false;
  pageSizePagination: number = DEFAULT_PAGE_SIZE;
  totalCount: number;
  public daterange: any = {};
  public options: any = {
    ranges: {
      'Bugün': [moment(), moment()],
      'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Son 7 gün': [moment().subtract(6, 'days'), moment()],
      'Son 30 gün': [moment().subtract(29, 'days'), moment()],
      'Bu ay': [moment().startOf('month'), moment().endOf('month')],
      'Geçen ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
    alwaysShowCalendars: true,

    showCustomRangeLabel: false,
    autoUpdateInput: true,
    locale: {
      format: "YYYY-MM-DD",
      separator: " - ",
      applyLabel: "Uygula",
      cancelLabel: "Vazgeç",
      fromLabel: "Dan",
      toLabel: "a",
      customRangeLabel: "Seç",
      daysOfWeek: [
        "Pz",
        "Pt",
        "Sl",
        "Çr",
        "Pr",
        "Cm",
        "Ct"
      ],
      monthNames: [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık"
      ],
      firstDay: 1
    }
  };
  FilterModel: any = {};

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['OrderList']) {
      router.navigateByUrl('/');
    }

  }

  public get pageNumber(): number {
    return 1;
  }
  @Input() public set pageNumber(number) {
    if (number && this.pageSizePagination <= this.totalCount) {
      this.GetOrders(this.pageSizePagination, number);
    }
  }

  public get pageSize(): number {
    return DEFAULT_PAGE_SIZE;
  }
  @Input() public set pageSize(size) {
    if (size) {
      this.GetOrders(size, this.pageNumber);
    }
  }

  ngOnInit() {
    this.FilterModel.BeginDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.FilterModel.EndDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.FilterModel.Date = this.FilterModel.BeginDate + " - " + this.FilterModel.EndDate;
    $('#daterangeInput').val(this.FilterModel.BeginDate + " - " + this.FilterModel.EndDate);
    this.GetOrders(this.pageSize, this.pageNumber);
  }
  public selectedDate(value: any) { // Date On Select** for ng2daterangepicker

    const start = new Date(value.start);
    const end = new Date(value.end);
    this.daterange.start = start.toLocaleString('en-CA');
    this.daterange.end = end.toLocaleString('en-CA');
    this.daterange.label = value.label;
    let startdate = new Date(value.start).toLocaleDateString('en-CA');
    let enddate = new Date(value.end).toLocaleDateString('en-CA');
    this.FilterModel.BeginDate = startdate;
    this.FilterModel.EndDate = enddate;
    this.FilterModel.Date = startdate + " - " + enddate;
    $('#daterangeInput').val(startdate + " - " + enddate);
    this.GetOrders(10, 1);
  }

  DateOnFocusOut() {
    setTimeout(() => {
      var inputValue = (<HTMLInputElement>document.getElementById('daterangeInput')).value;
      if (!inputValue) {
        this.FilterModel.Date = null;
        this.FilterModel.BeginDate = null;
        this.FilterModel.EndDate = null;
        $('#daterangeInput').val("");
      }
      else {
        let startdate = inputValue.split(' - ')[0];
        let enddate = inputValue.split(' - ')[1];
        let IsSameDate = inputValue == this.FilterModel.Date ? true : false
        this.FilterModel.BeginDate = startdate;
        this.FilterModel.EndDate = enddate;
        this.FilterModel.Date = inputValue;
        if (!IsSameDate) this.GetOrders(this.pageSize, this.pageNumber);
      }
    }, 500);
  }
  GetOrders(pageSize, pageNumber) { // Get User Data
    this.http.get<any>(environment.url + "Order/GetOrderList/?pageSize=" + pageSize + "&pageNumber=" + pageNumber + '&begDate=' + this.FilterModel.BeginDate + '&endDate=' + this.FilterModel.EndDate).subscribe(data => {
      this.OrderList = data.Data.result;
      this.totalCount = data.Data.totalCount;
      this.pageSizePagination = pageSize;
      this.isLoading = true;
    });
  }



  gotoOrderDetail(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/orders/order-detail'], navigatioExtra);
  }


}
