import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(500, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(500, style({ height: '*' }))
      ])
    ])
  ],
  styleUrls: ['./seo.component.scss']
})
export class SEOComponent implements OnInit {
  id;
  ColumnList: Array<any> = [];
  Model: any = {};
  MainModel: any = {};
  PageModel: any = {};
  MegaMenuList;
  SubMenuList: any = [];
  loading;
  loadingMain;
  isChangeSubMenuOrMegaMenu;
  isChangeMain;
  MenuRef: any = null;
  MainRef: any = null;
  PageRef: any = null;
  isChangePage;
  loadingPage;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['SEOManagement']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.GetMegaMenuList();
  }
  //GetMegaMenu
  GetMegaMenuList() {
    this.http.get<any>(environment.url + 'Menu/GetMegaMenuAll').subscribe(data => {
      this.MegaMenuList = data.Data;
    });
  }
  MegaMenuOnChange(ref) {
    this.SubMenuList = [];
    this.MenuRef = null;
    this.Model.SubMenuRef = null;
    this.http.get<any>(environment.url + 'Menu/GetMegaMenuByRef?MenuRef=' + ref).subscribe(data => {
      data.Data[0].Columns.forEach(element => {
        element.Rows.forEach(row => {
          this.SubMenuList.push(row);
        });
      });
    });

  }
  SubMenuOnChange(ref) {
    this.isChangeSubMenuOrMegaMenu = true;
    this.http.get<any>(environment.url + 'MenuSeo/GetMenuSeoByMenuRef?menuRef=' + this.Model.MegaMenuRef + '&rowRef=' + ref).subscribe(data => {
      if (data.Data.length) {
        this.MenuRef = data.Data[0].Ref;
        this.Model.MetaTitle = data.Data[0].MetaTitle;
        this.Model.MetaKeywords = data.Data[0].MetaKeywords;
        this.Model.MetaDescription = data.Data[0].MetaDescription;
        this.Model.PageSubject = data.Data[0].PageSubject;
        this.Model.PageDescription = data.Data[0].PageDescription;
        setTimeout(() => {
          this.isChangeSubMenuOrMegaMenu = false;
        }, 500);

      }
      else {
        this.MenuRef = null;
        this.Model.MetaTitle = null;
        this.Model.MetaKeywords = null;
        this.Model.MetaDescription = null;
        this.Model.PageSubject = null;
        this.Model.PageDescription = null;
        setTimeout(() => {
          this.isChangeSubMenuOrMegaMenu = false;
        }, 500);
      }
    });
  }

  SaveMenuSeo() {
    if (this.MenuRef == null) {
      this.loading = true;
      const body = {
        MegaMenuRef: this.Model.MegaMenuRef, MenuRowRef: this.Model.SubMenuRef, MetaTitle: this.Model.MetaTitle,
        MetaKeywords: this.Model.MetaKeywords, MetaDescription: this.Model.MetaDescription, PageSubject: this.Model.PageSubject, PageDescription: this.Model.PageDescription
      };
      this.http.post<any>(environment.url + 'MenuSeo/AddMenuSeo', body).subscribe(data => {
        if (data) {
          this.MenuRef = data.Data.Ref;
          this.loading = false;
          this.toastr.success("Seo Bilgileri Eklendi !", '', {
            timeOut: 2000,
          });
        }
      },
        error => {
          this.toastr.error('Seo Bilgileri Eklenemedi!', 'Hata!', {
            timeOut: 2000,
          });
          this.loading = false;
        });
    }
    else {
      this.loading = true;
      const body = {
        Ref: this.MenuRef,
        MegaMenuRef: this.Model.MegaMenuRef, MenuRowRef: this.Model.SubMenuRef, MetaTitle: this.Model.MetaTitle,
        MetaKeywords: this.Model.MetaKeywords, MetaDescription: this.Model.MetaDescription, PageSubject: this.Model.PageSubject, PageDescription: this.Model.PageDescription
      };
      this.http.post<any>(environment.url + 'MenuSeo/EditMenuSeo', body).subscribe(data => {
        if (data) {
          this.loading = false;
          this.toastr.success("Seo Bilgileri Güncellendi !", '', {
            timeOut: 2000,
          });
        }
      },
        error => {
          this.toastr.error('Seo Bilgileri Güncellenemedi!', 'Hata!', {
            timeOut: 2000,
          });
          this.loading = false;
        });
    }

  }

  LangCodeOnChange(lang) {
    this.isChangeMain = true;
    this.http.get<any>(environment.url + 'MainPageSeo/GetMainPageSeoByLangCode?LangCode=' + lang).subscribe(data => {
      if (data.Data.length) {
        this.MainRef = data.Data[0].Ref;
        this.MainModel.MetaTitle = data.Data[0].MetaTitle;
        this.MainModel.MetaKeywords = data.Data[0].MetaKeywords;
        this.MainModel.MetaDescription = data.Data[0].MetaDescription;
        this.MainModel.PageSubject = data.Data[0].PageSubject;
        this.MainModel.PageDescription = data.Data[0].PageDescription;
        setTimeout(() => {
          this.isChangeMain = false;
        }, 500);

      }
      else {
        this.MainRef = null;
        this.MainModel.MetaTitle = null;
        this.MainModel.MetaKeywords = null;
        this.MainModel.MetaDescription = null;
        this.MainModel.PageSubject = null;
        this.MainModel.PageDescription = null;
        setTimeout(() => {
          this.isChangeMain = false;
        }, 500);
      }
    });
  }

  PageLangCodeOnChange(lang) {
    this.isChangePage = true;
    this.http.get<any>(environment.url + 'PageSeo/GetPageSeoByUrl?URL=' + this.PageModel.PageURL).subscribe(data => {
      if (data.Data.length) {
        let find = data.Data.find(x => x.LangCode === lang);
        if (find != undefined) {
          this.PageRef = find.Ref;
          this.PageModel.MetaTitle = find.MetaTitle;
          this.PageModel.MetaKeywords = find.MetaKeywords;
          this.PageModel.MetaDescription = find.MetaDescription;
          this.PageModel.PageSubject = find.PageSubject;
          this.PageModel.PageDescription = find.PageDescription;
          setTimeout(() => {
            this.isChangePage = false;
          }, 500);
        }
        else {
          this.PageRef = null;
          this.PageModel.MetaTitle = null;
          this.PageModel.MetaKeywords = null;
          this.PageModel.MetaDescription = null;
          this.PageModel.PageSubject = null;
          this.PageModel.PageDescription = null;
          setTimeout(() => {
            this.isChangePage = false;
          }, 500);
        }


      }
      else {
        this.PageRef = null;
        this.PageModel.MetaTitle = null;
        this.PageModel.MetaKeywords = null;
        this.PageModel.MetaDescription = null;
        this.PageModel.PageSubject = null;
        this.PageModel.PageDescription = null;
        setTimeout(() => {
          this.isChangePage = false;
        }, 500);
      }
    });
  }
  PageURLOnChange(url) {
    if (this.PageModel.PageLangCode) {
      this.isChangePage = true;
      this.http.get<any>(environment.url + 'PageSeo/GetPageSeoByUrl?URL=' + this.PageModel.PageURL).subscribe(data => {
        if (data.Data.length) {
          let find = data.Data.find(x => x.LangCode === this.PageModel.PageLangCode);
          if (find != undefined) {
            this.PageRef = find.Ref;
            this.PageModel.MetaTitle = find.MetaTitle;
            this.PageModel.MetaKeywords = find.MetaKeywords;
            this.PageModel.MetaDescription = find.MetaDescription;
            this.PageModel.PageSubject = find.PageSubject;
            this.PageModel.PageDescription = find.PageDescription;
            setTimeout(() => {
              this.isChangePage = false;
            }, 500);
          }
          else {
            this.PageRef = null;
            this.PageModel.MetaTitle = null;
            this.PageModel.MetaKeywords = null;
            this.PageModel.MetaDescription = null;
            this.PageModel.PageSubject = null;
            this.PageModel.PageDescription = null;
            setTimeout(() => {
              this.isChangePage = false;
            }, 500);
          }


        }
        else {
          this.PageRef = null;
          this.PageModel.MetaTitle = null;
          this.PageModel.MetaKeywords = null;
          this.PageModel.MetaDescription = null;
          this.PageModel.PageSubject = null;
          this.PageModel.PageDescription = null;
          setTimeout(() => {
            this.isChangePage = false;
          }, 500);
        }
      });
    }

  }

  SaveMainSeo() {
    if (this.MainRef == null) {
      this.loadingMain = true;
      const body = {
        MetaTitle: this.MainModel.MetaTitle, LangCode: this.MainModel.LangCode,
        MetaKeywords: this.MainModel.MetaKeywords, MetaDescription: this.MainModel.MetaDescription, PageSubject: this.MainModel.PageSubject, PageDescription: this.MainModel.PageDescription
      };
      this.http.post<any>(environment.url + 'MainPageSeo/AddMainPage', body).subscribe(data => {
        if (data) {
          this.MenuRef = data.Data.Ref;
          this.loadingMain = false;
          this.toastr.success("Seo Bilgileri Eklendi !", '', {
            timeOut: 2000,
          });
        }
      },
        error => {
          this.toastr.error('Seo Bilgileri Eklenemedi!', 'Hata!', {
            timeOut: 2000,
          });
          this.loadingMain = false;
        });
    }
    else {
      this.loadingMain = true;
      const body = {
        Ref: this.MainRef,
        LangCode: this.MainModel.LangCode, MetaTitle: this.MainModel.MetaTitle,
        MetaKeywords: this.MainModel.MetaKeywords, MetaDescription: this.MainModel.MetaDescription, PageSubject: this.MainModel.PageSubject, PageDescription: this.MainModel.PageDescription
      };
      this.http.post<any>(environment.url + 'MainPageSeo/EditMainPage', body).subscribe(data => {
        if (data) {
          this.loadingMain = false;
          this.toastr.success("Seo Bilgileri Güncellendi !", '', {
            timeOut: 2000,
          });
        }
      },
        error => {
          this.toastr.error('Seo Bilgileri Güncellenemedi!', 'Hata!', {
            timeOut: 2000,
          });
          this.loadingMain = false;
        });
    }

  }
  SavePageSeo() {
    if (this.PageRef == null) {
      this.loadingPage = true;
      const body = {
        MetaTitle: this.PageModel.MetaTitle, LangCode: this.PageModel.PageLangCode, PageURL: this.PageModel.PageURL,
        MetaKeywords: this.PageModel.MetaKeywords, MetaDescription: this.PageModel.MetaDescription, PageSubject: this.PageModel.PageSubject, PageDescription: this.PageModel.PageDescription
      };
      this.http.post<any>(environment.url + 'PageSeo/AddPageSeo', body).subscribe(data => {
        if (data) {
          this.MenuRef = data.Data.Ref;
          this.loadingPage = false;
          this.toastr.success("Seo Bilgileri Eklendi !", '', {
            timeOut: 2000,
          });
        }
      },
        error => {
          this.toastr.error('Seo Bilgileri Eklenemedi!', 'Hata!', {
            timeOut: 2000,
          });
          this.loadingPage = false;
        });
    }
    else {
      this.loadingPage = true;
      const body = {
        Ref: this.PageRef,
        LangCode: this.PageModel.PageLangCode, MetaTitle: this.PageModel.MetaTitle, PageURL: this.PageModel.PageURL,
        MetaKeywords: this.PageModel.MetaKeywords, MetaDescription: this.PageModel.MetaDescription, PageSubject: this.PageModel.PageSubject, PageDescription: this.PageModel.PageDescription
      };
      this.http.post<any>(environment.url + 'PageSeo/EditPageSeo', body).subscribe(data => {
        if (data) {
          this.loadingPage = false;
          this.toastr.success("Seo Bilgileri Güncellendi !", '', {
            timeOut: 2000,
          });
        }
      },
        error => {
          this.toastr.error('Seo Bilgileri Güncellenemedi!', 'Hata!', {
            timeOut: 2000,
          });
          this.loadingPage = false;
        });
    }
  }
}
