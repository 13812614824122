<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Edit Payment</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="PaymentForm" class="needs-validation" novalidate #PaymentForm="ngForm"
                        (ngSubmit)="PaymentForm.form.valid && EditPayment()">
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">English</label>
                            <input class="form-control col-xl-8 col-md-7" type="text" name="PaymentNameEN" 
                                #PaymentNameEN="ngModel" [(ngModel)]="EditPaymentModel.PaymentNameEN " style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': PaymentForm.submitted && PaymentNameEN.invalid  }"
                                required>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Türkçe</label>
                            <input class="form-control col-xl-8 col-md-7" type="text" name="PaymentNameTR"
                            #PaymentNameTR="ngModel" [(ngModel)]="EditPaymentModel.PaymentNameTR" style="font-size: 13px;"
                            [ngClass]="{ 'is-invalid': PaymentForm.submitted && PaymentNameTR.invalid  }"
                            required>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Russian</label>
                            <input class="form-control col-xl-8 col-md-7" type="text" name="PaymentNameRU"
                            #PaymentNameRU="ngModel" [(ngModel)]="EditPaymentModel.PaymentNameRU" style="font-size: 13px;"
                            [ngClass]="{ 'is-invalid': PaymentForm.submitted && PaymentNameRU.invalid  }"
                            required>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Ödeme Durumu</label>
                            <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                <input id="checkbox-primary-2" type="checkbox" name="HasWebService" #HasWebService="ngModel"
                                    [(ngModel)]="EditPaymentModel.HasWebService">
                                <label for="checkbox-primary-2">Aktif</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">URL</label>
                            <input class="form-control col-xl-8 col-md-7" type="text" name="RedirectURL"
                            #RedirectURL="ngModel" [(ngModel)]="EditPaymentModel.RedirectURL" style="font-size: 13px;"
                            [ngClass]="{ 'is-invalid': PaymentForm.submitted && RedirectURL.invalid  }"
                            required>
                        </div>
                      
                        <div class="pull-right">
                            <button type="submit" class="btn btn-primary" [disabled]="isEditing"
                                style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Düzenle<span
                                *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"
                                style="margin-bottom: 2px;"></span></button>
                        </div>
                    </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
           
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->