import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';

const routes: Routes = [

  {
    path: 'list-user',
    component: ListUserComponent,
    data: {
      title: "User List",
      breadcrumb: "User List"
    }
  },
  {
    path: 'edit-user',
    component: EditUserComponent,
    data: {
      title: "Edit List",
      breadcrumb: "Edit List"
    }
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    data: {
      title: "Create User",
      breadcrumb: "Create User"
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
