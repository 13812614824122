<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-12 p-0 card-right">
                    <div class="card tab2-card ">
                        <div class="card-body m-2" style="text-align: center;">
                            <img width="230px" src="assets/images/dashboard/logo.png" alt="">
                        </div>

                        <div class="card-body">
                            <ngb-tabset class="tab-coupon mb-0">
                                <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-user mr-2"></i>Giriş</ng-template>
                                    <ng-template ngbTabContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form class="form-signin" name="loginform" novalidate #loginform="ngForm"
                                                (ngSubmit)="loginform.form.valid && login()">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="UserName"
                                                        #UserName="ngModel" [(ngModel)]="LoginModel.UserName"
                                                        maxlength="30"
                                                        [ngClass]="{  'is-invalid': loginform.submitted && UserName.invalid  }"
                                                        placeholder="Kullanıcı Adı / E-posta Adresi" required />
                                                    <div *ngIf="loginform.submitted && UserName.invalid"
                                                        class="invalid-feedback">
                                                        <div>Kullanıcı Adını Giriniz!</div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input [type]="isVisible ? 'text' : 'password'"
                                                            class="form-control" name="Password" #Password="ngModel"
                                                            [(ngModel)]="LoginModel.Password" autocomplete="new-password"
                                                            [ngClass]="{ 'is-invalid': loginform.submitted && Password.invalid }"
                                                            placeholder="Şifre" required />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">
                                                                <i (click)="PasswordVisibility()"
                                                                    [ngClass]="{'visible' : isVisible, 'notvisible': !isVisible }"></i>
                                                            </span>
                                                        </div>
                                                        <div *ngIf="loginform.submitted && Password.invalid"
                                                            class="invalid-feedback"
                                                            style="margin-bottom: 10px;">
                                                            <div>Şifrenizi Giriniz!</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            style="font-size: 12px;padding-top: 3px;"
                                                            for="customControlAutosizing">Beni Hatırla</label>
                                                        <a href="javascript:void(0)"
                                                            style="font-size: 11px;margin-top: 2px;"
                                                            class="btn btn-default forgot-pass">Parolamı Unuttum</a>
                                                    </div>
                                                </div> -->
                                                <div class="form-button" style="float: right;">
                                                    <button type="submit" [attr.disabled]="loading ? '' : null"
                                                        class="btn btn-primary"
                                                        style="background-color: #277a9b;font-size:16px;font-weight: 500;text-transform: none;">
                                                        Giriş Yap
                                                        <span *ngIf="loading"
                                                            class="spinner-border spinner-border-sm ml-1"
                                                            style="margin-bottom: 2px;"></span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </ng-template>
                                </ngb-tab>
                                <!-- <ngb-tab>
                                    <ng-template ngbTabTitle><i class="icon-unlock mr-2"></i> Kayıt Ol
                                        <ng-template ngbTabContent>
                                            <form [formGroup]="registerForm"
                                                class="form-horizontal auth-form" novalidate>
                                                <div class="form-group">
                                                    <input required="" name="login[username]" type="email"
                                                        class="form-control" placeholder="Username"
                                                        id="exampleInputEmail12">
                                                </div>
                                                <div class="form-group">
                                                    <input required="" name="login[password]" type="password"
                                                        class="form-control" placeholder="Password">
                                                </div>
                                                <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing1">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing1"><span>I agree all statements
                                                                in <a href="javascript:void(0)" class="pull-right">Terms
                                                                    &amp;
                                                                    Conditions</a></span></label>
                                                    </div>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" type="submit">Register</button>
                                                </div>
                                                <div class="form-footer">
                                                    <span>Or Sign up with social platforms</span>
                                                    <ul class="social">
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-facebook"></i></a></li>
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-twitter"></i></a></li>
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-instagram"></i></a></li>
                                                        <li><a href="javascript:void(0)"><i
                                                                    class="icon-pinterest"></i></a></li>
                                                    </ul>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </ng-template>
                                </ngb-tab> -->
                            </ngb-tabset>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
