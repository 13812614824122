import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-order-status',
  templateUrl: './edit-order-status.component.html',
  styleUrls: ['./edit-order-status.component.scss']
})
export class EditOrderStatusComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditOrderStatusModel: any = {};
  MenuList;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['OrderStatus']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetOrderStatusDetail();
    });
  }

  GetOrderStatusDetail() {
    this.http.get<any>(environment.url + 'Order/GetOrderStatus').subscribe(data => {
      if (data.length) {
        data.forEach(element => {
          if (element.StatusRef == this.id) {
            this.EditOrderStatusModel.StatusRef = element.StatusRef;
            this.EditOrderStatusModel.StatusTextTR = element.StatusTextTR;
            this.EditOrderStatusModel.StatusTextEN = element.StatusTextEN;
            this.EditOrderStatusModel.StatusTextRU = element.StatusTextRU;
          }
        });
      }

    });
  }



  EditOrderStatus() {
    this.isEditing = true;
    const body = {
      StatusRef: this.EditOrderStatusModel.StatusRef, StatusTextEN: this.EditOrderStatusModel.StatusTextEN, StatusTextTR: this.EditOrderStatusModel.StatusTextTR, StatusTextRU: this.EditOrderStatusModel.StatusTextRU
    };
    this.http.post<any>(environment.url + 'Order/UpdateOrderStatus', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Sipariş Durumu Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Sipariş Durumu Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Sipariş Durumu Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
