import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router) {   }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : boolean |
        import("@angular/router").UrlTree |
        import("rxjs").Observable<boolean |
        import("@angular/router").UrlTree> |
        Promise<boolean | import("@angular/router").UrlTree> {
        if (localStorage.getItem("AdminToken") != null) {
            this.router.navigate(["/home/dashboard"]);
            return false;
        }
        return true;
    }
}