import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-instagram-detail',
  templateUrl: './instagram-detail.component.html',
  styleUrls: ['./instagram-detail.component.scss']
})
export class InstagramDetailComponent implements OnInit {
  id;
  isEditing = false;
  EditInstagramModel: any = {};

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['InstagramShop']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetInstagramDetail();

    });
  }
  GetInstagramDetail() {
    this.http.get<any>(environment.url + 'InstaShop/GetInstagramMedias').subscribe(data => {
      if (data.length) {
        this.EditInstagramModel = data.find(x => x.Ref == this.id);
      }

    });
  }



  EditInstagramDetail() {
    this.isEditing = true;
    const body = { Ref : this.id, ImageAlt: this.EditInstagramModel.ImageAlt , ImageAltEN: this.EditInstagramModel.ImageAltEN , ImageAltRU: this.EditInstagramModel.ImageAltRU, ProductURL: this.EditInstagramModel.ProductURL,
       ProductURLEN: this.EditInstagramModel.ProductURLEN, ProductURLRU: this.EditInstagramModel.ProductURLRU,
      MediaID : this.EditInstagramModel.MediaID , PermaLink: this.EditInstagramModel.PermaLink , ImageURL : this.EditInstagramModel.ImageURL , Link: this.EditInstagramModel.Link 
    , ApproveDate : this.EditInstagramModel.ApproveDate , PublishDate: this.EditInstagramModel.PublishDate , OrderNo : this.EditInstagramModel.OrderNo , Caption: this.EditInstagramModel.Caption, IsActive: this.EditInstagramModel.IsActive  };
    this.http.post<any>(environment.url + 'InstaShop/UpdateInstaData', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Instagram Detayı Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Instagram Detayı Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Instagram Detayı Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }

}
