<!-- Container-fluid starts-->
<div class="container-fluid" [@fadeInOut]>
    <div class="card">
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="Onaylanmış Postlar">
                    <ng-template ngbTabContent>
                        <div class="row" *ngIf="ApprovedPosts.length" [@fadeInOut]>
                            <div class="col-lg-2 mb-5" *ngFor="let item of ApprovedPosts" style="text-align: center;">
                                <span class="span">#{{ item.Ref }}</span>
                                <img style="width: 100%;border-radius: 10px;"
                                    src="https://scm.vipstendo.com/AdminService/{{item.ImageURL}}">
                                <span class="span">Sıra No : {{item.OrderNo}}</span>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <button type="submit" class="btn btn-primary" (click)="gotoDetail(item)"
                                            style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Düzenle<i
                                                class="fa fa-pencil"
                                                style="font-size: 15px;color: white;margin-left: 5px;"></i></button>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" class="btn btn-secondary"
                                            (click)="DeleteInstagramModal(deleteinstagram,item)"
                                            style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;;">Sil<i
                                                class="fa fa-times"
                                                style="font-size: 15px;color: white;margin-left: 5px;"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Onaylanmamış Postlar">
                    <ng-template ngbTabContent>
                        <div class="row" *ngIf="UnApprovedPosts.length" [@fadeInOut]>
                            <div class="col-lg-2 mb-5" *ngFor="let item of UnApprovedPosts" style="text-align: center;">
                                <span class="span">#{{ item.Ref }}</span>
                                <img style="width: 100%;border-radius: 10px;"
                                    src="https://scm.vipstendo.com/AdminService/{{item.ImageURL}}">
                                <span class="span">Sıra No : {{item.OrderNo}}</span>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <button type="submit" class="btn btn-primary" (click)="gotoDetail(item)"
                                            style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Düzenle<i
                                                class="fa fa-pencil"
                                                style="font-size: 15px;color: white;margin-left: 5px;"></i></button>
                                    </div>
                                    <div class="col-lg-12">
                                        <button type="submit" class="btn btn-secondary"
                                            (click)="DeleteInstagramModal(deleteinstagram,item)"
                                            style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;;">Sil<i
                                                class="fa fa-times"
                                                style="font-size: 15px;color: white;margin-left: 5px;"></i></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #deleteinstagram let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="CloseDeleteModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            İnstagram Ürününü Silmek İstediğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                [disabled]="isDeleting" (click)="DeleteInstagram()">Sil<span *ngIf="isDeleting"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="CloseDeleteModalClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>
