<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="Kur Güncelle">
                    <ng-template ngbTabContent>
                        <div class="form-group row mb-5">
                            <div class="col-lg-12" style="text-align: center;">
                                <button type="submit" class="btn btn-primary" (click)="UpdateCurr()"
                                    [disabled]="isUpdatingCurr"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Kur
                                    Güncelle<span *ngIf="isUpdatingCurr" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>

                            </div>
                        </div>

                        <form name="FirmMultiplierForm" class="needs-validation" novalidate #FirmMultiplierForm="ngForm"
                            (ngSubmit)="FirmMultiplierForm.form.valid && SaveFirmMultipler()">

                            <div class="form-group row">
                                <div class="col-lg-4" style="text-align: center;">
                                    <div class="form-check form-check-inline" style="margin-bottom: 10px;">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            [(ngModel)]="SelectedCurRate" id="inlineRadio1" value="UseManuelCurrRate">
                                        <label class="form-check-label label-curr" for="inlineRadio1">Manuel USD Kur
                                            Değeri</label>
                                    </div>
                                    <input class="form-control col-lg-12" type="text" name="ManuelCurrRate"
                                        [disabled]="SelectedCurRate != 'UseManuelCurrRate'" #ManuelCurrRate="ngModel"
                                        [(ngModel)]="FirmMultipliers.ManuelCurrRate" style="font-size: 13px;"
                                        [ngClass]="{ 'is-invalid': FirmMultiplierForm.submitted && ManuelCurrRate.invalid  }"
                                        required>
                                </div>
                                <div class="col-lg-4" style="text-align: center;">
                                    <div class="form-check form-check-inline" style="margin-bottom: 10px;">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            [(ngModel)]="SelectedCurRate" id="inlineRadio2"
                                            value="UseCentralBankExchangeRate">
                                        <label class="form-check-label label-curr" for="inlineRadio2">Merkez Bankası USD
                                            Kur Değeri</label>
                                    </div>
                                    <input class="form-control col-lg-12" type="text" name="CentralBankExchangeRate"
                                        #CentralBankExchangeRate="ngModel"
                                        [disabled]="SelectedCurRate != 'UseCentralBankExchangeRate'"
                                        [(ngModel)]="FirmMultipliers.CentralBankExchangeRate " style="font-size: 13px;"
                                        [ngClass]="{ 'is-invalid': FirmMultiplierForm.submitted && CentralBankExchangeRate.invalid  }"
                                        required>
                                </div>
                                <div class="col-lg-4" style="text-align: center;">
                                    <div class="form-check form-check-inline" style="margin-bottom: 10px;">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            [(ngModel)]="SelectedCurRate" id="inlineRadio3"
                                            value="UseFreeMarketExchangeRate">
                                        <label class="form-check-label label-curr" for="inlineRadio3">Serbest Piyasa USD
                                            Kur Değeri</label>
                                    </div>
                                    <input class="form-control col-lg-12" type="text" name="FreeMarketExchangeRate"
                                        #FreeMarketExchangeRate="ngModel"
                                        [disabled]="SelectedCurRate != 'UseFreeMarketExchangeRate'"
                                        [(ngModel)]="FirmMultipliers.FreeMarketExchangeRate" style="font-size: 13px;"
                                        [ngClass]="{ 'is-invalid': FirmMultiplierForm.submitted && FreeMarketExchangeRate.invalid  }"
                                        required>
                                </div>
                            </div>

                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Kaydet<span
                                        *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Üretim Talebi">
                    <ng-template ngbTabContent>
                        <form name="MinPurchaseSetAmountForm" class="needs-validation" novalidate
                            #MinPurchaseSetAmountForm="ngForm"
                            (ngSubmit)="MinPurchaseSetAmountForm.form.valid && SaveFirmMultipler()">
                            <div class="row">
                                <div class="col-lg-4 mt-3" style="text-align: center;">
                                    <label class="form-check-label label-curr" style="margin-bottom: 10px;">Minimum
                                        Yeniden
                                        Üretim Seri Çarpanı</label>
                                    <input class="form-control col-lg-12" type="text"
                                        name="MinPurchaseSetAmountForSoldOut" #MinPurchaseSetAmountForSoldOut="ngModel"
                                        [(ngModel)]="MinPurchaseSetAmount" style="font-size: 13px;"
                                        [ngClass]="{ 'is-invalid': MinPurchaseSetAmountForm.submitted && MinPurchaseSetAmountForSoldOut.invalid  }"
                                        required>
                                </div>
                            </div>
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Kaydet<span
                                        *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>

                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->