import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-banner-type',
  templateUrl: './edit-banner-type.component.html',
  styleUrls: ['./edit-banner-type.component.scss']
})
export class EditBannerTypeComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditBannerTypeModel: any = {};
  MenuList;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['BannerManagement']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetBannerTypeDetail();
    });
  }

  GetBannerTypeDetail() {
    this.http.get<any>(environment.url + 'Banner/GetBannerTypes').subscribe(data => {
      data.forEach(element => {
        if (data.length) {
          if (element.Ref == this.id) {
            this.EditBannerTypeModel.BannerType = element.BannerType;
            this.EditBannerTypeModel.RowTypeStr = element.RowTypeStr;
            this.EditBannerTypeModel.SizeWH = element.SizeWH;
          }
        }

      });
    });
  }

  EditBannerType() {
    this.isEditing = true;
    const body = { Ref: this.id, BannerType: this.EditBannerTypeModel.BannerType , RowTypeStr: this.EditBannerTypeModel.RowTypeStr, SizeWH: this.EditBannerTypeModel.SizeWH };
    this.http.post<any>(environment.url + 'Banner/UpdateBannerType', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Banner Lokasyon Tipi Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.isEditing = false;
        this.toastr.error('Banner Lokasyon Tipi Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
      }
    },
      error => {
        this.toastr.error('Banner Lokasyon Tipi Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
