<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header" style="display: flex;">
            <h5 style="align-items: center;display: flex;">ÜYELER</h5>
            <input type="text" class="form-control input" maxlength="50" (change)="searchUser()" name="SearchUserInput" style="width: 250px;margin-left: 10px;font-size: 14px;" [(ngModel)]="SearchUserInput" placeholder="Üye Arayın.." />
        </div>
        <div class="card-body">
           
            <div class="table-responsive-xl">
                <table class="table table-striped" style="text-align: center;" *ngIf="isLoading">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th scope="col">Adı Soyadı</th>
                            <th scope="col">Ülke</th>
                            <th scope="col">Telefon</th>
                            <th scope="col">E-Mail</th>
                            <th scope="col">Üyelik Tarihi</th>
                            <th scope="col">IP</th>
                            <th scope="col">Aktivasyon</th>
                            <th scope="col">Aktif/Pasif</th>
                            <th scope="col">Düzenle</th>
                            <th scope="col">Sil</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of Users; index as i" style=" font-size: 12px;">
                            <td>{{user.Code}}</td>
                            <td><a type="button" (click)="gotoUserEdit(user.Ref,user.Email1)">{{user.Definition}}</a></td>
                            <td>{{user.Country}}</td>
                            <td>{{user.Phone1}}</td>
                            <td>{{user.Email1}}</td>
                            <td>{{user.CreateDate | date:'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{user.IPAddress}}</td>
                            <td>
                                <div *ngIf="false">
                                    <i class="fa fa-circle font-warning f-12"></i>
                                </div>
                                <div *ngIf="true">
                                    <i class="fa fa-circle font-success f-12"></i>
                                </div>
                            </td>
                            <td>
                                <div style="padding-left: 35px;" class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="UserControl2" disabled
                                        [checked]="user.Active">
                                    <label class="custom-control-label" style="cursor: pointer;" (click)="EditActivityModal(updateuser, user)"
                                        for="UserControl2"></label>
                                </div>
                            </td>
                            <td><a type="button" (click)="gotoUserEdit(user.Ref,user.Email1)"><i class="fa fa-edit"
                                        style="font-size: 17px;"></i></a></td>
                            <td>
                                <a type="button" (click)="DeleteUserModal(deleteuser,user)"
                                    *ngIf="DeletingItem != user"><i class="fa fa-trash"
                                        style="font-size: 17px;"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                    style="left: 50%;position: relative;"></span>
                <div class="d-flex justify-content-between p-2" style="font-size: 11px;">
                    <ngb-pagination [collectionSize]="totalCount" [(page)]="pageNumber" [pageSize]="pageSizePagination"
                        [rotate]="true" [maxSize]="5" [boundaryLinks]="true" [ellipses]="false"
                        style="justify-content: center;display: flex;">
                    </ngb-pagination>
                    <select class="custom-select" style="width: auto;font-size: 12px;" name="pageSize"
                        [(ngModel)]="pageSize">
                        <option [ngValue]="10">10 Adet Listele</option>
                        <option [ngValue]="25">25 Adet Listele</option>
                        <option [ngValue]="50">50 Adet Listele</option>
                        <option [ngValue]="100">100 Adet Listele</option>
                        <option [ngValue]="250">250 Adet Listele</option>
                    </select>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #deleteuser let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="CloseDeleteModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            <strong>{{DeletingItem.FirstName}} {{DeletingItem.SurName}}</strong> İsimli Kullanıcıyı Silmek
            İstediğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                [disabled]="isDeleting" (click)="DeleteUser()">Sil<span *ngIf="isDeleting"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="CloseDeleteModalClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>
<ng-template #updateuser let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="EditActivityModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            <strong>{{editpersonnelmodel.FirstName}} {{editpersonnelmodel.SurName}}</strong> İsimli Kullanıcının
            Durumunu
            Değiştirmek İsteğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                [disabled]="IsEditActivityPersonnel" (click)="EditActivity()">Değiştir<span
                    *ngIf="IsEditActivityPersonnel" class="spinner-border spinner-border-sm ml-1"></span></button>
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="EditActivityModalClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>