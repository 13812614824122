import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { menuDB } from 'src/app/shared/tables/menu';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent implements OnInit {

  public menus = [];
  public selected = [];
  isDeleting = false;
  DeletingItem;
  MenuList;
  isLoading;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal, private TokenService: TokenService) {
    this.menus = menuDB.data;
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['FooterMenu']) {
      router.navigateByUrl('/');
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit() { 
    this.GetFooterMenuList();
  }
  GetFooterMenuList() {
    this.http.get<any>(environment.url + 'Menu/GetFooterMenu').subscribe(data => {
      this.MenuList = data.Data;
      this.isLoading = true;
    });
  }

  gotoFooterMenuEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/menus/edit-footer-menu'], navigatioExtra);
  }


  DeleteFooterMenu() {
    this.isDeleting = true;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        Ref: this.DeletingItem 
      },
    };
    this.http.delete<any>(environment.url + 'Menu/DeleteMegaMenu', options).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetFooterMenuList();
      if (data.Success == true) {
        this.modalService.dismissAll();
        this.toastr.success("Footer Menü Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.modalService.dismissAll();
        this.toastr.error("Footer Menü Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  DeleteFooterMenuModal(file,ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
}
