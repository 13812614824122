import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ]),
    trigger('slideIn', [
      state('*', style({ 'overflow': 'hidden' })),
      state('void', style({ 'overflow': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(300, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(300, style({ height: '*' }))
      ])
    ])
  ],
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  id;
  isLoading;
  eMail;
  UserDetail;

  UserDetailModel: any = {};
  isEditingUserDetail = false;
  EditUserDetailLoading = false;

  UserDetailMovementModel: any = {};

  UserDetailShipmentAddressModel: any = {};
  isEditingUserDetailShipmentAddress = false;
  isEditingShipmentAddressLoading = false;

  UserDetailInvoiceAddressModel: any = {};
  isEditingUserDetailInvoiceAddress = false;
  isEditingInvoiceAddressLoading = false;

  UserDetailPasswordModel: any = {};
  isEditingUserDetailPassword = false;
  isSendingPassword;
  isSendingPasswordMail;

  CartList;
  ShipmentAddress;
  InvoiceAddress;


  constructor(private modalService: NgbModal, private elementRef: ElementRef, private activeRoute: ActivatedRoute, private router: Router, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['MemberList']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.eMail = x["eMail"];
      this.GetUserDetail();
      this.GetUserCartList();
      this.GetUserShipmentAddress();
      this.GetUserInvoiceAddress();
    });
  }

  GetUserDetail() {
    this.http.get<any>(environment.url + 'Accounting/GetUserDetail?EMail=' + this.eMail + '&userRef=' + this.id).subscribe(data => {
      this.UserDetail = data.Data;
    });

  }

  GetUserCartList() {
    this.http.get<any>(environment.url + 'Cart/GetCartListByAccount?AccountId=' + this.id).subscribe(data => {
      this.CartList = data;
    });
  }

  GetUserShipmentAddress() {
    this.http.get<any>(environment.url + 'Accounting/GetShipmentAddressByUserRef?UserRef=' + this.id).subscribe(data => {
      this.ShipmentAddress = data;
    });
  }
  GetUserInvoiceAddress() {
    this.http.get<any>(environment.url + 'Accounting/GetInvoiceAddressByUserRef?UserRef=' + this.id).subscribe(data => {
      this.InvoiceAddress = data;
    });
  }



  EditUserDetailPassword() {
    this.isEditingUserDetailPassword = true;
    this.UserDetailPasswordModel.WebPassword = this.UserDetail.Pass;
  }
  CancelUserDetailPassword() {
    this.isEditingUserDetailPassword = false;
    this.UserDetailPasswordModel.WebPassword = null;

  }
  SaveUserDetailPassword() {
    this.isSendingPassword = true;
    this.http.post<any>(environment.url + 'Accounting/ChangePassword?UserRef=' + this.id + '&newPassword=' + this.UserDetailPasswordModel.WebPassword, null).subscribe(data => {
      if (data) {
        this.isSendingPassword = false;
        this.isEditingUserDetailPassword = false;
        this.UserDetail.Pass = data.Password;
        this.UserDetail.CustomerInfo.WebPassword = data.Password;
        this.toastr.success("Yeni Şifre Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isSendingPassword = false;
        this.toastr.error("Yeni Şifre Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }


  EditUserDetailShipmentAddress(address) {
    if (address.Ref != this.UserDetailShipmentAddressModel.Ref) {
      this.isEditingUserDetailShipmentAddress = false;
      this.UserDetailShipmentAddressModel = Object.assign({}, address);
      setTimeout(() => {
        this.isEditingUserDetailShipmentAddress = true;
      }, 500);

    }
    else {
      this.isEditingUserDetailShipmentAddress = false;
      this.UserDetailShipmentAddressModel = {};
    }
  }
  SaveUserDetailShipmentAddress() {
    this.isEditingShipmentAddressLoading = true;
    const body = {
      Ref: this.UserDetailShipmentAddressModel.Ref, Address: this.UserDetailShipmentAddressModel.Address, TaxNr: this.UserDetailShipmentAddressModel.TaxNr
      , CompanyName: this.UserDetailShipmentAddressModel.CompanyName, PostCode: this.UserDetailShipmentAddressModel.PostCode,
      City: this.UserDetailShipmentAddressModel.City, Country: this.UserDetailShipmentAddressModel.Country, MobilePhone: this.UserDetailShipmentAddressModel.MobilePhone,
      Phone1: "", Phone2: "", FaxNr1: "", FaxNr2: "", TaxOffice: "", Town: "", DefaultAddress: false, FirstName: this.UserDetailShipmentAddressModel.FirstName, LastName: this.UserDetailShipmentAddressModel.LastName,
    };
    this.http.post<any>(environment.url + 'Accounting/UpdateAccountAddress', body).subscribe(data => {
      if (!data.Error) {
        this.isEditingShipmentAddressLoading = false;
        this.isEditingUserDetailShipmentAddress = false;
        let index = this.ShipmentAddress.indexOf(this.ShipmentAddress.find(x => x.Ref == data.Address.Ref));
        this.ShipmentAddress[index] = data.Address;
        this.toastr.success("Sevkiyat Adresi Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingShipmentAddressLoading = false;
        this.toastr.error("Sevkiyat Adresi Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }

  EditUserDetailInvoiceAddress(address) {
    if (address.Ref != this.UserDetailInvoiceAddressModel.Ref) {
      this.isEditingUserDetailInvoiceAddress = false;
      this.UserDetailInvoiceAddressModel = Object.assign({}, address);
      setTimeout(() => {
        this.isEditingUserDetailInvoiceAddress = true;
      }, 500);
    }
    else {
      this.isEditingUserDetailInvoiceAddress = false;
      this.UserDetailInvoiceAddressModel = {};
    }


  }
  SaveUserDetailInvoiceAddress() {
    this.isEditingInvoiceAddressLoading = true;
    const body = {
      Ref: this.UserDetailInvoiceAddressModel.Ref, Address: this.UserDetailInvoiceAddressModel.Address, TaxNr: this.UserDetailInvoiceAddressModel.TaxNr
      , CompanyName: this.UserDetailInvoiceAddressModel.CompanyName, PostCode: this.UserDetailInvoiceAddressModel.PostCode,
      City: this.UserDetailInvoiceAddressModel.City, Country: this.UserDetailInvoiceAddressModel.Country, MobilePhone: this.UserDetailInvoiceAddressModel.MobilePhone,
      Phone1: "", Phone2: "", FaxNr1: "", FaxNr2: "", TaxOffice: "", Town: "", DefaultAddress: false, FirstName: this.UserDetailInvoiceAddressModel.FirstName, LastName: this.UserDetailInvoiceAddressModel.LastName,
    };
    this.http.post<any>(environment.url + 'Accounting/UpdateAccountAddress', body).subscribe(data => {
      if (!data.Error) {
        this.isEditingInvoiceAddressLoading = false;
        this.isEditingUserDetailInvoiceAddress = false;
        let index = this.InvoiceAddress.indexOf(this.InvoiceAddress.find(x => x.Ref == data.Address.Ref));
        this.InvoiceAddress[index] = data.Address;
        this.toastr.success("Sevkiyat Adresi Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingInvoiceAddressLoading = false;
        this.toastr.error("Sevkiyat Adresi Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }

  EditUserDetail() {
    this.isEditingUserDetail = true;
    this.UserDetailModel.Firstname = this.UserDetail.Account.Firstname;
    this.UserDetailModel.Surname = this.UserDetail.Account.Surname;
    this.UserDetailModel.Email = this.UserDetail.Account.Email;
    this.UserDetailModel.Mobile = this.UserDetail.Account.Mobile;
    this.UserDetailModel.EBulletinSubscription = this.UserDetail.Account.EBulletinSubscription;
    this.UserDetailModel.EBulletinMail = this.UserDetail.Account.EBulletinMail;
    this.UserDetailModel.CustomerGroup = this.UserDetail.Account.CustomerGroup;
    this.UserDetailModel.CustomerRepresentative = this.UserDetail.Account.CustomerRepresentative;
  }
  CancelUserDetail() {
    this.isEditingUserDetail = false;
    this.UserDetailModel = {};
  }
  SaveUserDetail() {
    this.EditUserDetailLoading = true;
    const body = {
      Firstname: this.UserDetailModel.Firstname, Surname: this.UserDetailModel.Surname, Email: this.UserDetailModel.Email,
      Mobile: this.UserDetailModel.Mobile, EBulletinSubscription: this.UserDetailModel.EBulletinSubscription, EBulletinMail: this.UserDetailModel.EBulletinMail,
      CustomerGroup: this.UserDetailModel.CustomerGroup, CustomerRepresentative: this.UserDetailModel.CustomerRepresentative, AccountId: this.UserDetail.Account.AccountId
    };
    this.http.post<any>(environment.url + 'Accounting/UpdateAccount', body).subscribe(data => {
      if (!data.Error) {
        this.isEditingUserDetail = false;
        this.EditUserDetailLoading = false;
        this.UserDetail.Account.Firstname = this.UserDetailModel.Firstname;
        this.UserDetail.Account.Surname = this.UserDetailModel.Surname;
        this.UserDetail.Account.Email = this.UserDetailModel.Email;
        this.UserDetail.Account.Mobile = this.UserDetailModel.Mobile;
        this.UserDetail.Account.EBulletinSubscription = this.UserDetailModel.EBulletinSubscription;
        this.UserDetail.Account.EBulletinMail = this.UserDetailModel.EBulletinMail;
        this.UserDetail.Account.CustomerGroup = this.UserDetailModel.CustomerGroup;
        this.UserDetail.Account.CustomerRepresentative = this.UserDetailModel.CustomerRepresentative;
        this.toastr.success("Kullanıcı Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.EditUserDetailLoading = false;
        this.toastr.error("Kullanıcı Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }

  SendRecoverMail(){
    this.isSendingPasswordMail = true;
    this.http.post<any>(environment.url + 'Accounting/ResetPassword?email=' + this.eMail, null).subscribe(data => {
      if (data.Success) {
        this.toastr.success("Yeni Şifre Maili Yollandı", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isSendingPasswordMail = false;
        this.toastr.error("Yeni Şifre Maili Yollanamadı", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }

  gotoOrderDetail(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/orders/order-detail'], navigatioExtra);
  }
}
