<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Web Translations</h5>
                </div>
                <div class="card-body">
                    <div id="batchDelete" class="custom-datatable">
                        <button class="btn btn-primary mr-1 mb-3" type="submit"
                            [disabled]="isSaving || isEditing || isAdding" (click)="SaveTranslations()"
                            style="float: right;text-transform: none;font-size: 11px;font-weight: 500;font-family: 'Poppins';">Kaydet
                            <span *ngIf="isSaving" class="spinner-border spinner-border-sm ml-1"></span></button>

                        <button type="button" [disabled]="isSaving || isEditing || isAdding" (click)="AddNewKey()"
                            style="padding-top: 15px;border: none;background: none;"><i class="fa fa-plus"
                                style="font-size: 24px;color: #22af47;"></i></button>
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered" *ngIf="isLoading">
                                <thead>
                                    <tr>
                                        <th scope="col">Key</th>
                                        <th scope="col">English</th>
                                        <th scope="col">Türkçe</th>
                                        <th scope="col">Russian</th>
                                        <th scope="col">Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let translate of WebTranslates">
                                        <th scope="row">
                                            <span
                                                *ngIf="(!isAdding || translate.MainText != AddingMainKey) && (!isEditing || translate.MainText != EditingMainKey) ">
                                                {{translate.MainText}}
                                            </span>

                                            <input class="form-control input"
                                                *ngIf="isEditing && translate.MainText === EditingMainKey || isAdding && translate.MainText === AddingMainKey"
                                                [(ngModel)]="Model.TranslateKey" name="TranslateKey"
                                                #TranslateKey="ngModel" />
                                        </th>
                                        <td>
                                            <span
                                                *ngIf="!isEditing || translate.MainText != EditingMainKey && !isAdding && translate.MainText != AddingMainKey">
                                                {{translate.EN}}
                                            </span>
                                            <input class="form-control input"
                                                *ngIf="isEditing && translate.MainText === EditingMainKey || isAdding && translate.MainText === AddingMainKey"
                                                [(ngModel)]="Model.TranslateEN" name="TranslateEN"
                                                #TranslateEN="ngModel" />
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="!isEditing || translate.MainText != EditingMainKey && !isAdding && translate.MainText != AddingMainKey">
                                                {{translate.TR}}
                                            </span>
                                            <input class="form-control input"
                                                *ngIf="isEditing && translate.MainText === EditingMainKey || isAdding && translate.MainText === AddingMainKey"
                                                [(ngModel)]="Model.TranslateTR" name="TranslateTR"
                                                #TranslateTR="ngModel" />
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="!isEditing || translate.MainText != EditingMainKey && !isAdding && translate.MainText != AddingMainKey">
                                                {{translate.RU}}
                                            </span>
                                            <input class="form-control input"
                                                *ngIf="isEditing && translate.MainText === EditingMainKey || isAdding && translate.MainText === AddingMainKey"
                                                [(ngModel)]="Model.TranslateRU" name="TranslateRU"
                                                #TranslateRU="ngModel" />
                                        </td>
                                        <td>
                                            <div style="display: flex;" *ngIf="!isEditing && !isAdding">
                                                <a type="button" (click)="EditTranslate(translate.MainText)"><i
                                                        class="fa fa-edit"
                                                        style="font-size: 19px;color: #22af47;"></i></a>
                                                <a type="button" (click)="DeleteTranslateModal(deletetranslate,translate.MainText)"><i
                                                        class="fa fa-trash"
                                                        style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>

                                            </div>
                                            <div style="display: flex;margin-top: 6px;"
                                                *ngIf="isEditing && EditingMainKey === translate.MainText || isAdding && translate.MainText === AddingMainKey">
                                                <a type="button" (click)="EditOkay()"><i class="fa fa-check"
                                                        style="font-size: 19px;color: #22af47;"></i></a>
                                                <a type="button" (click)="EditCancel()"><i class="fa fa-times"
                                                        style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                                style="left: 50%;position: relative;"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #deletetranslate let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="CloseDeleteModalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="text-align: center;">
        Çeviriyi Silmek İstediğinizden Emin misiniz ?
      </div>
      <div class="modal-footer" style="text-align: center;display: block;">
        <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeleting"
          (click)="DeleteTranslate()">Sil<span *ngIf="isDeleting"
            class="spinner-border spinner-border-sm ml-1"></span></button>
        <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
          (click)="CloseDeleteModalClose()">Vazgeç</button>
      </div>
    </div>
  </ng-template>