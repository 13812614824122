import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirmMultiplierComponent } from './firm-multipliers/firm-multipliers.component';

import { CreateAdminUserComponent } from './admin-users/create-admin-user/create-admin-user.component';
import { EditAdminUserComponent } from './admin-users/edit-admin-user/edit-admin-user.component';
import { AdminUserListComponent } from './admin-users/admin-user-list/admin-user-list.component';

const routes: Routes = [
  {
    path: 'firm-multiplier',
    component: FirmMultiplierComponent,
    data: {
      title: "Firma Çarpanları",
      breadcrumb: "Firma Çarpanları"
    }
  },
  {
    path: 'admin-user-list',
    component: AdminUserListComponent,
    data: {
      title: "Admin User List",
      breadcrumb: "Admin User List"
    }
  },
  {
    path: 'edit-admin-user',
    component: EditAdminUserComponent,
    data: {
      title: "Edit Admin List",
      breadcrumb: "Edit Admin List"
    }
  },
  {
    path: 'create-admin-user',
    component: CreateAdminUserComponent,
    data: {
      title: "Create Admin User",
      breadcrumb: "Create Admin User"
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingRoutingModule { }
