import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RatesComponent } from './rates/rates.component';
import { WebTranslationsComponent } from './web-translations/web-translations.component';
import { AdminTranslationsComponent } from './admin-translations/admin-translations.component';
import { TaxesComponent } from './taxes/taxes.component';


const routes: Routes = [
  {
    path: 'web-translations',
    component: WebTranslationsComponent,
    data: {
      title: "Web Translations",
      breadcrumb: "Web Translations"
    }
  },
  {
    path: 'admin-translations',
    component: AdminTranslationsComponent,
    data: {
      title: "Admin Translations",
      breadcrumb: "Admin Translations"
    }
  },
  {
    path: 'currency-rates',
    component: RatesComponent,
    data: {
      title: "Currency Rates",
      breadcrumb: "Currency Rates"
    }
  },
  {
    path: 'taxes',
    component: TaxesComponent,
    data: {
      title: 'Taxes',
      breadcrumb: 'Taxes'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocalizationRoutingModule { }
