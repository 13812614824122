import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

const DEFAULT_PAGE_SIZE: number = 25;

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  Users;
  pageSizePagination: number = DEFAULT_PAGE_SIZE;
  totalCount: number;
  isLoading = false;
  DeletingItem;
  isDeleting = false;
  UpdatingItem;
  isUpdating = false;
  numberPage = 1;
  editpersonnelmodel: any ={};
  IsEditActivityPersonnel = false;
  SearchUserInput: any = '';


  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['MemberList']) {
      router.navigateByUrl('/');
    }
  }

  public get pageNumber(): number {
    return this.numberPage;
  }
  @Input() public set pageNumber(number) {
    if (number && this.pageSizePagination <= this.totalCount) {
      this.numberPage = number;
      this.GetUsers(this.pageSizePagination, number);
    }
  }


  public get pageSize(): number {
    return DEFAULT_PAGE_SIZE;
  }
  @Input() public set pageSize(size) {
    if (size) {
      this.GetUsers(size, this.pageNumber);
    }
  }


  ngOnInit() {
    this.GetUsers(this.pageSize, this.pageNumber);
  }
  GetUsers(pageSize, pageNumber) { // Get User Data
    this.isLoading = false;
    this.http.get<any>(environment.url + "Accounting/GetAccounts/?pageSize=" + pageSize + "&pageNumber=" + pageNumber + '&searchText=' + this.SearchUserInput).subscribe(data => {
      this.Users = data.result;
      this.totalCount = data.totalCount;
      this.pageSizePagination = pageSize;
      this.isLoading = true;
    });

  }
  searchUser() { // Search on Front Office
    this.numberPage = 1;
    this.pageSizePagination = 25;

    this.GetUsers(25, 1);
  }

  gotoUserEdit(item: any,email) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item , eMail: email }
    }
    this.router.navigate(['/home/users/edit-user'], navigatioExtra);
  }

  DeleteUser() {
    this.isDeleting = true;
    var body = { Email: this.DeletingItem.Email1, AccountId: this.DeletingItem.Ref };
    this.http.post<any>(environment.url + 'Accounting/DeleteAccount', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetUsers(this.pageSizePagination, this.numberPage);
      if (data.Success) {
        this.modalService.dismissAll();
        this.toastr.success("Kullanıcı Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Kullanıcı Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }

  DeleteUserModal(file,ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }

  EditActivityModal(editpersonnel, item) { // Open Edit Activity Modal
    this.modalService.open(editpersonnel, { centered: true });
    this.editpersonnelmodel = item;
  }
  EditActivity() {
    this.IsEditActivityPersonnel = true;
    var body = { Email: this.editpersonnelmodel.Email1, AccountId: this.editpersonnelmodel.Ref };
    this.http.post<any>(environment.url + "Accounting/UpdateAccountStatus", body).subscribe(data => {
      if (data.Success == true) {
        this.IsEditActivityPersonnel = false;
        this.GetUsers(this.pageSizePagination, this.numberPage);
        this.modalService.dismissAll();
        this.toastr.success("Kullanıcı Durumu Güncellendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.IsEditActivityPersonnel = false;
        this.toastr.error("Kullanıcı Durumu Güncellenemedi", '', {
          timeOut: 3000,
        });
      }

    });
  }
  EditActivityModalClose() { // Activity Modal Close
    this.modalService.dismissAll();
  }
}