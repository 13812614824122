<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Edit Mail Template</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="Mail Şablonu">
                    <ng-template ngbTabContent>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row mb-3" style="align-items: center;">
                                    <div class="col-sm-4">
                                        <label>İçerik Dili</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <select class="form-control select" style="font-size: 12px;" name="LangCode"
                                            #LangCode="ngModel" [(ngModel)]="EditMailTemplateModel.LangCode">
                                            <option value="TR">Türkçe</option>
                                            <option value="EN">English</option>
                                            <option value="RU">Russian</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-3" style="align-items: center">
                                    <div class="col-sm-4">
                                        <label class="label">Şablon Adı</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input class="form-control" type="text" name="TemplateName"
                                            #TemplateName="ngModel" [(ngModel)]="EditMailTemplateModel.TemplateName"
                                            style="font-size: 12px;">
                                    </div>
                                </div>
                                <div class="row mb-3" style="align-items: center;">
                                    <div class="col-sm-4">
                                        <label>From</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <select class="form-control select" style="font-size: 12px;" name="SMTPRef"
                                            #SMTPRef="ngModel" [(ngModel)]="EditMailTemplateModel.SMTPRef">
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option *ngFor="let item of SMTPTemplates" [value]="item.Ref">
                                                {{item.TemplateName}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-3" style="align-items: center;">
                                    <div class="col-sm-4">
                                        <label>To</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <ng-select name="ToList" #ToList="ngModel" style="font-size: 12px;padding: 0;" 
                                            [multiple]="true" [(ngModel)]="EditMailTemplateModel.ToList"
                                            class="select custom">
                                            <ng-option value="[Member]">Member</ng-option>
                                            <ng-option *ngFor="let item of CCLists" [value]="item.MailTo">{{item.MailTo}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="row mb-3" style="align-items: center;">
                                    <div class="col-sm-4">
                                        <label>Gönderilecek Modül</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <select class="form-control select" style="font-size: 12px;" name="MailModuleRef"
                                            (change)="onChangeModule()" #MailModuleRef="ngModel"
                                            [(ngModel)]="EditMailTemplateModel.MailModuleRef">
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option *ngFor="let item of MailModules" [value]="item.Ref">
                                                {{item.MailModuleName}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-3" style="align-items: center;" *ngIf="UseOrderStatusRef">
                                    <div class="col-sm-4">
                                        <label>Status</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <select class="form-control select" style="font-size: 12px;" name="OrderStatusRef"
                                            #MailModuleRef="ngModel" [(ngModel)]="EditMailTemplateModel.OrderStatusRef">
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option *ngFor="let item of Status" [value]="item.StatusRef">
                                                {{item.StatusTextTR}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-3" style="align-items: center">
                                    <div class="col-sm-4">
                                      <label class="label">Active</label>
                                    </div>
                                    <div class="col-sm-8">
                                      <div class="checkbox checkbox-primary">
                                        <input type="checkbox" id="IsActive" name="IsActive" #IsActive="ngModel"
                                          [(ngModel)]="EditMailTemplateModel.IsActive">
                                        <label for="IsActive"></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mb-3" style="align-items: center;">
                                    <div class="col-sm-4">
                                        <label>İçerik Tipi</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <select class="form-control select" style="font-size: 12px;" name="ContentType"
                                            #ContentType="ngModel" [(ngModel)]="EditMailTemplateModel.ContentType">
                                            <option value="ckeditor">CKEditör</option>
                                            <option value="textarea">TextArea</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row mb-3" style="align-items: center">
                                    <div class="col-sm-2">
                                        <label class="label">Konu</label>
                                    </div>
                                    <div class="col-sm-10">
                                        <input class="form-control" type="text" name="Subject" #Subject="ngModel"
                                            [(ngModel)]="EditMailTemplateModel.Subject" style="font-size: 12px;">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-2"
                                        style="padding: 10px;border-radius: 3px;border: 1px solid lightgray">
                                        <div class="token-text">
                                            <span>TOKEN LİSTESİ</span>
                                        </div>
                                        <ng-container *ngIf="!isLoadingtokens">
                                            <span *ngFor="let item of tokens"
                                                style="display: block;font-size: 12px;padding: 5px 0;border-bottom: 1px solid lightgray;">{{item}}</span>
                                        </ng-container>
                                        <span *ngIf="isLoadingtokens"
                                            class="spinner-border spinner-border-sm" style="position: absolute;left: 46%;"></span>
                                    </div>
                                    <div class="col-sm-10" *ngIf="isLoading && EditMailTemplateModel.ContentType == 'ckeditor'">
                                        <ck-editor name="editor1" [(ngModel)]="editorValue" skin="moono-lisa"
                                            language="en" [fullPage]="true"></ck-editor>
                                    </div>
                                    <div class="col-sm-10" *ngIf="isLoading && EditMailTemplateModel.ContentType == 'textarea'">
                                        <textarea type="text" name="editor1"
                                            #editor1="ngModel" [(ngModel)]="editorValue"
                                            style="font-size: 13px;height: 1000px;width: 100%;" required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12" style="text-align: right;">
                                <button class="btn btn-primary mr-1 mb-3" type="submit" (click)="EditMailTemplate()"
                                    [disabled]="isEditing"
                                    style="text-transform: none;font-size: 11px;font-weight: 500;">Düzenle<span
                                        *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"></span></button>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->