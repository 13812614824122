<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Contact Contents Lists</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <div class="clearfix"></div>

                        <div class="table-responsive-sm">
                            <table class="table" style="text-align: center;">
                                <thead>
                                    <tr>
                                      <th scope="col">Sayfa Adı</th>
                                      <th scope="col">Dil</th>
                                      <th scope="col">Düzenle</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of ContactPages">
                                      <td>İletişim - {{ item.contactPage.LangCode }}</td>
                                      <td>{{ item.contactPage.LangCode }}</td>
                                      <td><a type="button" (click)="gotoPageEdit(item.contactPage.Ref)"><i class="fa fa-edit" style="font-size: 17px;"></i></a></td>
                                    </tr>
                                  </tbody>
                            </table>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>