import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss']
})
export class EditPaymentComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditPaymentModel: any = {};
  MenuList;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['PaymentTypesDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetPaymentDetail();
    });
  }

  GetPaymentDetail() {
    this.http.get<any>(environment.url + 'Payment/GetPaymentTypes').subscribe(data => {
      if (data.length) {
        data.forEach(element => {
          if (element.Ref == this.id) {
            this.EditPaymentModel.Ref = element.Ref;
            this.EditPaymentModel.PaymentNameEN = element.PaymentNameEN;
            this.EditPaymentModel.PaymentNameTR = element.PaymentNameTR;
            this.EditPaymentModel.PaymentNameRU = element.PaymentNameRU;
            this.EditPaymentModel.HasWebService = element.HasWebService;
            this.EditPaymentModel.HasWebService = element.HasWebService;
            this.EditPaymentModel.RedirectURL = element.RedirectURL;
          }
        });
      }

    });
  }



  EditPayment() {
    this.isEditing = true;
    const body = {
      Ref: this.EditPaymentModel.Ref, PaymentNameEN: this.EditPaymentModel.PaymentNameEN, PaymentNameTR: this.EditPaymentModel.PaymentNameTR, PaymentNameRU: this.EditPaymentModel.PaymentNameRU,
      HasWebService: this.EditPaymentModel.HasWebService, RedirectURL: this.EditPaymentModel.RedirectURL
    };
    this.http.post<any>(environment.url + 'Payment/UpdatePaymentTypes', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Ödeme Tipi Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Ödeme Tipi Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Ödeme Tipi Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
