import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-instagram-list',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './instagram-list.component.html',
  styleUrls: ['./instagram-list.component.scss']
})
export class InstagramListComponent implements OnInit {

  ApprovedPosts: any = [];
  UnApprovedPosts: any = [];
  isDeleting;
  DeletingItem;

  constructor(private modalService: NgbModal, private activeRoute: ActivatedRoute, private router: Router, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['InstagramShop']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.GetInstagramPosts();
  }

  GetInstagramPosts() {
    this.ApprovedPosts = [];
    this.UnApprovedPosts = [];
    this.http.get<any>(environment.url + 'InstaShop/GetInstagramMedias').subscribe(data => {
      data.forEach(element => {
        if(element.IsActive) this.ApprovedPosts.push(element);
        else this.UnApprovedPosts.push(element);
      });
    });
  }
  gotoDetail(item){
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item.Ref }
    }
    this.router.navigate(['/home/instagrams/instagram-detail'], navigatioExtra);
  }
  DeleteInstagramModal(file, ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose() {
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
  DeleteInstagram() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItem };
    this.http.post<any>(environment.url + 'InstaShop/RemoveInstaData', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetInstagramPosts();
      if (!data.Error) {
        this.modalService.dismissAll();
        this.toastr.success("Instagram Ürünü Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Instagram Ürünü Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
}
