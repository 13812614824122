import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

declare var $: any;
const vatrate: number = 1;


@Component({
  selector: 'app-order-detail',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  id;
  IsLoading = false;
  searchModel: any = {};
  searchItems: any = [];
  isSearching;
  isAddingOrderItem;

  isUpdatingOrderSummary;

  TotalOrderItemsUnit: number = 0;
  TotalOrderItemsTotalWeight: number = 0;
  TotalOrderItemsProductPrice: number = 0;
  TotalOrderItemsVatAmount: number = 0;
  TotalOrderItemsTotalPrice: number = 0;

  OrderSummaryProductPrice: number = 0;
  OrderSummaryVat: number = 0;
  OrderSummarySubTotal: number = 0;
  OrderSummaryCommission: number = 0;
  OrderSummaryTotalPrice: number = 0;
  OrderSummaryAdditionalService: number = 0;

  isEditingPayment;
  isEditingPaymentLoading;
  PaymentModel: any = {};
  Payments: any = [];

  isEditingStatus;
  isEditingStatusLoading;
  StatusModel: any = {};
  Status: any = [];

  isEditingShipmentAddress;
  isEditingShipmentAddressLoading;
  ShipmentAddressModel: any = {};

  isEditingInvoiceAddress;
  isEditingInvoiceAddressLoading;
  InvoiceAddressModel: any = {};
  OrderLineModel: any = [];

  isEditingDeliveryInformation;
  isEditingDeliveryInformationLoading;
  DeliveryInformationModel: any = {};

  isEditingOrderItemLoading;

  Shipments: any = [];
  TransportList: any = [];
  public counter: number = 1;

  OrderDetail;
  isDeleting = false;
  DeletingItem;
  ShipmentDescriptionTR;
  TransportWayDefinitionTR;

  ViewHistory: any = [];


  AdditionalServicesbyOrder: any = [];
  AdditionalServices: any = [];
  isAddingAdditionalServices;
  AdditionalServicesModel: any = {};
  selectedAdditionalService: any = {};

  EditAdditionalServicesModel: any = {};
  EditingAdditionalServiceItemLoading;

  DeletingAdditionalServiceItem;
  DeletingAdditionalServiceItemLoading;

  IsUpdatingBIN;

  constructor(private modalService: NgbModal, private activeRoute: ActivatedRoute, private router: Router, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['OrderList']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.IsLoading = true;
      this.GetAdditionalServicesbyOrder();
      this.GetOrderDetail();
      this.GetPayments();
      this.GetStatus();
      this.GetTransportList();
      this.GetShipmentMethods();
      this.GetAdditionalServices();
      this.GetViewHistory();
    });

  }
  GetOrderDetail() {
    this.http.get<any>(environment.url + 'Order/GetOrderLines?orderRef=' + this.id).subscribe(data => {
      this.OrderDetail = data.Data;
      if(data.Data.SuppOrderLines.length){
        data.Data.SuppOrderLines.forEach(element => {
          this.OrderLineModel.push({
            Barcode: element.Barcode,
            LineRef: element.Ref, VariantRef: element.VariantRef, StockRef: element.StockRef, StockCode: element.StockCode, ImageURL: element.ImageURL, ProductWeight: element.ProductWeight,
            ColorCode: element.ColorCode, StockName: element.StockName, VariantCode: element.VariantCode, VariantName: element.VariantName, FirmModelCode: element.FirmModelCode,
            SuppModelCode: element.SuppModelCode, ColorName: element.ColorName, ColorRef: element.ColorRef, MarkCode: element.MarkCode, MarkRef: element.MarkRef,
            ClassCode: element.ClassCode, ClassRef: element.ClassRef, CategoryCode: element.CategoryCode, CategoryRef: element.CategoryRef, Amount: element.Amount,
            Price: element.Price, Total: element.Total, NetTotal: element.NetTotal, Vat: element.Vat, VatTotal: element.VatTotal,
            SetCount: element.SetCount, SizeList: element.SizeList
          });
          this.ShipmentDescriptionTR = data.Data.orderFiche.ShipmentDescriptionTR;
          this.TransportWayDefinitionTR = data.Data.orderFiche.TransportWayDefinitionTR;
          this.StatusModel.OpenOnWeb = data.Data.orderFiche.OpenOnWeb;
          this.OrderSummaryProductPrice = data.Data.orderFiche.Total
          this.OrderSummaryVat = data.Data.orderFiche.TotalVat;
          this.OrderSummaryAdditionalService = data.Data.orderFiche.ServiceTotals;
          let cargoprice = 0;
          if (this.OrderDetail.orderFiche.CargoPair) cargoprice = this.OrderDetail.orderFiche.CargoPrice;
          this.OrderSummarySubTotal = this.OrderSummaryProductPrice + this.OrderSummaryVat + cargoprice + this.OrderSummaryAdditionalService - (this.OrderDetail.orderFiche.VatRefund + this.OrderDetail.orderFiche.SpecialDiscount);
          this.OrderSummaryCommission = this.OrderSummarySubTotal * this.OrderDetail.orderFiche.CommissionRate / 100;
          this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
        });
        this.CalculateOrderItemsTotals();
      }
      else{
        this.ShipmentDescriptionTR = data.Data.orderFiche.ShipmentDescriptionTR;
        this.TransportWayDefinitionTR = data.Data.orderFiche.TransportWayDefinitionTR;
        this.StatusModel.OpenOnWeb = data.Data.orderFiche.OpenOnWeb;
        this.OrderSummaryProductPrice = data.Data.orderFiche.Total
        this.OrderSummaryVat = data.Data.orderFiche.TotalVat;
        this.OrderSummaryAdditionalService = data.Data.orderFiche.ServiceTotals;

        let cargoprice = 0;
        if (this.OrderDetail.orderFiche.CargoPair) cargoprice = this.OrderDetail.orderFiche.CargoPrice;
        this.OrderSummarySubTotal = this.OrderSummaryProductPrice + this.OrderSummaryVat + cargoprice + this.OrderSummaryAdditionalService - (this.OrderDetail.orderFiche.VatRefund + this.OrderDetail.orderFiche.SpecialDiscount);
        this.OrderSummaryCommission = this.OrderSummarySubTotal * this.OrderDetail.orderFiche.CommissionRate / 100;
        this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
      }
    });
  }
  GetPayments() {
    this.Payments = [];
    this.http.get<any>(environment.url + 'Payment/GetPaymentTypes').subscribe(data => {
      data.forEach(element => {
        this.Payments.push({ Ref: element.Ref, PaymentName: element.PaymentNameTR });
      });
    });
  }
  GetStatus() {
    this.Status = [];
    this.http.get<any>(environment.url + 'Order/GetOrderStatus').subscribe(data => {
      data.forEach(element => {
        this.Status.push({ Ref: element.StatusRef, StatusName: element.StatusTextTR });
      });
    });
  }
  GetShipmentMethods() {
    this.http.get<any>(environment.url + 'Shipment/GetShipmentMethods').subscribe(data => {
      data.forEach(element => {
        this.Shipments.push({ Ref: element.Ref, ShipmentText: element.ShipmentDescriptionTR, Price: element.Price, EstimatedText: element.EstimatedDescriptionTR });
      });
    });
  }
  GetTransportList() {
    this.http.get<any>(environment.url + 'TransportWays/GetTransportWays').subscribe(data => {
      this.TransportList = data;
    });
  }
  GetAdditionalServices() {
    this.http.get<any>(environment.url + 'AdditionalService/GetAdditionalServices').subscribe(data => {
      this.AdditionalServices = data;
    });
  }
  GetAdditionalServicesbyOrder() {
    this.http.get<any>(environment.url + 'Order/GetOrderServices?orderRef=' + this.id).subscribe(data => {
      this.AdditionalServicesbyOrder = data;
    });
  }
  GetViewHistory() {
    this.http.get<any>(environment.url + 'Order/GetOrderViewHistory?orderRef=' + this.id).subscribe(data => {
      this.ViewHistory = data;
    });
  }
  // Increament
  increment(item) {
    let amount = parseInt(item.Amount);
    item.Amount = amount + item.SetCount;
    item.Total = item.Price * item.Amount;
    item.VatTotal = item.Total * item.Vat / 100;
    item.NetTotal = item.Total + item.VatTotal;
    this.CalculateOrderItemsTotals();
  }
  // Decrement
  decrement(item) {
    if (item.Amount - item.SetCount > 0) item.Amount = item.Amount - item.SetCount;
    item.Total = item.Price * item.Amount;
    item.VatTotal = item.Total * item.Vat / 100;
    item.NetTotal = item.Total + item.VatTotal;
    this.CalculateOrderItemsTotals();
  }
  // VatRate Increament
  incrementVatRate(item) {
    let vat = parseInt(item.Vat);
    if (item.Vat + vatrate <= 100) item.Vat = vat + vatrate;
    item.VatTotal = item.Total * item.Vat / 100;

    item.NetTotal = item.Total + item.VatTotal;
    this.CalculateOrderItemsTotals();
  }
  // VatRate Decrement
  decrementVatRate(item) {
    if (item.Vat - vatrate >= 0) item.Vat = item.Vat - vatrate;
    item.VatTotal = item.Total * item.Vat / 100;
    item.NetTotal = item.Total + item.VatTotal;
    this.CalculateOrderItemsTotals();
  }

  OrderItemSave() {
    this.isEditingOrderItemLoading = true;
    const body = {
      Ref: this.id, VatRefund: this.OrderDetail.orderFiche.VatRefund, CommissionRate: this.OrderDetail.orderFiche.CommissionRate, SpecialDiscount: this.OrderDetail.orderFiche.SpecialDiscount
      , orderLines: this.OrderLineModel, CargoPrice: this.OrderDetail.orderFiche.CargoPrice, CargoPair: this.OrderDetail.orderFiche.CargoPair
    };
    this.http.post<any>(environment.url + 'Order/UpdateOrderDetails', body).subscribe(data => {
      this.isEditingOrderItemLoading = false;
      if (!data.Error) {
        this.CalculateNetTotals();
        this.toastr.success("Ürünler Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Ürünler Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }


  UpdateBinList(){
    this.IsUpdatingBIN = true;
    this.http.get<any>(environment.url + 'OrderPayment/UpdateBinList').subscribe(data => {
      this.IsUpdatingBIN = false;
      if(data === "Success"){
        this.toastr.success("BIN Güncellendi", 'Hata !', {
          timeOut: 3000,
        });
      }
      else{
        this.toastr.error("BIN Güncellenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
 
    });
  }

  DeleteOrderItem() {
    this.isDeleting = true;
    let index = this.OrderLineModel.indexOf(this.OrderLineModel.find(x => x.Barcode === this.DeletingItem));
    if (index != -1) this.OrderLineModel.splice(index, 1);
    const body = { Ref: this.id, orderLines: this.OrderLineModel, CargoPrice: this.OrderDetail.orderFiche.CargoPrice, CargoPair: this.OrderDetail.orderFiche.CargoPair };
    this.http.post<any>(environment.url + 'Order/UpdateOrderDetails', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      if (!data.Error) {
        this.CalculateNetTotals();
        this.CalculateOrderItemsTotals();
        this.modalService.dismissAll();
        this.toastr.success("Ürün Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Ürün Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  DeleteModal(file, barcode) {
    this.DeletingItem = barcode;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose() {
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }

  ViewHistoryModal(file) {
    this.modalService.open(file, { centered: true });
  }
  ViewHistoryModalClose() {
    this.modalService.dismissAll();
  }

  DeleteAdditionalService() {
    this.DeletingAdditionalServiceItemLoading = true;
    let item = this.AdditionalServicesbyOrder.find(x => x.Ref === this.DeletingAdditionalServiceItem);
    const body = {
      Ref: item.Ref, Amount: item.Amount,
      ServiceRef: item.ServiceRef, OrderRef: this.id, PhotoURL: item.PhotoURL,
      NameTR: item.NameTR, NameEN: item.NameEN, UnitPrice: item.Price,
      VatRate: item.Vat, VatAmount: item.VatTotal, NetTotal: item.NetTotal
    };
    this.http.post<any>(environment.url + 'Order/DeleteOrderService', body).subscribe(data => {
      this.DeletingAdditionalServiceItemLoading = false;
      this.DeletingAdditionalServiceItem = "";
      if (!data.Error) {
        this.OrderSummaryAdditionalService = data.ServiceTotals;
        this.CalculateNetTotals();
        this.GetAdditionalServicesbyOrder();
        this.UpdateOrderSummary();
        this.modalService.dismissAll();
        this.toastr.success("Hizmet Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Hizmet Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  OpenDeleteAdditionalService(file, ref) {
    this.DeletingAdditionalServiceItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteAdditionalServiceModalClose() {
    this.modalService.dismissAll();
    this.DeletingAdditionalServiceItem = "";
  }


  EditAdditionalServiceNameOnChange() {
    let item = this.AdditionalServices.find(x => x.Ref == this.EditAdditionalServicesModel.Name);
    this.EditAdditionalServicesModel.PhotoURL = item.PhotoURL;
    this.EditAdditionalServicesModel.NameTR = item.NameTR;
    this.EditAdditionalServicesModel.NameEN = item.NameEN;
    this.EditAdditionalServicesModel.Unit = item.Amount;
  }
  OpenEditAdditionalService(file, item) {
    this.EditAdditionalServicesModel.Name = item.ServiceRef;
    this.EditAdditionalServicesModel.Ref = item.Ref;
    this.EditAdditionalServicesModel.Unit = item.Amount;
    this.EditAdditionalServicesModel.Price = item.UnitPrice;
    this.EditAdditionalServicesModel.VatTotal = item.VatAmount;
    this.EditAdditionalServicesModel.Vat = item.VatRate;
    this.EditAdditionalServicesModel.NetTotal = item.NetTotal;
    this.EditAdditionalServicesModel.PhotoURL = item.PhotoURL;
    this.EditAdditionalServicesModel.NameTR = item.NameTR;
    this.EditAdditionalServicesModel.NameEN = item.NameEN;
    this.modalService.open(file, { centered: true });
  }
  CloseEdititionalServicesModalClose() {
    this.EditAdditionalServicesModel = {};
    this.modalService.dismissAll();
  }
  EditAdditionalServices() {
    this.EditingAdditionalServiceItemLoading = true;
    const body = {
      Ref: this.EditAdditionalServicesModel.Ref, Amount: this.EditAdditionalServicesModel.Unit,
      ServiceRef: this.EditAdditionalServicesModel.Name, OrderRef: this.id, PhotoURL: this.EditAdditionalServicesModel.PhotoURL,
      NameTR: this.EditAdditionalServicesModel.NameTR, NameEN: this.EditAdditionalServicesModel.NameEN, UnitPrice: this.EditAdditionalServicesModel.Price,
      VatRate: this.EditAdditionalServicesModel.Vat, VatAmount: this.EditAdditionalServicesModel.VatTotal, NetTotal: this.EditAdditionalServicesModel.NetTotal
    }
    this.http.post<any>(environment.url + 'Order/UpdateOrderService', body).subscribe(data => {
      this.isAddingAdditionalServices = false;
      if (!data.Error) {
        this.EditAdditionalServicesModel = {};
        this.OrderSummaryAdditionalService = data.ServiceTotals;
        this.CalculateNetTotals();
        this.GetAdditionalServicesbyOrder();
        this.UpdateOrderSummary();
        this.modalService.dismissAll();
        this.toastr.success("Hizmet Düzenlendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error('Hizmet Düzenlenemedi', 'Bilinmeyen Bir Hata Oluştu!', {
          timeOut: 3000,
        });
      }

    }, error => {
      this.EditingAdditionalServiceItemLoading = false;
      this.toastr.error('Girdiğiniz Değerleri Kontrol Ediniz!', 'Bilinmeyen Bir Hata Oluştu !', { timeOut: 2000 });
    });
  }
  EditAdditionalServicesPriceOnChange() {
    if (this.EditAdditionalServicesModel.Unit > 0 && this.EditAdditionalServicesModel.Price > 0) {
      if (this.EditAdditionalServicesModel.Vat == 0) {
        this.EditAdditionalServicesModel.VatTotal = 0;
        this.EditAdditionalServicesModel.NetTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit);
      }
      else {
        this.EditAdditionalServicesModel.VatTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit) * this.EditAdditionalServicesModel.Vat / 100;
        this.EditAdditionalServicesModel.NetTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit) + this.EditAdditionalServicesModel.VatTotal;
      }
    }
    else {
      this.EditAdditionalServicesModel.VatTotal = 0;
      this.EditAdditionalServicesModel.NetTotal = 0;
    }
  }
  EditAdditionalServicesVatOnChange() {
    if (this.EditAdditionalServicesModel.Unit > 0 && this.EditAdditionalServicesModel.Price > 0) {
      if (this.EditAdditionalServicesModel.Vat == 0) {
        this.EditAdditionalServicesModel.VatTotal = 0;
        this.EditAdditionalServicesModel.NetTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit);
      }
      else {
        this.EditAdditionalServicesModel.VatTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit) * this.EditAdditionalServicesModel.Vat / 100;
        this.EditAdditionalServicesModel.NetTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit) + this.EditAdditionalServicesModel.VatTotal;
      }
    }
    else {
      this.EditAdditionalServicesModel.VatTotal = 0;
      this.EditAdditionalServicesModel.NetTotal = 0;
    }
  }
  EditAdditionalServicesUnitOnChange() {
    if (this.EditAdditionalServicesModel.Unit > 0 && this.EditAdditionalServicesModel.Price > 0) {
      if (this.EditAdditionalServicesModel.Vat == 0) {
        this.EditAdditionalServicesModel.VatTotal = 0;
        this.EditAdditionalServicesModel.NetTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit);
      }
      else {
        this.EditAdditionalServicesModel.VatTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit) * this.EditAdditionalServicesModel.Vat / 100;
        this.EditAdditionalServicesModel.NetTotal = (this.EditAdditionalServicesModel.Price * this.EditAdditionalServicesModel.Unit) + this.EditAdditionalServicesModel.VatTotal;
      }
    }
    else {
      this.EditAdditionalServicesModel.VatTotal = 0;
      this.EditAdditionalServicesModel.NetTotal = 0;
    }

  }



  OpenAdditionalServicesModal(file) {
    this.AdditionalServicesModel.Vat = 0;
    this.modalService.open(file, { centered: true });
  }
  AddAdditionalServices() {
    this.isAddingAdditionalServices = true;
    const body = {
      ServiceRef: this.AdditionalServicesModel.Name, OrderRef: this.id, PhotoURL: this.selectedAdditionalService.PhotoURL, Amount: this.AdditionalServicesModel.Unit,
      NameTR: this.selectedAdditionalService.NameTR, NameEN: this.selectedAdditionalService.NameEN, UnitPrice: this.AdditionalServicesModel.Price,
      VatRate: this.AdditionalServicesModel.Vat, VatAmount: this.AdditionalServicesModel.VatTotal, NetTotal: this.AdditionalServicesModel.NetTotal
    }
    this.http.post<any>(environment.url + 'Order/AddOrderService', body).subscribe(data => {
      this.isAddingAdditionalServices = false;
      if (!data.Error) {
        this.AdditionalServicesModel = {};
        this.selectedAdditionalService = {};
        this.OrderSummaryAdditionalService = data.ServiceTotals;
        this.CalculateNetTotals();
        this.GetAdditionalServicesbyOrder();
        this.UpdateOrderSummary();
        this.modalService.dismissAll();
        this.toastr.success("Hizmet Eklendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error('Hizmet Eklenemedi', 'Bilinmeyen Bir Hata Oluştu!', {
          timeOut: 3000,
        });
      }
    }, error => {
      this.isAddingAdditionalServices = false;
      this.toastr.error('Girdiğiniz Değerleri Kontrol Ediniz!', 'Bilinmeyen Bir Hata Oluştu !', { timeOut: 2000 });
    });
  }
  CloseAdditionalServicesModalClose() {
    this.modalService.dismissAll();
  }
  AdditionalServiceNameOnChange() {
    this.selectedAdditionalService = this.AdditionalServices.find(x => x.Ref == this.AdditionalServicesModel.Name);
  }
  AdditionalServicesPriceOnChange() {
    if (this.AdditionalServicesModel.Unit > 0 && this.AdditionalServicesModel.Price > 0) {
      if (this.AdditionalServicesModel.Vat == 0) {
        this.AdditionalServicesModel.VatTotal = 0;
        this.AdditionalServicesModel.NetTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit);
      }
      else {
        this.AdditionalServicesModel.VatTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit) * this.AdditionalServicesModel.Vat / 100;
        this.AdditionalServicesModel.NetTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit) + this.AdditionalServicesModel.VatTotal;
      }
    }
    else {
      this.AdditionalServicesModel.VatTotal = 0;
      this.AdditionalServicesModel.NetTotal = 0;
    }
  }
  AdditionalServicesVatOnChange() {
    if (this.AdditionalServicesModel.Unit > 0 && this.AdditionalServicesModel.Price > 0) {
      if (this.AdditionalServicesModel.Vat == 0) {
        this.AdditionalServicesModel.VatTotal = 0;
        this.AdditionalServicesModel.NetTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit);
      }
      else {
        this.AdditionalServicesModel.VatTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit) * this.AdditionalServicesModel.Vat / 100;
        this.AdditionalServicesModel.NetTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit) + this.AdditionalServicesModel.VatTotal;
      }
    }
    else {
      this.AdditionalServicesModel.VatTotal = 0;
      this.AdditionalServicesModel.NetTotal = 0;
    }
  }
  AdditionalServicesUnitOnChange() {
    if (this.AdditionalServicesModel.Unit > 0 && this.AdditionalServicesModel.Price > 0) {
      if (this.AdditionalServicesModel.Vat == 0) {
        this.AdditionalServicesModel.VatTotal = 0;
        this.AdditionalServicesModel.NetTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit);
      }
      else {
        this.AdditionalServicesModel.VatTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit) * this.AdditionalServicesModel.Vat / 100;
        this.AdditionalServicesModel.NetTotal = (this.AdditionalServicesModel.Price * this.AdditionalServicesModel.Unit) + this.AdditionalServicesModel.VatTotal;
      }
    }
    else {
      this.AdditionalServicesModel.VatTotal = 0;
      this.AdditionalServicesModel.NetTotal = 0;
    }

  }


  OpenSearchModal(file) {
    this.modalService.open(file, { centered: true, size: 'xl' });
  }
  SearchOrderItem() {
    this.isSearching = true;
    this.searchItems = [];
    if (this.searchModel.itemCode.length == 25 || this.searchModel.itemCode.length == 7) {
      if (this.searchModel.itemCode.length == 25) {
        let itemcode = this.searchModel.itemCode.substring(0, 22);
        let variantcode = this.searchModel.itemCode.substring(22, 25);

        this.http.get<any>(environment.url + 'Products/SearchProduct?itemCode=' + itemcode).subscribe(data => {
          this.isSearching = false;
          if (data.length) {
            data[0].StockVariants.forEach(element => {
              if (element.VariantCode == variantcode) {
                this.searchItems.push({
                  Balance: element.Balance,
                  ImageURL: element.Images[0].ImageLocation, FirmModelCode: data[0].FirmModelCode, VariantCode: variantcode, StockName: data[0].Name, ColorName: element.ColorName,
                  Price: element.StoreSalesPrice, Barcode: element.Barcode,
                  Ref: 0, VariantRef: element.VariantRef, StockRef: data[0].StockRef, StockCode: data[0].StockCode, ProductWeight: data[0].SetWeight,
                  ColorCode: element.ColorCode, VariantName: element.VariantName,
                  SuppModelCode: data[0].SuppModelCode, ColorRef: element.ColorRef,
                  ClassCode: data[0].ClassCode, CategoryRef: data[0].CategoryRef,
                  SetCount: data[0].SetCount
                });
              }
            });
          }

        });
      }
      else if (this.searchModel.itemCode.length == 7) {
        this.http.get<any>(environment.url + 'Products/SearchProduct?itemCode=' + this.searchModel.itemCode).subscribe(data => {
          this.isSearching = false;
          if (data.length) {
            data[0].StockVariants.forEach(element => {
              this.searchItems.push({
                Balance: element.Balance,
                ImageURL: element.Images[0].ImageLocation, FirmModelCode: data[0].FirmModelCode, VariantCode: element.VariantCode, StockName: data[0].Name, ColorName: element.ColorName,
                Price: element.StoreSalesPrice, Barcode: element.Barcode,
                Ref: 0, VariantRef: element.VariantRef, StockRef: data[0].StockRef, StockCode: data[0].StockCode, ProductWeight: data[0].SetWeight,
                ColorCode: element.ColorCode, VariantName: element.VariantName,
                SuppModelCode: data[0].SuppModelCode, ColorRef: element.ColorRef,
                ClassCode: data[0].ClassCode, CategoryRef: data[0].CategoryRef,
                SetCount: data[0].SetCount
              });
            });
          }

        });
      }

    }
    else {
      this.isSearching = false;
      this.toastr.warning("Lütfen 7 Haneli Firma Model Kodunu veya 25 Haneli Barkodu Giriniz!", '', {
        timeOut: 3000,
      });
    }

  }
  AddOrderItem(item) {
    this.isAddingOrderItem = true;
    let index = this.OrderLineModel.indexOf(this.OrderLineModel.find(x => x.Barcode == item.Barcode));
    if (index === -1) {
      this.OrderLineModel.push({
        Barcode: item.Barcode, LineRef: item.Ref, VariantRef: item.VariantRef, StockRef: item.StockRef, StockCode: item.StockCode, ImageURL: [item.ImageURL], ProductWeight: item.ProductWeight,
        ColorCode: item.ColorCode, StockName: item.StockName, VariantCode: item.VariantCode, VariantName: item.VariantName, FirmModelCode: item.FirmModelCode,
        SuppModelCode: item.SuppModelCode, ColorName: item.ColorName, ColorRef: item.ColorRef, MarkCode: item.MarkCode, MarkRef: item.MarkRef,
        ClassCode: item.ClassCode, ClassRef: item.ClassRef, CategoryCode: item.CategoryCode, CategoryRef: item.CategoryRef, Amount: item.SetCount,
        Price: item.Price, Total: item.Price * item.SetCount, NetTotal: item.Price * item.SetCount, Vat: 0, VatTotal: 0, SetCount: item.SetCount, SizeList: item.SizeList
      });
      const body = { Ref: this.id, orderLines: this.OrderLineModel, CargoPrice: this.OrderDetail.orderFiche.CargoPrice, CargoPair: this.OrderDetail.orderFiche.CargoPair };
      this.http.post<any>(environment.url + 'Order/UpdateOrderDetails', body).subscribe(data => {
        if (!data.Error) {
          this.CalculateNetTotals();
          this.CalculateOrderItemsTotals();
          this.isAddingOrderItem = false;
          this.toastr.success("Ürün Eklendi", '', {
            timeOut: 3000,
          });
        }
        else {
          this.isAddingOrderItem = false;
          this.toastr.error("Ürün Eklenemedi", 'Hata !', {
            timeOut: 3000,
          });
        }
      });
    }
    else {
      this.OrderLineModel[index].Amount = this.OrderLineModel[index].Amount + item.SetCount;
      this.OrderLineModel[index].Total = this.OrderLineModel[index].Price * this.OrderLineModel[index].Amount;
      this.OrderLineModel[index].VatTotal = this.OrderLineModel[index].Total * this.OrderLineModel[index].Vat / 100;
      this.OrderLineModel[index].NetTotal = this.OrderLineModel[index].Total + this.OrderLineModel[index].VatTotal;
      const body = { Ref: this.id, orderLines: this.OrderLineModel, CargoPrice: this.OrderDetail.orderFiche.CargoPrice, CargoPair: this.OrderDetail.orderFiche.CargoPair };
      this.http.post<any>(environment.url + 'Order/UpdateOrderDetails', body).subscribe(data => {
        if (!data.Error) {
          this.CalculateNetTotals();
          this.isAddingOrderItem = false;
          this.toastr.success("Ürün Eklendi", '', {
            timeOut: 3000,
          });
        }
        else {
          this.isAddingOrderItem = false;
          this.toastr.error("Ürün Eklenemedi", 'Hata !', {
            timeOut: 3000,
          });
        }
      });
    }

  }
  CloseSearchModalClose() {
    this.modalService.dismissAll();
  }


  UnitOnChange(item) {
    item.Total = item.Price * item.Amount;
    item.VatTotal = item.Total * item.Vat / 100;
    item.NetTotal = item.Total + item.VatTotal;
    this.CalculateOrderItemsTotals();
  }
  VatRateOnChange(item) {
    item.VatTotal = item.Total * item.Vat / 100;
    item.NetTotal = item.Total + item.VatTotal;
    this.CalculateOrderItemsTotals();
  }
  VatRefundOnChange() {
    this.OrderSummarySubTotal = 0;
    this.OrderSummaryCommission = 0;
    this.OrderSummaryTotalPrice = 0;
    this.OrderSummaryAdditionalService = 0;
    this.AdditionalServicesbyOrder.forEach(element => {
      this.OrderSummaryAdditionalService += element.NetTotal;
    });
    let cargoprice = 0;
    if (this.OrderDetail.orderFiche.CargoPair) cargoprice = this.OrderDetail.orderFiche.CargoPrice;

    this.OrderSummarySubTotal = this.OrderSummaryProductPrice + this.OrderSummaryVat + cargoprice + this.OrderSummaryAdditionalService - (this.OrderDetail.orderFiche.VatRefund + this.OrderDetail.orderFiche.SpecialDiscount);
    this.OrderSummaryCommission = this.OrderSummarySubTotal * this.OrderDetail.orderFiche.CommissionRate / 100;
    this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
  }
  SpecialDiscountOnChange() {
    this.OrderSummarySubTotal = 0;
    this.OrderSummaryCommission = 0;
    this.OrderSummaryTotalPrice = 0;
    this.OrderSummaryAdditionalService = 0;
    this.AdditionalServicesbyOrder.forEach(element => {
      this.OrderSummaryAdditionalService += element.NetTotal;
    });
    let cargoprice = 0;
    if (this.OrderDetail.orderFiche.CargoPair) cargoprice = this.OrderDetail.orderFiche.CargoPrice;
    this.OrderSummarySubTotal = this.OrderSummaryProductPrice + this.OrderSummaryVat + cargoprice + this.OrderSummaryAdditionalService - (this.OrderDetail.orderFiche.VatRefund + this.OrderDetail.orderFiche.SpecialDiscount);
    this.OrderSummaryCommission = this.OrderSummarySubTotal * this.OrderDetail.orderFiche.CommissionRate / 100;
    this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
  }
  UpdateOrderSummary() {
    this.isUpdatingOrderSummary = true;
    const body = {
      Ref: this.id, PromotionCode: this.OrderDetail.orderFiche.PromotionCode, PromotionPrice: this.OrderDetail.orderFiche.PromotionPrice, VatRefund: this.OrderDetail.orderFiche.VatRefund,
      SpecialDiscount: this.OrderDetail.orderFiche.SpecialDiscount, CommissionRate: this.OrderDetail.orderFiche.CommissionRate, CommissionPrice: this.OrderSummaryCommission,
      CurrRate: this.OrderDetail.orderFiche.CurrRate, CargoPrice: this.OrderDetail.orderFiche.CargoPrice, CargoPair: this.OrderDetail.orderFiche.CargoPair
    };
    this.http.post<any>(environment.url + 'Order/UpdateOrderSummary', body).subscribe(data => {
      this.isUpdatingOrderSummary = false;
      if (!data.Error) {
        this.toastr.success("Sipariş Güncellendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Sipariş Güncellenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }

  EditPaymentMethod() {
    this.PaymentModel.PaymetMethodRef = this.OrderDetail.orderFiche.PaymetMethodRef;
    this.PaymentModel.CommissionRate = this.OrderDetail.orderFiche.CommissionRate;
    this.isEditingPayment = true;
  }
  EditPaymentOkay() {
    this.isEditingPaymentLoading = true;
    this.OrderSummaryCommission = this.OrderSummarySubTotal * this.PaymentModel.CommissionRate / 100;
    const body = { Ref: this.id, PaymetMethodRef: this.PaymentModel.PaymetMethodRef, CommissionRate: this.PaymentModel.CommissionRate, CommissionPrice: this.OrderSummaryCommission };
    this.http.post<any>(environment.url + 'Order/UpdateOrderPayment', body).subscribe(data => {
      this.isEditingPaymentLoading = false;
      if (!data.Error) {
        let index = this.Payments.indexOf(this.Payments.find(x => x.Ref.toString() === this.PaymentModel.PaymetMethodRef));
        if (index != -1) this.OrderDetail.orderFiche.PaymentNameTR = this.Payments[index].PaymentName;
        this.OrderDetail.orderFiche.CommissionRate = this.PaymentModel.CommissionRate;
        this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
        this.isEditingPayment = false;
        this.toastr.success("Ödeme Yöntemi Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingPayment = false;
        this.toastr.error("Ödeme Yöntemi Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  EditPaymentCancel() {
    this.PaymentModel.PaymetMethodRef = this.OrderDetail.orderFiche.PaymetMethodRef;
    this.PaymentModel.CommissionRate = this.OrderDetail.orderFiche.CommissionRate;
    this.isEditingPayment = false;
  }


  EditStatusMethod() {
    this.StatusModel.Status = this.OrderDetail.orderFiche.Status;
    this.StatusModel.OpenOnWeb = this.OrderDetail.orderFiche.OpenOnWeb;
    this.StatusModel.MailNotify = this.OrderDetail.orderFiche.MailNotify;
    this.StatusModel.StatusNote = this.OrderDetail.orderFiche.StatusNote;
    this.isEditingStatus = true;
  }
  EditStatusOkay() {
    this.isEditingStatusLoading = true;
    const body = { Ref: this.id, Status: this.StatusModel.Status, StatusNote: this.StatusModel.StatusNote, OpenOnWeb: this.StatusModel.OpenOnWeb, MailNotify: this.StatusModel.MailNotify };
    this.http.post<any>(environment.url + 'Order/UpdateOrderStatusNote', body).subscribe(data => {
      this.isEditingStatusLoading = false;
      if (!data.Error) {
        let index = this.Status.indexOf(this.Status.find(x => x.Ref.toString() === this.StatusModel.Status));
        if (index != -1) this.OrderDetail.orderFiche.StatusStr = this.Status[index].StatusName;
        this.OrderDetail.orderFiche.Status = this.StatusModel.Status;
        this.OrderDetail.orderFiche.OpenOnWeb = this.StatusModel.OpenOnWeb;
        this.OrderDetail.orderFiche.MailNotify = this.StatusModel.MailNotify;
        this.OrderDetail.orderFiche.StatusNote = this.StatusModel.StatusNote;
        this.OrderDetail.orderFiche.ModifiedDate = data.ModifiedDate;

        this.isEditingStatus = false;
        this.toastr.success("Durum Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingStatus = false;
        this.toastr.error("Durum Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  EditStatusCancel() {
    this.StatusModel.Status = this.OrderDetail.orderFiche.Status;
    this.StatusModel.OpenOnWeb = this.OrderDetail.orderFiche.OpenOnWeb;
    this.StatusModel.MailNotify = this.OrderDetail.orderFiche.MailNotify;
    this.StatusModel.StatusNote = this.OrderDetail.orderFiche.StatusNote;
    this.isEditingStatus = false;
  }


  EditShipmentAddress() {
    this.ShipmentAddressModel.ShipmentFirstName = this.OrderDetail.orderFiche.ShipmentFirstName;
    this.ShipmentAddressModel.ShipmentLastName = this.OrderDetail.orderFiche.ShipmentLastName;
    this.ShipmentAddressModel.ShipmentAddress = this.OrderDetail.orderFiche.ShipmentAddress;
    this.ShipmentAddressModel.ShipmentCity = this.OrderDetail.orderFiche.ShipmentCity;
    this.ShipmentAddressModel.ShipmentCountry = this.OrderDetail.orderFiche.ShipmentCountry;
    this.ShipmentAddressModel.ShipmentMobilePhone = this.OrderDetail.orderFiche.ShipmentMobilePhone;
    this.ShipmentAddressModel.ShipmentPostCode = this.OrderDetail.orderFiche.ShipmentPostCode;
    this.ShipmentAddressModel.ShipmentCompanyName = this.OrderDetail.orderFiche.ShipmentCompanyName;
    this.ShipmentAddressModel.ShipmentTaxNr = this.OrderDetail.orderFiche.ShipmentTaxNr;
    this.isEditingShipmentAddress = true;
  }
  EditShipmentAddressOkay() {
    this.isEditingShipmentAddressLoading = true;
    const body = { FirstName: this.ShipmentAddressModel.ShipmentFirstName, LastName: this.ShipmentAddressModel.ShipmentLastName,
      Ref: this.OrderDetail.orderFiche.ShipmentAddrRef, Address: this.ShipmentAddressModel.ShipmentAddress, TaxNr: this.ShipmentAddressModel.ShipmentTaxNr
      , CompanyName: this.ShipmentAddressModel.ShipmentCompanyName, PostCode: this.ShipmentAddressModel.ShipmentPostCode,
      City: this.ShipmentAddressModel.ShipmentCity, Country: this.ShipmentAddressModel.ShipmentCountry, MobilePhone: this.ShipmentAddressModel.ShipmentMobilePhone,
      Phone1: "", Phone2: "", FaxNr1: "", FaxNr2: "", TaxOffice: "", Town: "", DefaultAddress: false
    };
    this.http.post<any>(environment.url + 'Order/UpdateOrderShipmentAddress', body).subscribe(data => {
      this.isEditingShipmentAddressLoading = false;
      this.OrderDetail.orderFiche.ShipmentFirstName = this.ShipmentAddressModel.ShipmentFirstName;
      this.OrderDetail.orderFiche.ShipmentLastName = this.ShipmentAddressModel.ShipmentLastName;
      this.OrderDetail.orderFiche.ShipmentAddress = this.ShipmentAddressModel.ShipmentAddress;
      this.OrderDetail.orderFiche.ShipmentCity = this.ShipmentAddressModel.ShipmentCity;
      this.OrderDetail.orderFiche.ShipmentCountry = this.ShipmentAddressModel.ShipmentCountry;
      this.OrderDetail.orderFiche.ShipmentMobilePhone = this.ShipmentAddressModel.ShipmentMobilePhone;
      this.OrderDetail.orderFiche.ShipmentTaxNr = this.ShipmentAddressModel.ShipmentTaxNr;
      this.OrderDetail.orderFiche.ShipmentCompanyName = this.ShipmentAddressModel.ShipmentCompanyName;
      this.OrderDetail.orderFiche.ShipmentPostCode = this.ShipmentAddressModel.ShipmentPostCode;
      if (!data.Error) {
        this.isEditingShipmentAddress = false;
        this.toastr.success("Sevkiyat Adresi Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingShipmentAddress = false;
        this.toastr.error("Sevkiyat Adresi Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  EditShipmentAddressCancel() {
    this.ShipmentAddressModel.ShipmentFirstName = this.OrderDetail.orderFiche.ShipmentFirstName;
    this.ShipmentAddressModel.ShipmentLastName = this.OrderDetail.orderFiche.ShipmentLastName;
    this.ShipmentAddressModel.ShipmentAddress = this.OrderDetail.orderFiche.ShipmentAddress;
    this.ShipmentAddressModel.ShipmentCity = this.OrderDetail.orderFiche.ShipmentCity;
    this.ShipmentAddressModel.ShipmentCountry = this.OrderDetail.orderFiche.ShipmentCountry;
    this.ShipmentAddressModel.ShipmentMobilePhone = this.OrderDetail.orderFiche.ShipmentMobilePhone;
    this.ShipmentAddressModel.ShipmentPostCode = this.OrderDetail.orderFiche.ShipmentPostCode;
    this.ShipmentAddressModel.ShipmentCompanyName = this.OrderDetail.orderFiche.ShipmentCompanyName;
    this.ShipmentAddressModel.ShipmentTaxNr = this.OrderDetail.orderFiche.ShipmentTaxNr;
    this.isEditingShipmentAddress = false;
  }


  EditInvoiceAddress() {
    this.InvoiceAddressModel.InvFirstName = this.OrderDetail.orderFiche.InvFirstName;
    this.InvoiceAddressModel.InvLastName = this.OrderDetail.orderFiche.InvLastName;
    this.InvoiceAddressModel.Address = this.OrderDetail.orderFiche.Address;
    this.InvoiceAddressModel.City = this.OrderDetail.orderFiche.City;
    this.InvoiceAddressModel.Country = this.OrderDetail.orderFiche.Country;
    this.InvoiceAddressModel.MobilePhone = this.OrderDetail.orderFiche.MobilePhone;
    this.InvoiceAddressModel.PostCode = this.OrderDetail.orderFiche.PostCode;
    this.InvoiceAddressModel.TaxNr = this.OrderDetail.orderFiche.TaxNr;
    this.InvoiceAddressModel.CompanyName = this.OrderDetail.orderFiche.CompanyName;
    this.isEditingInvoiceAddress = true;
  }
  EditInvoiceAddressOkay() {
    this.isEditingInvoiceAddressLoading = true;
    const body = { FirstName: this.InvoiceAddressModel.InvFirstName, LastName: this.InvoiceAddressModel.InvLastName,
      Ref: this.OrderDetail.orderFiche.AddrRef, Address: this.InvoiceAddressModel.Address,
      City: this.InvoiceAddressModel.City, Country: this.InvoiceAddressModel.Country, MobilePhone: this.InvoiceAddressModel.MobilePhone, PostCode: this.InvoiceAddressModel.PostCode
      , TaxNr: this.InvoiceAddressModel.TaxNr, CompanyName: this.InvoiceAddressModel.CompanyName,
      Phone1: "", Phone2: "", FaxNr1: "", FaxNr2: "", TaxOffice: "", Town: "", DefaultAddress: false
    };
    this.http.post<any>(environment.url + 'Order/UpdateOrderInvoiceAddress', body).subscribe(data => {
      this.isEditingInvoiceAddressLoading = false;
      this.OrderDetail.orderFiche.InvFirstName = this.InvoiceAddressModel.InvFirstName;
      this.OrderDetail.orderFiche.InvLastName = this.InvoiceAddressModel.InvLastName;
      this.OrderDetail.orderFiche.Address = this.InvoiceAddressModel.Address;
      this.OrderDetail.orderFiche.City = this.InvoiceAddressModel.City;
      this.OrderDetail.orderFiche.Country = this.InvoiceAddressModel.Country;
      this.OrderDetail.orderFiche.MobilePhone = this.InvoiceAddressModel.MobilePhone;
      this.OrderDetail.orderFiche.PostCode = this.InvoiceAddressModel.PostCode;
      this.OrderDetail.orderFiche.TaxNr = this.InvoiceAddressModel.TaxNr;
      this.OrderDetail.orderFiche.CompanyName = this.InvoiceAddressModel.CompanyName;
      if (!data.Error) {
        this.isEditingInvoiceAddress = false;
        this.toastr.success("Fatura Adresi Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingInvoiceAddress = false;
        this.toastr.error("Fatura Adresi Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  EditInvoiceAddressCancel() {
    this.InvoiceAddressModel.InvFirstName = this.OrderDetail.orderFiche.InvFirstName;
    this.InvoiceAddressModel.InvLastName = this.OrderDetail.orderFiche.InvLastName;
    this.InvoiceAddressModel.Address = this.OrderDetail.orderFiche.Address;
    this.InvoiceAddressModel.City = this.OrderDetail.orderFiche.City;
    this.InvoiceAddressModel.Country = this.OrderDetail.orderFiche.Country;
    this.InvoiceAddressModel.MobilePhone = this.OrderDetail.orderFiche.MobilePhone;
    this.InvoiceAddressModel.PostCode = this.OrderDetail.orderFiche.PostCode;
    this.InvoiceAddressModel.TaxNr = this.OrderDetail.orderFiche.TaxNr;
    this.InvoiceAddressModel.CompanyName = this.OrderDetail.orderFiche.CompanyName;
    this.isEditingInvoiceAddress = false;
  }


  ShipmentMethodRefOnChange($event) {
    this.ShipmentDescriptionTR = $event.target.options[$event.target.options.selectedIndex].text;
    if(this.DeliveryInformationModel.ShipmentMethodRef != 1){
      this.DeliveryInformationModel.CargoFirm = "";
      this.DeliveryInformationModel.CargoNumber = "";
    }
    else{
      this.DeliveryInformationModel.CargoFirm = this.OrderDetail.orderFiche.CargoFirm;
      this.DeliveryInformationModel.CargoNumber = this.OrderDetail.orderFiche.CargoNumber;
    }
  }
  TransportWayRefOnChange($event) {
    this.TransportWayDefinitionTR = $event.target.options[$event.target.options.selectedIndex].text;
  }


  EditDeliveryInformation() {
    this.DeliveryInformationModel.ShipmentMethodRef = this.OrderDetail.orderFiche.ShipmentMethodRef;
    this.DeliveryInformationModel.CargoPair = this.OrderDetail.orderFiche.CargoPair;
    this.DeliveryInformationModel.CargoPrice = this.OrderDetail.orderFiche.CargoPrice;
    this.DeliveryInformationModel.CargoNote = this.OrderDetail.orderFiche.CargoNote;
    this.DeliveryInformationModel.CargoFirm = this.OrderDetail.orderFiche.CargoFirm;
    this.DeliveryInformationModel.CargoTrackingNumber = this.OrderDetail.orderFiche.CargoTrackingNumber;
    this.DeliveryInformationModel.CargoNumber = this.OrderDetail.orderFiche.CargoNumber;
    this.DeliveryInformationModel.TransportWayRef = this.OrderDetail.orderFiche.TransportWayRef;
    this.isEditingDeliveryInformation = true;
  }
  EditDeliveryInformationOkay() {
    this.isEditingDeliveryInformationLoading = true;
    const body = {
      Ref: this.id, ShipmentMethodRef: this.DeliveryInformationModel.ShipmentMethodRef, CargoPair: this.DeliveryInformationModel.CargoPair,
      CargoPrice: this.DeliveryInformationModel.CargoPrice, CargoNote: this.DeliveryInformationModel.CargoNote, CargoFirm: this.DeliveryInformationModel.CargoFirm,
      CargoTrackingNumber: this.DeliveryInformationModel.CargoTrackingNumber, CargoNumber: this.DeliveryInformationModel.CargoNumber, TransportWayRef: this.DeliveryInformationModel.TransportWayRef,
    };
    this.http.post<any>(environment.url + 'Order/UpdateOrderDeliveryInfo', body).subscribe(data => {
      this.isEditingDeliveryInformationLoading = false;
      this.OrderDetail.orderFiche.ShipmentMethodRef = this.DeliveryInformationModel.ShipmentMethodRef;
      this.OrderDetail.orderFiche.ShipmentDescriptionTR = this.ShipmentDescriptionTR;
      this.OrderDetail.orderFiche.TransportWayDefinitionTR = this.TransportWayDefinitionTR;
      this.OrderDetail.orderFiche.CargoPair = this.DeliveryInformationModel.CargoPair;
      this.OrderDetail.orderFiche.CargoPrice = this.DeliveryInformationModel.CargoPrice;
      this.OrderDetail.orderFiche.CargoNote = this.DeliveryInformationModel.CargoNote;
      this.OrderDetail.orderFiche.CargoFirm = this.DeliveryInformationModel.CargoFirm;
      this.OrderDetail.orderFiche.CargoTrackingNumber = this.DeliveryInformationModel.CargoTrackingNumber;
      this.OrderDetail.orderFiche.TransportWayRef = this.DeliveryInformationModel.TransportWayRef;
      this.OrderDetail.orderFiche.CargoNumber = this.DeliveryInformationModel.CargoNumber;
      if (!data.Error) {
        this.isEditingDeliveryInformation = false;
        this.CalculateNetTotals();
        this.toastr.success("Teslimat Bilgileri Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.isEditingDeliveryInformation = false;
        this.toastr.error("Teslimat Bilgileri Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  EditDeliveryInformationCancel() {
    this.DeliveryInformationModel.ShipmentMethodRef = this.OrderDetail.orderFiche.ShipmentMethodRef;
    this.DeliveryInformationModel.CargoPair = this.OrderDetail.orderFiche.CargoPair;
    this.DeliveryInformationModel.CargoPrice = this.OrderDetail.orderFiche.CargoPrice;
    this.DeliveryInformationModel.CargoNote = this.OrderDetail.orderFiche.CargoNote;
    this.DeliveryInformationModel.CargoFirm = this.OrderDetail.orderFiche.CargoFirm;
    this.DeliveryInformationModel.CargoTrackingNumber = this.OrderDetail.orderFiche.CargoTrackingNumber;
    this.DeliveryInformationModel.CargoNumber = this.OrderDetail.orderFiche.CargoNumber;
    this.DeliveryInformationModel.TransportWayRef = this.OrderDetail.orderFiche.TransportWayRef;
    this.isEditingDeliveryInformation = false;
  }

  CalculateOrderItemsTotals() {
    this.TotalOrderItemsUnit = 0;
    this.TotalOrderItemsTotalWeight = 0;
    this.TotalOrderItemsProductPrice = 0;
    this.TotalOrderItemsVatAmount = 0;
    this.TotalOrderItemsTotalPrice = 0;
    this.OrderLineModel.forEach(element => {
      this.TotalOrderItemsUnit = this.TotalOrderItemsUnit + Number(element.Amount);
      this.TotalOrderItemsTotalWeight = this.TotalOrderItemsTotalWeight + element.ProductWeight * element.Amount / element.SetCount;
      this.TotalOrderItemsProductPrice = this.TotalOrderItemsProductPrice + element.Total;
      this.TotalOrderItemsVatAmount = this.TotalOrderItemsVatAmount + element.VatTotal;
      this.TotalOrderItemsTotalPrice = this.TotalOrderItemsTotalPrice + element.NetTotal;
    });
  }

  CalculateNetTotals() {
    this.OrderSummaryProductPrice = 0;
    this.OrderSummaryVat = 0;
    this.OrderSummarySubTotal = 0;
    this.OrderSummaryCommission = 0;
    this.OrderSummaryTotalPrice = 0;
    if (this.OrderLineModel.length) {
      this.OrderLineModel.forEach(element => {
        this.OrderSummaryProductPrice = this.OrderSummaryProductPrice + Number(element.Total);
        this.OrderSummaryVat = this.OrderSummaryVat + Number(element.VatTotal);
        let cargoprice = 0;
        if (this.OrderDetail.orderFiche.CargoPair) cargoprice = this.OrderDetail.orderFiche.CargoPrice;
        this.OrderSummarySubTotal = this.OrderSummaryProductPrice + this.OrderSummaryVat + cargoprice + this.OrderSummaryAdditionalService - (this.OrderDetail.orderFiche.VatRefund + this.OrderDetail.orderFiche.SpecialDiscount);
        this.OrderSummaryCommission = this.OrderSummarySubTotal * this.OrderDetail.orderFiche.CommissionRate / 100;
        this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
      });
    }
    else{
      let cargoprice = 0;
      if (this.OrderDetail.orderFiche.CargoPair) cargoprice = this.OrderDetail.orderFiche.CargoPrice;
      this.OrderSummarySubTotal = this.OrderSummaryProductPrice + this.OrderSummaryVat + cargoprice + this.OrderSummaryAdditionalService - (this.OrderDetail.orderFiche.VatRefund + this.OrderDetail.orderFiche.SpecialDiscount);
      this.OrderSummaryCommission = this.OrderSummarySubTotal * this.OrderDetail.orderFiche.CommissionRate / 100;
      this.OrderSummaryTotalPrice = this.OrderSummarySubTotal + this.OrderSummaryCommission;
    }

  }

  gotoUser(){
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: this.OrderDetail.orderFiche.CustomerRef , eMail: this.OrderDetail.orderFiche.EMail }
    }
    this.router.navigate(['/home/users/edit-user'], navigatioExtra);
  }
}
