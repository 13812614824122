import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss']
})
export class EditPageComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  CreatePageModel: any = {};
  MenuList;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService,) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['TextContents']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetTextContentDetail();
      this.GetMegaMenuList();
    });
  }

  GetTextContentDetail() {
    this.http.get<any>(environment.url + 'Policies/GetPoliciesPagesByRef?Ref=' + this.id).subscribe(data => {
      this.CreatePageModel.PageName = data.Data[0].PageName;
      this.CreatePageModel.PageURL = data.Data[0].PageURL;
      this.CreatePageModel.MenuRef = data.Data[0].MenuRef;
      this.CreatePageModel.LanguageCode = data.Data[0].LanguageCode;
      this.CreatePageModel.IsActive = data.Data[0].IsActive;
      this.CreatePageModel.IsContactForm = data.Data[0].IsContactForm;
      setTimeout(() => {                           
        this.editorValue = data.Data[0].InnerHTML;
      }, 1000);

      this.CreatePageModel.Ref = data.Data[0].Ref;
    });
  }

  GetMegaMenuList() {
    this.http.get<any>(environment.url + 'Menu/GetMegaMenu').subscribe(data => {
      this.MenuList = data.Data;
    });
  }

  PageNameOnChange() {
    this.CreatePageModel.PageURL = "/" + this.CreatePageModel.PageName.replace(/ /g, '-').replace(/Ğ/gim, "g")
      .replace(/Ü/g, "u")
      .replace(/Ş/g, "s")
      .replace(/I/g, "i")
      .replace(/İ/g, "i")
      .replace(/Ö/g, "o")
      .replace(/Ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c").toLowerCase();
  }

  UpdateCMSContentPage() {
    this.isEditing = true;
    const body = { Ref: this.CreatePageModel.Ref, PageName: this.CreatePageModel.PageName, PageURL: this.CreatePageModel.PageURL, InnerHTML: this.editorValue, MenuRef: this.CreatePageModel.MenuRef, LanguageCode: this.CreatePageModel.LanguageCode, IsActive: this.CreatePageModel.IsActive, IsContactForm: this.CreatePageModel.IsContactForm  };
    this.http.post<any>(environment.url + 'Policies/UpdatePolicies', body).subscribe(data => {
      if (data.Success) {
        this.isEditing = false;
        this.toastr.success("Sayfa Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.isEditing = false;
        this.toastr.error(data.Data.Error, 'Hata!', {
          timeOut: 2000,
        });
      }
    },
      error => {
        this.toastr.error('Sayfa Oluşturulamadı!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
