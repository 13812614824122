import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,Event, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { Location } from '@angular/common';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs;
  href;
  visible = true;
  public title: string

  constructor(private activatedRoute: ActivatedRoute, private _location: Location,
    private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.href = this.router.url;
        if (this.href == '/home/dashboard') {
          this.visible = false;
        }
        else {
          this.visible = true;
        }
      }
    });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        this.breadcrumbs = {};
        this.title = title;
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child
        }
      });

  }

  ngOnInit() {
    this.href = this.router.url;
    if (this.href == '/home/dashboard') {
      this.visible = false;
    }
    else {
      this.visible = true;
    }
  }
  backClicked() {
    this._location.back();
    this.href = this.router.url;
    if (this.href == '/home/dashboard') {
      this.visible = false;
    }
    else {
      this.visible = true;
    }
  }

  ngOnDestroy() { }

}
