import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalizationRoutingModule } from './localization-routing.module';
import { WebTranslationsComponent } from './web-translations/web-translations.component';
import { RatesComponent } from './rates/rates.component';
import { TaxesComponent } from './taxes/taxes.component';

import { AdminTranslationsComponent } from './admin-translations/admin-translations.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [WebTranslationsComponent, RatesComponent, TaxesComponent,AdminTranslationsComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    LocalizationRoutingModule,
    
  ]
})
export class LocalizationModule { }
