import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-admin-user',
  templateUrl: './create-admin-user.component.html',
  styleUrls: ['./create-admin-user.component.scss']
})
export class CreateAdminUserComponent implements OnInit {
  isAdding = false;
  CreateUserModel: any = {};
  UserList: any = [];
  myDate;
  Roles: any = [];

  constructor(private router: Router, private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {
    this.myDate = new Date();
    if (!this.TokenService.getUser().IsAdmin) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.GetRoles();
  }

  GetRoles() {
    this.http.get<any>(environment.url + 'Roles/GetRoles').subscribe(data => {
      this.Roles = data;
    });
  }

  AddUser() {
    this.isAdding = true;
    const body = {
      UserName: this.CreateUserModel.UserName, Password: this.CreateUserModel.Password, IsAdmin: this.CreateUserModel.IsAdmin,
      RoleRef: this.CreateUserModel.RoleRef, IsActive: this.CreateUserModel.IsActive, IsDeleted: false
    };
    this.http.post<any>(environment.url + 'User/CreateUser', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Kullanıcı Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/setting/admin-user-list"]);
      }
      else{
        this.isAdding = false;
        this.toastr.error(data.Message, 'Hata!', {
          timeOut: 2000,
        });
      }
    },
      error => {
        this.toastr.error('Kullanıcı Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
