<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div
          class="card-header"
          style="padding: 20px 30px; display: flex; align-items: center"
        >
          <h5>{{ MenuName }}</h5>
          <div class="custom-control custom-checkbox ml-sm-2">
            <input
              type="checkbox"
              class="custom-control-input"
              name="IsActive"
              id="IsActive"
              #IsActive="ngModel"
              [(ngModel)]="Model.IsActive"
            />
            <label class="custom-control-label" for="IsActive"></label>
          </div>
          <select
            class="form-control select"
            style="font-size: 13px; margin-left: 10px; width: auto"
            [(ngModel)]="Model.LanguageCode"
            name="LanguageCode"
            required
          >
            <option selected disabled [value]="undefined">Seçiniz</option>
            <option value="TR">Türkçe</option>
            <option value="EN">English</option>
            <option value="RU">Russian</option>
          </select>
          <div class="sortOrderLabel">
            <label class="label">Menü Sırası: </label>
            <input
              type="text"
              class="form-control input"
              style="margin-left: 10px"
              name="SortOrder"
              [(ngModel)]="Model.SortOrder"
            />
          </div>
        </div>

        <div
          *ngIf="!isFirstLoading; else loading"
          class="card-body"
          style="padding: 10px 30px"
        >
          <div class="table-responsive">
            <table class="table" style="white-space: nowrap">
              <thead>
                <tr>
                  <th scope="col" style="width: 20%">Menü Etiketi</th>
                  <th scope="col" style="width: 10%">Arka Renk</th>
                  <th scope="col" style="width: 10%">Yazı Rengi</th>
                  <th scope="col" style="width: 13%">Stil</th>
                  <th scope="col" style="width: 15%">Tip</th>
                  <th scope="col" style="width: 20%">Link</th>
                  <th scope="col" style="width: 13%">Açılış Tipi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      class="form-control input"
                      (change)="MenuTagOnChange()"
                      name="MenuTag"
                      [(ngModel)]="Model.MenuTag"
                    />
                  </td>
                  <td>
                    <input
                      type="color"
                      class="form-control input"
                      (change)="BackColorOnChange()"
                      id="backcolor"
                      name="BackColor"
                      [(ngModel)]="Model.BackColor"
                    />
                  </td>
                  <td>
                    <input
                      type="color"
                      class="form-control input"
                      (change)="FontColorOnChange()"
                      id="favcolor"
                      name="FontColor"
                      [(ngModel)]="Model.FontColor"
                    />
                  </td>
                  <td>
                    <select
                      class="form-control select"
                      name="FontStyle"
                      [(ngModel)]="Model.FontStyle"
                      required
                    >
                      <option selected disabled [value]="undefined">
                        Seçiniz
                      </option>
                      <option value="Bold">Bold</option>
                      <option value="Regular">Regular</option>
                    </select>
                  </td>
                  <td>
                    <select
                      class="form-control select"
                      name="MenuType"
                      (change)="MenuTypeOnChange($event)"
                      [(ngModel)]="Model.MenuType"
                      required
                    >
                      <option selected disabled [value]="undefined">
                        Seçiniz
                      </option>
                      <option value="1">Kategori</option>
                      <option value="2">İçerik</option>
                      <option value="3">Link</option>
                      <option value="4">#</option>
                    </select>
                  </td>

                  <td>
                    <input
                      type="text"
                      class="form-control input"
                      name="MenuLink"
                      [disabled]="Model.MenuType == 4"
                      [(ngModel)]="Model.MenuLink"
                    />
                  </td>
                  <td>
                    <select
                      class="form-control select"
                      name="OpenType"
                      [(ngModel)]="Model.OpenType"
                      required
                    >
                      <option selected disabled [value]="undefined">
                        Seçiniz
                      </option>
                      <option value="_self">_self</option>
                      <option value="_blank">_blank</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="create-colon">
            <label class="label">Kolon Sayısı : </label>
            <select
              class="form-control select"
              id="setcolumn"
              name="ColumnAmount"
              [disabled]="ColumnList.length"
              [(ngModel)]="Model.ColumnAmount"
              style="width: auto; margin-left: 10px; margin-right: 10px"
            >
              <option selected disabled [value]="undefined">Seçiniz</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
            <button
              type="button"
              *ngIf="!ColumnList.length"
              class="btn btn-primary"
              [disabled]="!Model.ColumnAmount"
              (click)="CreateTableColumn()"
              style="font-size: 11px; padding: 5px 15px"
            >
              Oluştur
            </button>
            <button
              type="button"
              *ngIf="ColumnList.length"
              [disabled]="Model.ColumnAmount >= 10"
              class="btn btn-primary"
              (click)="AddTableColumn()"
              style="font-size: 11px; padding: 5px 15px"
            >
              Kolon Ekle
            </button>
          </div>
          <hr />
          <div class="row" style="margin: 0">
            <div
              class="table-responsive"
              *ngFor="let item of ColumnList; let i = index"
              style="margin-top: 15px; margin-bottom: 15px"
            >
              <h4 class="column-header">
                KOLON {{ i + 1
                }}<a
                  type="submit"
                  (click)="DeleteTableColumn(item.Id)"
                  style="margin-left: 10px"
                  ><i
                    class="fa fa-trash"
                    style="font-size: 17px; color: #e6466f"
                  ></i
                ></a>
              </h4>
              <table
                class="table"
                style="white-space: nowrap; text-align: center"
              >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">#</th>
                    <th scope="col">İçerik</th>
                    <th scope="col">Menü Etiketi</th>
                    <th scope="col">Görsel Adı</th>
                    <th scope="col">Görsel</th>
                    <th scope="col">Arka Renk</th>
                    <th scope="col">Yazı Rengi</th>
                    <th scope="col">Stil</th>
                    <th scope="col">Tip</th>
                    <th scope="col">Cinsiyet</th>
                    <th scope="col">Kategori</th>
                    <th scope="col">Özellik</th>
                    <th scope="col">Özellik Değeri</th>
                    <th scope="col">Link</th>
                    <th scope="col">Açılış Tipi</th>
                    <th scope="col">Status</th>
                    <th scope="col">Stok Kontrol</th>
                  </tr>
                </thead>
                <tbody *ngFor="let row of item.Rows; let a = index">
                  <tr>
                    <td>
                      <a
                        type="button"
                        (click)="DeleteTableRows(item.Id, row.Id)"
                        ><i
                          class="fa fa-trash"
                          style="font-size: 17px; color: #e6466f"
                        ></i
                      ></a>
                    </td>
                    <td>
                      <span>{{ a + 1 }}</span>
                    </td>
                    <td>
                      <select
                        class="form-control select-column"
                        name="ContentType"
                        [(ngModel)]="row.ContentType"
                        (change)="
                          RowContentTypeOnChange(item.Id, row.Id, $event)
                        "
                      >
                        <option selected disabled [value]="undefined">
                          Seçiniz
                        </option>
                        <option value="0">Metin</option>
                        <option value="1">Görsel</option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control column-input"
                        (change)="
                          RowMenuTagOnChange(item.Id, row.Id, row.MenuTag)
                        "
                        *ngIf="row.ContentType == 0"
                        name="MenuTag"
                        [(ngModel)]="row.MenuTag"
                      />
                      <span *ngIf="row.ContentType != 0 && row.ContentType == 1"
                        >-</span
                      >
                    </td>
                    <td>
                      <input
                        *ngIf="!(row.ContentType != 1 && row.ContentType == 0)"
                        type="text"
                        class="form-control column-input"
                        name="ImageName"
                        [(ngModel)]="row.ImageName"
                      />
                      <span *ngIf="row.ContentType != 1 && row.ContentType == 0"
                        >-</span
                      >
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary"
                        *ngIf="row.ImageLocation"
                        (click)="DeleteImage(item.Id, row.Id)"
                        style="
                          font-size: 14px;
                          line-height: 13px;
                          padding: 7px;
                          margin-right: 10px;
                          text-transform: none;
                        "
                      >
                        Sil
                      </button>

                      <button
                        type="button"
                        class="btn btn-primary"
                        (click)="openImage(item.Id, row.Id)"
                        [disabled]="isUploadImage"
                        [ngStyle]="{'padding': row.ImageLocation ? '1px 2px' : '1px 18px'}"
                        style="
                          font-size: 12px;
                          line-height: 13px;
                        "
                      >
                        Yükle<span style="font-size: 10px; display: block"
                          >800x900</span
                        ><span
                          *ngIf="isUploadImage"
                          class="spinner-border spinner-border-sm ml-1"
                        ></span>
                      </button>
                      <input
                        type="file"
                        class="addimage"
                        id="addimage{{ item.Id }}{{ row.Id }}"
                        accept="image/*"
                        (change)="
                          addImageChange($event.target.files, item.Id, row.Id)
                        "
                        style="display: none"
                      />
                    </td>
                    <td>
                      <input
                        type="color"
                        class="form-control column-input"
                        name="BackColor"
                        *ngIf="row.ContentType == 0"
                        [(ngModel)]="row.BackColor"
                      />
                      <span *ngIf="row.ContentType != 0 && row.ContentType == 1"
                        >-</span
                      >
                    </td>
                    <td>
                      <input
                        type="color"
                        class="form-control column-input"
                        name="FontColor"
                        *ngIf="row.ContentType == 0"
                        [(ngModel)]="row.FontColor"
                      />
                      <span *ngIf="row.ContentType != 0 && row.ContentType == 1"
                        >-</span
                      >
                    </td>
                    <td>
                      <select
                        class="form-control select-column"
                        name="FontStyle"
                        *ngIf="row.ContentType == 0"
                        [(ngModel)]="row.FontStyle"
                      >
                        <option selected disabled [value]="undefined">
                          Seçiniz
                        </option>
                        <option value="Bold">Bold</option>
                        <option value="Regular">Regular</option>
                      </select>
                      <span *ngIf="row.ContentType != 0 && row.ContentType == 1"
                        >-</span
                      >
                    </td>
                    <td>
                      <select
                        class="form-control select-column"
                        name="RowType"
                        *ngIf="row.ContentType == 0 || row.ContentType == 1"
                        [(ngModel)]="row.RowType"
                        (change)="RowTypeOnChange(item.Id, row.Id, $event)"
                      >
                        <option selected disabled [value]="undefined">
                          Seçiniz
                        </option>
                        <option value="0">Kategori</option>
                        <option value="1">İçerik</option>
                        <option value="2">Link</option>
                        <option value="3">#</option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control select"
                        [ngStyle]="{
                          'min-width': row.RowType != 0 ? '100px' : null
                        }"
                        name="GenderRef"
                        [disabled]="row.RowType != 0"
                        (change)="
                          GenderOnChange(item.Id, row.Id, $event.target.value)
                        "
                        [(ngModel)]="row.GenderRef"
                        required
                      >
                        <option selected [value]="0">Seçiniz</option>
                        <option *ngFor="let item of Genders" [value]="item.Ref">
                          {{ item.ValName }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control select"
                        [ngStyle]="{
                          'min-width': row.RowType != 0 ? '100px' : null
                        }"
                        name="CategoryRef"
                        [disabled]="row.RowType != 0"
                        (change)="
                          CategoryOnChange(item.Id, row.Id, $event.target.value)
                        "
                        [(ngModel)]="row.CategoryRef"
                        required
                      >
                        <option selected [value]="0">Seçiniz</option>
                        <option
                          *ngFor="let item of Categories"
                          [value]="item.CategoryRef"
                        >
                          {{ item.CategoryName }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control select"
                        [ngStyle]="{
                          'min-width': row.RowType != 0 ? '100px' : null
                        }"
                        name="PropRef"
                        [disabled]="row.RowType != 0"
                        (change)="
                          PropOnChange(item.Id, row.Id, $event.target.value)
                        "
                        [(ngModel)]="row.PropRef"
                        required
                      >
                        <option selected [value]="0">Seçiniz</option>
                        <option
                          *ngFor="let item of row.Props"
                          [value]="item.PropRef"
                        >
                          {{ item.PropName }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control select"
                        name="PropValRef"
                        [ngStyle]="{
                          'min-width': row.RowType != 0 ? '100px' : null
                        }"
                        [disabled]="row.RowType != 0"
                        [(ngModel)]="row.PropValRef"
                        required
                      >
                        <option selected [value]="0">Seçiniz</option>
                        <option
                          *ngFor="let item of row.PropVals"
                          [value]="item.PropRef"
                        >
                          {{ item.PropValName }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        [ngStyle]="{
                          'min-width': '100px'
                        }"
                        class="form-control column-input"
                        name="RowLink"
                        [disabled]="row.RowType == 3"
                        *ngIf="row.ContentType == 0 || row.ContentType == 1"
                        [(ngModel)]="row.RowLink"
                      />
                    </td>
                    <td>
                      <select
                        class="form-control select-column"
                        name="OpenType"
                        [(ngModel)]="row.OpenType"
                        *ngIf="row.ContentType == 0 || row.ContentType == 1"
                      >
                        <option selected disabled [value]="undefined">
                          Seçiniz
                        </option>
                        <option value="_self">_self</option>
                        <option value="_blank">_blank</option>
                      </select>
                    </td>
                    <td>
                      <select
                        class="form-control select-column"
                        name="IsActive"
                        [(ngModel)]="row.IsActive"
                        *ngIf="row.ContentType == 0 || row.ContentType == 1"
                      >
                        <option selected disabled [value]="undefined">
                          Seçiniz
                        </option>
                        <option value="true">Aktif</option>
                        <option value="false">Pasif</option>
                      </select>
                    </td>
                    <td>
                      <div
                        class="custom-control custom-checkbox mr-sm-2"
                        *ngIf="row.ContentType == 0"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="StockControl"
                          [(ngModel)]="row.StockControl"
                          id="SubMenuControl{{ item.Id }}{{ row.Id }}"
                        />
                        <label
                          class="custom-control-label"
                          for="SubMenuControl{{ item.Id }}{{ row.Id }}"
                        ></label>
                      </div>
                      <span *ngIf="row.ContentType != 0 && row.ContentType == 1"
                        >-</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                type="button"
                class="btn btn-primary"
                (click)="AddRows(item.Id)"
                style="
                  font-size: 11px;
                  padding: 5px 15px;
                  align-items: center;
                  display: flex;
                "
              >
                <i
                  class="fa fa-plus"
                  style="font-size: 17px; margin-right: 5px; color: white"
                ></i
                >Satır Ekle
              </button>

              <hr />
            </div>
          </div>
          <div style="float: right">
            <button
              type="button"
              class="btn btn-primary"
              [disabled]="isSaving"
              (click)="SaveMegaMenu()"
              style="font-size: 11px; margin-right: 10px"
            >
              Kaydet<span
                *ngIf="isSaving"
                class="spinner-border spinner-border-sm ml-1"
              ></span>
            </button>
          </div>
        </div>
        <ng-template #loading>
          <div class="card-body" style="padding: 10px 30px">
            <span
              class="spinner-border spinner-border-sm ml-1"
              style="left: 50%; position: relative"
            ></span>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
