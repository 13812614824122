<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Account">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                   
                                </div>
                            </ng-template>
                        </ngb-tab>

                    </ngb-tabset>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->