import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-web-translations',
  templateUrl: './web-translations.component.html',
  styleUrls: ['./web-translations.component.scss']
})
export class WebTranslationsComponent implements OnInit {

  isLoading = false;
  isSaving = false;
  isEditing = false;
  isDeleting = false;
  DeletingItem;
  EditingMainKey;
  AddingMainKey;
  isAdding;
  WebTranslates;
  TranslationsList: Array<any> = [];
  Model: any = {};

  constructor(private http: HttpClient, private modalService: NgbModal, private toastr: ToastrService, private TokenService: TokenService,private router: Router,) { 
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['WebSiteTranslate']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.getWebTranslates();
  }
  getWebTranslates() { // Get User Data
    this.http.get<any>(environment.url + "App/GetWebTranslates").subscribe(data => {
      this.WebTranslates = data;
      this.isLoading = true
      this.WebTranslates.forEach((myObject) => {
        this.TranslationsList.push({ LangCode: "TR", MainKey: myObject.MainText, TranslatedText: myObject.TR });
        this.TranslationsList.push({ LangCode: "EN", MainKey: myObject.MainText, TranslatedText: myObject.EN });
        this.TranslationsList.push({ LangCode: "RU", MainKey: myObject.MainText, TranslatedText: myObject.RU });
      });
    });
  }

  EditTranslate(Key) {
    this.isEditing = true;
    this.EditingMainKey = Key;
    this.Model.TranslateEN = this.TranslationsList.find(x => x.MainKey === this.EditingMainKey && x.LangCode == "EN").TranslatedText;
    this.Model.TranslateTR = this.TranslationsList.find(x => x.MainKey === this.EditingMainKey && x.LangCode == "TR").TranslatedText;
    this.Model.TranslateRU = this.TranslationsList.find(x => x.MainKey === this.EditingMainKey && x.LangCode == "RU").TranslatedText;
    this.Model.TranslateKey = this.EditingMainKey;
  }

  EditOkay() {
    if (!this.isAdding) {
      let indexEN = this.TranslationsList.indexOf(this.TranslationsList.find(x => x.MainKey === this.EditingMainKey && x.LangCode == "EN"));
      let indexTR = this.TranslationsList.indexOf(this.TranslationsList.find(x => x.MainKey === this.EditingMainKey && x.LangCode == "TR"));
      let indexRU = this.TranslationsList.indexOf(this.TranslationsList.find(x => x.MainKey === this.EditingMainKey && x.LangCode == "RU"));
      let indexWebTranslate = this.WebTranslates.indexOf(this.WebTranslates.find(x => x.MainText === this.EditingMainKey));
      this.TranslationsList[indexEN] = { LangCode: "EN", MainKey: this.Model.TranslateKey, TranslatedText: this.Model.TranslateEN };
      this.TranslationsList[indexTR] = { LangCode: "TR", MainKey: this.Model.TranslateKey, TranslatedText: this.Model.TranslateTR };
      this.TranslationsList[indexRU] = { LangCode: "RU", MainKey: this.Model.TranslateKey, TranslatedText: this.Model.TranslateRU };
      this.WebTranslates[indexWebTranslate] = { EN: this.Model.TranslateEN, MainText: this.Model.TranslateKey, RU: this.Model.TranslateRU, TR: this.Model.TranslateTR };
      this.isEditing = false;
      this.isAdding = false;
      this.EditingMainKey = "";
      this.AddingMainKey = "";
      this.Model.TranslateEN = null;
      this.Model.TranslateTR = null;
      this.Model.TranslateRU = null;
      this.Model.TranslateKey = null;
    }
    else {
      this.WebTranslates[0] = { EN: this.Model.TranslateEN, MainText: this.Model.TranslateKey, RU: this.Model.TranslateRU, TR: this.Model.TranslateTR };
      this.TranslationsList.push({ LangCode: "EN", MainKey: this.Model.TranslateKey, TranslatedText: this.Model.TranslateEN });
      this.TranslationsList.push({ LangCode: "TR", MainKey: this.Model.TranslateKey, TranslatedText: this.Model.TranslateTR });
      this.TranslationsList.push({ LangCode: "RU", MainKey: this.Model.TranslateKey, TranslatedText: this.Model.TranslateRU });
      this.isEditing = false;
      this.isAdding = false;
      this.EditingMainKey = "";
      this.AddingMainKey = "";
      this.Model.TranslateEN = null;
      this.Model.TranslateTR = null;
      this.Model.TranslateRU = null;
      this.Model.TranslateKey = null;
    }
  }
  EditCancel() {
    if (this.isAdding) {
      this.WebTranslates.splice(0, 1);
    }
    this.isEditing = false;
    this.isAdding = false;
    this.EditingMainKey = "";
    this.AddingMainKey = "";
    this.Model.TranslateEN = null;
    this.Model.TranslateTR = null;
    this.Model.TranslateRU = null;
    this.Model.TranslateKey = null;
  }

  AddNewKey() {
    this.WebTranslates.unshift({ EN: "", MainText: "New", RU: "", TR: "" });
    this.isAdding = true;
    this.AddingMainKey = "New";
  }

  SaveTranslations() {
    this.isSaving = true;
    const body = this.TranslationsList;
    this.http.post<any>(environment.url + 'App/SaveTranslateWebPanel', body).subscribe(data => {
      this.isSaving = false;
      if (data == true) {
        this.modalService.dismissAll();
        this.toastr.success("Çevirmeler Kaydedildi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Çeviriler Kaydedilemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });


  }

  DeleteTranslate() {
    this.isDeleting = true;
    let index = this.WebTranslates.indexOf(this.WebTranslates.find(x => x.MainKey === this.DeletingItem));
    this.WebTranslates.splice(index, 1);
    for (let index = 0; index < 3; index++) {
      let index = this.TranslationsList.indexOf(this.TranslationsList.find(x => x.MainKey === this.DeletingItem));
      this.TranslationsList.splice(index, 1);
    }
    this.http.post<any>(environment.url + 'App/DeleteKeyWebPanel?Key=' + this.DeletingItem, null).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.getWebTranslates();
      if (data) {
        this.toastr.success("Çeviri Silindi", '', {
          timeOut: 3000,
        });
        this.modalService.dismissAll();
      }
      else {
        this.toastr.error("Çeviri Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
        this.modalService.dismissAll();
      }
    });
  }
  DeleteTranslateModal(file,key) {
    this.DeletingItem = key;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
}
