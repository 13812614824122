<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Page</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="CMSContentPageForm" class="needs-validation" novalidate #CMSContentPageForm="ngForm"
                            (ngSubmit)="CMSContentPageForm.form.valid && AddCMSContentPage()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">İçerik Dili</label>
                                <select class="form-control col-xl-8 col-md-7 select" name="LanguageCode"
                                    #LanguageCode="ngModel" [(ngModel)]="CreatePageModel.LanguageCode"
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CMSContentPageForm.submitted && LanguageCode.invalid  }"
                                    required>
                                    <option selected disabled [value]="undefined">Seçiniz</option>
                                    <option value="TR">Türkçe</option>
                                    <option value="EN">English</option>
                                    <option value="RU">Russian</option>
                                </select>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Sayfa Adı</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="PageName" (change)="PageNameOnChange()"
                                    #PageName="ngModel" [(ngModel)]="CreatePageModel.PageName" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CMSContentPageForm.submitted && PageName.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Menü/Link</label>
                                <select class="form-control col-xl-8 col-md-7 select" style="font-size: 13px;"
                                    name="MenuRef" #MenuRef="ngModel" [(ngModel)]="CreatePageModel.MenuRef"
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CMSContentPageForm.submitted && MenuRef.invalid }">
                                    <option selected disabled [value]="undefined">Seçiniz</option>
                                    <option *ngFor="let item of MenuList" [value]="item.Ref">{{ item.MenuName }} - {{ item.LanguageCode }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">URL</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="PageURL" #PageURL="ngModel"
                                    [(ngModel)]="CreatePageModel.PageURL" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CMSContentPageForm.submitted && PageURL.invalid  }" required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Durum</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input id="checkbox-primary-2" type="checkbox" name="IsActive" #IsActive="ngModel"
                                        [(ngModel)]="CreatePageModel.IsActive">
                                    <label for="checkbox-primary-2">Aktif</label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">İletişim Formu</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input id="IsContactForm" type="checkbox" name="IsContactForm" #IsContactForm="ngModel"
                                        [(ngModel)]="CreatePageModel.IsContactForm">
                                    <label for="IsContactForm">Aktif</label>
                                </div>
                            </div>
                            <div class="form-group row editor-label">
                                <label class="col-xl-3 col-md-4"> İçerik</label>
                                <div class="col-xl-8 col-md-7 editor-space">
                                    <ck-editor name="editor1" [(ngModel)]="editorValue" skin="moono-lisa" language="en"
                                        [fullPage]="true"></ck-editor>
                                </div>
                            </div>
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Oluştur<span
                                    *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                    style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
            <!-- <ul class="nav nav-tabs tab-coupon" id="myTab" role="tablist">
                <li class="nav-item"><a class="nav-link active show" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true" data-original-title="" title="">General</a></li>
                <li class="nav-item"><a class="nav-link" id="restriction-tabs" data-toggle="tab" href="#seo" role="tab" aria-controls="seo" aria-selected="false" data-original-title="" title="">SEO</a></li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="general" role="tabpanel" aria-labelledby="general-tab">
                    <form class="needs-validation" novalidate="">
                        <h4>General</h4>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4"> Name</label>
                            <input class="form-control col-xl-8 col-md-7"  type="text" required="">
                        </div>
                        <div class="form-group row editor-label">
                            <label class="col-xl-3 col-md-4"> Description</label>
                            <div class="col-xl-8 col-md-7 editor-space">
                                <textarea id="editor1" name="editor1" cols="30" rows="10"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">Status</label>
                            <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                <input id="checkbox-primary-2" type="checkbox" data-original-title="" title="">
                                <label for="checkbox-primary-2">Enable the Page</label>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="tab-pane fade" id="seo" role="tabpanel" aria-labelledby="seo-tab">
                    <form class="needs-validation" novalidate="">
                        <h4>SEO</h4>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4">Meta Title</label>
                            <input class="form-control col-xl-8 col-md-7" id="validationCustom2" type="text" required="" >
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom3" class="col-xl-3 col-md-4">Meta Keywords</label>
                            <input class="form-control col-xl-8 col-md-7" id="validationCustom3" type="text" required="" >
                        </div>
                        <div class="form-group row editor-label">
                            <label class="col-xl-3 col-md-4">Meta Description</label>
                            <textarea rows="4" class="col-xl-8 col-md-7"></textarea>
                        </div>
                    </form>
                </div>
            </div> -->

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->