import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firm-multipliers',
  templateUrl: './firm-multipliers.component.html',
  styleUrls: ['./firm-multipliers.component.scss']
})
export class FirmMultiplierComponent implements OnInit {

  FirmMultipliers: any = {};
  isAdding;
  isUpdatingCurr;
  SelectedCurRate;
  MinPurchaseSetAmount;

  constructor(private router: Router, private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {

  }

  ngOnInit() {
    this.GetFirmMultiplier();
  }

  GetFirmMultiplier() {
    this.http.get<any>(environment.url + 'FirmMultipler/GetFirmMultipler').subscribe(data => {
      this.FirmMultipliers = data;
      this.MinPurchaseSetAmount = data.MinPurchaseSetAmountForSoldOut;
      if (this.FirmMultipliers.UseManuelCurrRate) this.SelectedCurRate = 'UseManuelCurrRate';
      if (this.FirmMultipliers.UseCentralBankExchangeRate) this.SelectedCurRate = 'UseCentralBankExchangeRate';
      if (this.FirmMultipliers.UseFreeMarketExchangeRate) this.SelectedCurRate = 'UseFreeMarketExchangeRate';
    });
  }
  UpdateCurr() {
    this.isUpdatingCurr = true;
    this.http.get<any>(environment.url + 'FirmMultipler/UpdateTCMBRates').subscribe(tcmb => {
      if (!tcmb.Error) {
        this.FirmMultipliers.CentralBankExchangeRate = tcmb[0].satis;
        this.isUpdatingCurr = false;
        this.toastr.success('Merkez Bankası Güncelleme Başarılı!', 'Hata!', {
          timeOut: 2000,
        });
      }
      else {
        this.isUpdatingCurr = false;
        this.toastr.error('Merkez Bankası Güncellenirken Bir Hata Oluştu!', 'Hata!', {
          timeOut: 2000,
        });
      }

    });
    this.http.get<any>(environment.url + 'FirmMultipler/UpdateGreeMarketRates').subscribe(freemarket => {
      if (!freemarket.Error) {
        this.FirmMultipliers.FreeMarketExchangeRate = freemarket[0].satis;
        this.isUpdatingCurr = false;
        this.toastr.success('Serbest Piyasa Güncelleme Başarılı!', 'Hata!', {
          timeOut: 2000,
        });
      }
      else {
        this.isUpdatingCurr = false;
        this.toastr.error('Serbest Piyasa Güncellenirken Bir Hata Oluştu!', 'Hata!', {
          timeOut: 2000,
        });
      }
    });
  }

  SaveFirmMultipler() {
    this.isAdding = true;
    if (this.SelectedCurRate == 'UseManuelCurrRate') {
      this.FirmMultipliers.UseManuelCurrRate = true;
      this.FirmMultipliers.UseCentralBankExchangeRate = false;
      this.FirmMultipliers.UseFreeMarketExchangeRate = false;
    }
    if (this.SelectedCurRate == 'UseCentralBankExchangeRate') {
      this.FirmMultipliers.UseCentralBankExchangeRate = true;
      this.FirmMultipliers.UseFreeMarketExchangeRate = false;
      this.FirmMultipliers.UseManuelCurrRate = false;
    }
    if (this.SelectedCurRate == 'UseFreeMarketExchangeRate') {
      this.FirmMultipliers.UseFreeMarketExchangeRate = true;
      this.FirmMultipliers.UseCentralBankExchangeRate = false;
      this.FirmMultipliers.UseManuelCurrRate = false;
    }

    const body = { MinPurchaseSetAmountForSoldOut: this.MinPurchaseSetAmount,
      ManuelCurrRate: this.FirmMultipliers.ManuelCurrRate, CentralBankExchangeRate: this.FirmMultipliers.CentralBankExchangeRate, FreeMarketExchangeRate: this.FirmMultipliers.FreeMarketExchangeRate,
      UseManuelCurrRate: this.FirmMultipliers.UseManuelCurrRate, UseCentralBankExchangeRate: this.FirmMultipliers.UseCentralBankExchangeRate ,UseFreeMarketExchangeRate: this.FirmMultipliers.UseFreeMarketExchangeRate
    };
    this.http.post<any>(environment.url + 'FirmMultipler/SaveFirmMultipler', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Firma Çarpanları Kaydedildi!", '', {
          timeOut: 2000,
        });
      }
      else {
        this.isAdding = false;
        this.toastr.error(data.Message, 'Hata!', {
          timeOut: 2000,
        });
      }
    },
      error => {
        this.toastr.error('Firma Çarpanları Kaydedilemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }


}
