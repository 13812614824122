<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Pop Up Lists</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <div class="clearfix"></div>
            <a class="btn btn-primary mr-1 mb-3" routerLinkActive="active" [routerLink]="'../add-pop-up-contents'"
              style="float: right;text-transform: none;font-size: 11px;font-weight: 500;">Yeni Pop Up Oluştur</a>
            <div class="table-responsive-sm">
              <table class="table" style="text-align: center;">
                <thead>
                  <tr>
                    <th scope="col">Pop Up Adı</th>
                    <th scope="col">Dil</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Düzenle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of PopupList">
                    <td>Anasayfa Pop up - {{ item.LangCode }}</td>
                    <td>{{ item.LangCode }}</td>
                    <td>
                      <div *ngIf="!item.IsActive">
                        <i class="fa fa-circle font-warning f-12"></i>
                      </div>
                      <div *ngIf="item.IsActive">
                        <i class="fa fa-circle font-success f-12"></i>
                      </div>
                    </td>
                    <td><a type="button" (click)="gotoPageEdit(item.Ref)"><i class="fa fa-edit"
                          style="font-size: 17px;"></i></a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>