<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="OrderDetail" [@fadeInOut]>
    <div class="card">
        <div class="card-header">
            <h5><i class="fa fa-user mr-2"></i>{{OrderDetail.orderFiche.CustName}}</h5>
            <h6 style="margin-bottom: 0;">Order Number : {{OrderDetail.orderFiche.FicheNo}}</h6>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="Order Detail">
                    <ng-template ngbTabContent>
                        <div class="row mb-4">
                            <div class="col-sm-4">
                                <div class="order-card" style="padding: 0;">
                                    <div class="order-title-background">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Account Information
                                        </h4>
                                        <a (click)="gotoUser()" style="float: right;cursor: pointer;"><i
                                                class="fa fa-user" style="font-size: 20px;color: #205799;"></i></a>
                                    </div>
                                    <div class="order-address-body" style="padding: 10px;">
                                        <div class="row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Order Date</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="order-info-text">{{ OrderDetail.orderFiche.ShippedDate |
                                                    date:'dd/MM/yyyy HH:mm' }}</span>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">IP</label>

                                            </div>
                                            <div class="col-sm-8">
                                                <span class="order-info-text">{{OrderDetail.orderFiche.Country}}</span>

                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Where Sold</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span
                                                    class="order-info-text">{{OrderDetail.orderFiche.LanguageCode}}</span>
                                            </div>

                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Name Surname</label>

                                            </div>
                                            <div class="col-sm-8">
                                                <span class="order-info-text">
                                                    {{OrderDetail.orderFiche.CustName}}</span>

                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="label"> E-Mail</label>

                                            </div>
                                            <div class="col-sm-8">
                                                <span class="order-info-text">{{OrderDetail.orderFiche.EMail}}</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="order-card" style="padding: 0;height: 100%;">
                                    <div class="order-title-background">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Shipment Address</h4>
                                        <a (click)="EditShipmentAddress()" *ngIf="!isEditingShipmentAddress"
                                            style="cursor: pointer;"><i class="fa fa-pencil"
                                                style="font-size: 19px;float: right;color: #ff8084;"></i></a>
                                        <div style="float: right;" *ngIf="isEditingShipmentAddress">
                                            <a type="button" (click)="EditShipmentAddressOkay()"><i class="fa fa-check"
                                                    style="font-size: 19px;color: #22af47;"></i></a>
                                            <a type="button" (click)="EditShipmentAddressCancel()"><i
                                                    class="fa fa-times"
                                                    style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                        </div>
                                    </div>
                                    <div class="order-address-body" style="padding: 10px;">
                                        <span *ngIf="!isEditingShipmentAddress"
                                            style="font-size: 13px;display: block;margin-bottom: 10px;">{{OrderDetail.orderFiche.ShipmentFirstName}}
                                            - {{OrderDetail.orderFiche.ShipmentLastName}}</span>
                                        <label *ngIf="!isEditingShipmentAddress" class="label"
                                            style="font-weight: 600;display: block;">Adres</label>
                                        <span *ngIf="!isEditingShipmentAddress"
                                            style="font-size: 13px;">{{OrderDetail.orderFiche.ShipmentAddress}}
                                        </span>
                                        <div class="row mt-2" *ngIf="!isEditingShipmentAddress">
                                            <div class="col-sm-4">
                                                <label class="label" style="font-weight: 600;">Post Code</label>
                                                <span style="font-size: 13px;display: block;">
                                                    {{OrderDetail.orderFiche.ShipmentPostCode}}</span>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="label" style="font-weight: 600;">City</label>
                                                <span style="font-size: 13px;display: block;">
                                                    {{OrderDetail.orderFiche.ShipmentCity}}</span>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="label" style="font-weight: 600;">Country</label>
                                                <span style="font-size: 13px;display: block;">
                                                    {{OrderDetail.orderFiche.ShipmentCountry}}</span>
                                            </div>
                                        </div>

                                        <div class="row" *ngIf="isEditingShipmentAddress">
                                            <div class="col-sm-6 mb-3">
                                                <label class="label" style="font-weight: 600;">Adı</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="ShipmentFirstName"
                                                    [(ngModel)]="ShipmentAddressModel.ShipmentFirstName" />
                                            </div>
                                            <div class="col-sm-6 mb-3">
                                                <label class="label" style="font-weight: 600;">Soyadı</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="ShipmentLastName"
                                                    [(ngModel)]="ShipmentAddressModel.ShipmentLastName" />
                                            </div>
                                            <div class="col-sm-12 mb-3">
                                                <label class="label" style="font-weight: 600;">Adres</label>
                                                <textarea class="form-control" style="font-size: 12px;"
                                                    name="ShipmentAddress"
                                                    [(ngModel)]="ShipmentAddressModel.ShipmentAddress"></textarea>
                                            </div>
                                            <div class="col-sm-4" style="padding-right: 5px;">
                                                <label class="label" style="font-weight: 600;">Post Code</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="ShipmentPostCode"
                                                    [(ngModel)]="ShipmentAddressModel.ShipmentPostCode" />
                                            </div>
                                            <div class="col-sm-4" style="padding-right: 5px;padding-left: 5px;">
                                                <label class="label" style="font-weight: 600;">City</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="ShipmentCity"
                                                    [(ngModel)]="ShipmentAddressModel.ShipmentCity" />
                                            </div>
                                            <div class="col-sm-4" style="padding-left: 5px;">
                                                <label class="label" style="font-weight: 600;">Country</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="ShipmentCountry"
                                                    [(ngModel)]="ShipmentAddressModel.ShipmentCountry" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"
                                        style="font-size: 13px;align-items: center;padding: 5px 10px 5px 10px;">
                                        <div class="col-sm-6" *ngIf="!isEditingShipmentAddress">
                                            <label class="label" style="font-weight: 600;display: block;">Company
                                                Name</label>
                                            {{OrderDetail.orderFiche.ShipmentCompanyName}}
                                        </div>
                                        <div class="col-sm-6" *ngIf="isEditingShipmentAddress">
                                            <label class="label" style="font-weight: 600;">Company Name</label>
                                            <input type="text" class="form-control" style="font-size: 12px;"
                                                name="ShipmentCompanyName"
                                                [(ngModel)]="ShipmentAddressModel.ShipmentCompanyName" />
                                        </div>
                                        <div class="col-sm-6" *ngIf="!isEditingShipmentAddress">
                                            <label class="label" style="font-weight: 600;display: block;">Vat
                                                Number</label>
                                            {{OrderDetail.orderFiche.ShipmentTaxNr}}
                                        </div>
                                        <div class="col-sm-6" *ngIf="isEditingShipmentAddress">
                                            <label class="label" style="font-weight: 600;">Vat Number</label>
                                            <input type="text" class="form-control" style="font-size: 12px;"
                                                name="ShipmentTaxNr" [(ngModel)]="ShipmentAddressModel.ShipmentTaxNr" />
                                        </div>
                                    </div>
                                    <div class="row"
                                        style="font-size: 13px;align-items: center;padding: 5px 10px 5px 10px;">
                                        <div class="col-sm-12" *ngIf="!isEditingShipmentAddress">
                                            <label class="label" style="font-weight: 600;display: block;">Mobile
                                                Phone</label>
                                            {{OrderDetail.orderFiche.ShipmentMobilePhone}}
                                        </div>
                                        <div class="col-sm-12" *ngIf="isEditingShipmentAddress">
                                            <label class="label" style="font-weight: 600;">Mobile Phone</label>
                                            <input type="text" class="form-control" style="font-size: 12px;"
                                                name="ShipmentMobilePhone"
                                                [(ngModel)]="ShipmentAddressModel.ShipmentMobilePhone" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="order-card" style="padding: 0;height: 100%;">
                                    <div class="order-title-background">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Invoice Address</h4>
                                        <a (click)="EditInvoiceAddress()" *ngIf="!isEditingInvoiceAddress"
                                            style="cursor: pointer;"><i class="fa fa-pencil"
                                                style="font-size: 19px;float: right;color: #ff8084;"></i></a>
                                        <div style="float: right;" *ngIf="isEditingInvoiceAddress">
                                            <a type="button" (click)="EditInvoiceAddressOkay()"><i class="fa fa-check"
                                                    style="font-size: 19px;color: #22af47;"></i></a>
                                            <a type="button" (click)="EditInvoiceAddressCancel()"><i class="fa fa-times"
                                                    style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                        </div>
                                    </div>
                                    <div class="order-address-body" style="padding: 10px;">
                                        <span *ngIf="!isEditingInvoiceAddress"
                                            style="font-size: 13px;display: block;margin-bottom: 10px;">{{OrderDetail.orderFiche.InvFirstName}}
                                            - {{OrderDetail.orderFiche.InvLastName}}</span>


                                        <span *ngIf="!isEditingInvoiceAddress"
                                            style="font-size: 13px;">{{OrderDetail.orderFiche.Address}}</span>

                                        <div class="row mt-2" *ngIf="!isEditingInvoiceAddress">
                                            <div class="col-sm-4">
                                                <label class="label" style="font-weight: 600;">Post Code</label>
                                                <span *ngIf="!isEditingInvoiceAddress"
                                                    style="font-size: 13px;display: block;">
                                                    {{OrderDetail.orderFiche.PostCode}}</span>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="label" style="font-weight: 600;">City</label>
                                                <span *ngIf="!isEditingInvoiceAddress"
                                                    style="font-size: 13px;display: block;">
                                                    {{OrderDetail.orderFiche.City}}</span>
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="label" style="font-weight: 600;">Country</label>
                                                <span *ngIf="!isEditingInvoiceAddress"
                                                    style="font-size: 13px;display: block;">
                                                    {{OrderDetail.orderFiche.Country}}</span>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isEditingInvoiceAddress">
                                            <div class="col-sm-6 mb-3">
                                                <label class="label" style="font-weight: 600;">Adı</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="InvFirstName"
                                                    [(ngModel)]="InvoiceAddressModel.InvFirstName" />
                                            </div>
                                            <div class="col-sm-6 mb-3">
                                                <label class="label" style="font-weight: 600;">Soyadı</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="InvLastName" [(ngModel)]="InvoiceAddressModel.InvLastName" />
                                            </div>
                                            <div class="col-sm-12 mb-3">
                                                <label class="label"
                                                    style="font-weight: 600;display: block;">Adres</label>
                                                <textarea class="form-control" style="font-size: 12px;"
                                                    name="InvoiceAddress"
                                                    [(ngModel)]="InvoiceAddressModel.Address"></textarea>
                                            </div>
                                            <div class="col-sm-4" style="padding-right: 5px;">
                                                <label class="label" style="font-weight: 600;">Post Code</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="PostCode" [(ngModel)]="InvoiceAddressModel.PostCode" />
                                            </div>
                                            <div class="col-sm-4" style="padding-right: 5px;padding-left: 5px;">
                                                <label class="label" style="font-weight: 600;">City</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="InvoiceCity" [(ngModel)]="InvoiceAddressModel.City" />
                                            </div>
                                            <div class="col-sm-4" style="padding-left: 5px;">
                                                <label class="label" style="font-weight: 600;">Country</label>
                                                <input type="text" class="form-control" style="font-size: 12px;"
                                                    name="InvoiceCountry" [(ngModel)]="InvoiceAddressModel.Country" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"
                                        style="font-size: 13px;align-items: center;padding: 5px 10px 5px 10px;">
                                        <div class="col-sm-6" *ngIf="!isEditingInvoiceAddress">
                                            <label class="label" style="font-weight: 600;display: block;">Company
                                                Name</label>
                                            {{OrderDetail.orderFiche.CompanyName}}
                                        </div>
                                        <div class="col-sm-6" *ngIf="isEditingInvoiceAddress">
                                            <label class="label" style="font-weight: 600;">Company Name</label>
                                            <input type="text" class="form-control" style="font-size: 12px;"
                                                name="CompanyName" [(ngModel)]="InvoiceAddressModel.CompanyName" />
                                        </div>
                                        <div class="col-sm-6" *ngIf="!isEditingInvoiceAddress">
                                            <label class="label" style="font-weight: 600;display: block;">Vat Number
                                            </label>
                                            {{OrderDetail.orderFiche.TaxNr}}
                                        </div>
                                        <div class="col-sm-6" *ngIf="isEditingInvoiceAddress">
                                            <label class="label" style="font-weight: 600;">Vat Number</label>
                                            <input type="text" class="form-control" style="font-size: 12px;"
                                                name="TaxNr" [(ngModel)]="InvoiceAddressModel.TaxNr" />
                                        </div>
                                    </div>
                                    <div class="row" style="font-size: 13px;padding: 5px 10px 5px 10px;">
                                        <div class="col-sm-12" *ngIf="!isEditingInvoiceAddress">
                                            <label class="label" style="font-weight: 600;display: block;">Mobile Phone
                                            </label>
                                            {{OrderDetail.orderFiche.MobilePhone}}
                                        </div>
                                        <div class="col-sm-12" *ngIf="isEditingInvoiceAddress">
                                            <label class="label" style="font-weight: 600;">Mobile Phone</label>
                                            <input type="text" class="form-control" style="font-size: 12px;"
                                                name="InvoiceMobilePhone"
                                                [(ngModel)]="InvoiceAddressModel.MobilePhone" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-4">
                                <div class="order-card" style="padding: 0;">
                                    <div class="order-title-background">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Payment Information
                                        </h4>
                                        <a (click)="EditPaymentMethod()" *ngIf="!isEditingPayment"
                                            style="cursor: pointer;"><i class="fa fa-pencil"
                                                style="font-size: 19px;float: right;color: #ff8084;"></i></a>
                                        <div style="float: right;" *ngIf="isEditingPayment">
                                            <a type="button" (click)="EditPaymentOkay()"><i class="fa fa-check"
                                                    style="font-size: 19px;color: #22af47;"></i></a>
                                            <a type="button" (click)="EditPaymentCancel()"><i class="fa fa-times"
                                                    style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                        </div>
                                    </div>
                                    <div class="row mb-3" style="padding: 10px;align-items: center;">
                                        <div class="col-sm-5">
                                            <label class="label">Payment Type</label>
                                        </div>
                                        <div class="col-sm-7">
                                            <span class="order-info-text" *ngIf="!isEditingPayment">
                                                {{OrderDetail.orderFiche.PaymentNameTR}}</span>
                                            <select class="form-control select" *ngIf="isEditingPayment"
                                                name="PaymetMethodRef" style="font-size: 13px;"
                                                [(ngModel)]="PaymentModel.PaymetMethodRef" required>
                                                <option *ngFor="let item of Payments" [value]="item.Ref">
                                                    {{item.PaymentName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row" style="padding: 10px;align-items: center;">
                                        <div class="col-sm-5">
                                            <label class="label">Commission %</label>
                                        </div>
                                        <div class="col-sm-7">
                                            <span class="order-info-text" *ngIf="!isEditingPayment">
                                                {{OrderDetail.orderFiche.CommissionRate}}</span>
                                            <input *ngIf="isEditingPayment" type="text" mask="percent" suffix=""
                                                class="form-control" style="font-size: 12px;" name="CommissionRate"
                                                [(ngModel)]="PaymentModel.CommissionRate"
                                                aria-describedby="paycommision">
                                        </div>
                                    </div>
                                    <div class="row" style="justify-content: center;margin: 20px;">
                                        <a type="button" (click)="UpdateBinList()" class="btn btn-primary">BIN GÜNCELLE <img
                                            class="img-radius" style="width: 20px;margin-left: 10px;filter: invert(1);"
                                            src="assets/images/update-arrows.svg" alt=""></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="order-card" style="padding: 0;">
                                    <div class="order-title-background">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Delivery Information
                                        </h4>
                                        <a (click)="EditDeliveryInformation()" *ngIf="!isEditingDeliveryInformation"
                                            style="cursor: pointer;"><i class="fa fa-pencil"
                                                style="font-size: 19px;float: right;color: #ff8084;"></i></a>
                                        <div style="float: right;" *ngIf="isEditingDeliveryInformation">
                                            <a type="button" (click)="EditDeliveryInformationOkay()"><i
                                                    class="fa fa-check" style="font-size: 19px;color: #22af47;"></i></a>
                                            <a type="button" (click)="EditDeliveryInformationCancel()"><i
                                                    class="fa fa-times"
                                                    style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                        </div>
                                    </div>
                                    <div style="padding: 10px;">
                                        <div class="row mb-3">
                                            <div class="col-sm-6">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label class="label">Cargo Company</label>
                                                    </div>
                                                    <div class="col-sm-6" *ngIf="!isEditingDeliveryInformation">
                                                        <span class="order-info-text">
                                                            {{OrderDetail.orderFiche.ShipmentDescriptionTR}}</span>
                                                    </div>
                                                    <div class="col-sm-6" *ngIf="isEditingDeliveryInformation">
                                                        <span class="order-info-text">
                                                            <select class="form-control select" name="ShipmentMethodRef"
                                                                style="font-size: 13px;"
                                                                (change)="ShipmentMethodRefOnChange($event)"
                                                                [(ngModel)]="DeliveryInformationModel.ShipmentMethodRef">
                                                                <option *ngFor="let item of Shipments"
                                                                    [value]="item.Ref">{{item.ShipmentText}}
                                                                </option>
                                                            </select></span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-sm-6">
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <label class="label" style="color: red;">Alıcı Ödemeli</label>
                                                    </div>
                                                    <div class="col-sm-8" *ngIf="!isEditingDeliveryInformation">
                                                        <input class="form-check-input" style="filter: invert(1);"
                                                            type="checkbox" value="" name="CargoPair" disabled
                                                            [(ngModel)]="OrderDetail.orderFiche.CargoPair"
                                                            id="flexCheckDefault3">
                                                    </div>
                                                    <div class="col-sm-8" *ngIf="isEditingDeliveryInformation">
                                                        <input class="form-check-input" style="filter: invert(1);"
                                                            type="checkbox" value="" name="CargoPair"
                                                            [(ngModel)]="DeliveryInformationModel.CargoPair"
                                                            id="flexCheckDefault3">
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <label class="label">Price</label>
                                                    </div>
                                                    <div class="col-sm-9" *ngIf="!isEditingDeliveryInformation">
                                                        <span
                                                            class="order-info-text">{{OrderDetail.orderFiche.CargoPrice}}</span>
                                                    </div>
                                                    <div class="col-sm-9" *ngIf="isEditingDeliveryInformation">
                                                        <input type="number" class="form-control"
                                                            style="width: 100%;font-size: 12px;" name="CargoPrice"
                                                            [(ngModel)]="DeliveryInformationModel.CargoPrice" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-3">
                                                <label class="label">Note</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <span *ngIf="!isEditingDeliveryInformation"
                                                    class="order-info-text"><textarea class="form-control"
                                                        style="height: 80px;font-size: 13px;"
                                                        disabled>{{OrderDetail.orderFiche.CargoNote}}</textarea></span>

                                                <textarea *ngIf="isEditingDeliveryInformation" class="form-control"
                                                    name="CargoNote" [(ngModel)]="DeliveryInformationModel.CargoNote"
                                                    style="height: 80px;font-size: 12px;"></textarea>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-3">
                                                <label class="label">Cargo Tracking Number</label>
                                            </div>
                                            <div class="col-sm-9">
                                                <input *ngIf="!isEditingDeliveryInformation" type="text" disabled
                                                    class="form-control" name="CargoTrackingNumber"
                                                    [(ngModel)]="OrderDetail.orderFiche.CargoTrackingNumber"
                                                    style="width: 100%;font-size: 12px;" />
                                                <input *ngIf="isEditingDeliveryInformation" type="text"
                                                    class="form-control" name="CargoTrackingNumber"
                                                    [(ngModel)]="DeliveryInformationModel.CargoTrackingNumber"
                                                    style="width: 100%;font-size: 12px;" />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-6">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label class="label">Customer Cargo Company Name</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input *ngIf="!isEditingDeliveryInformation" type="text"
                                                            class="form-control" name="CargoFirm" disabled
                                                            [(ngModel)]="OrderDetail.orderFiche.CargoFirm"
                                                            style="width: 100%;font-size: 12px;" />
                                                        <input *ngIf="isEditingDeliveryInformation" type="text"
                                                            class="form-control" name="CargoFirm"
                                                            [(ngModel)]="DeliveryInformationModel.CargoFirm"
                                                            style="width: 100%;font-size: 12px;" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-sm-6">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <label class="label">Customer Cargo Company Code</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input *ngIf="!isEditingDeliveryInformation" type="text"
                                                            class="form-control" name="CargoNumber" disabled
                                                            [(ngModel)]="OrderDetail.orderFiche.CargoNumber"
                                                            style="width: 100%;font-size: 12px;" />
                                                        <input *ngIf="isEditingDeliveryInformation" type="text"
                                                            class="form-control" name="CargoNumber"
                                                            [(ngModel)]="DeliveryInformationModel.CargoNumber"
                                                            style="width: 100%;font-size: 12px;" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-3">
                                                        <label class="label">Transport Way</label>
                                                    </div>
                                                    <div class="col-sm-9">
                                                        <select *ngIf="!isEditingDeliveryInformation"
                                                            class="form-control select" name="TransportWayRef"
                                                            style="font-size: 13px;" disabled
                                                            [(ngModel)]="OrderDetail.orderFiche.TransportWayRef">
                                                            <option value="0">Ulaşım Yolu Seçiniz</option>
                                                            <option *ngFor="let item of TransportList"
                                                                [value]="item.TransportWayRef">
                                                                {{item.TransportWayDefinitionTR}}
                                                            </option>
                                                        </select>

                                                        <select *ngIf="isEditingDeliveryInformation"
                                                            (change)="TransportWayRefOnChange($event)"
                                                            class="form-control select" name="TransportWayRef"
                                                            style="font-size: 13px;"
                                                            [(ngModel)]="DeliveryInformationModel.TransportWayRef">
                                                            <option disabled selected value="0">Ulaşım Yolu Seçiniz
                                                            </option>
                                                            <option *ngFor="let item of TransportList"
                                                                [value]="item.TransportWayRef">
                                                                {{item.TransportWayDefinitionTR}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-12">
                                <div class="order-card" style="padding: 0;">
                                    <div class="order-title-background" style="padding: 16px 10px;">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Order Items
                                        </h4>

                                        <button class="btn btn-primary"
                                            style="font-size: 11px;padding: 3px 15px;float: right;align-items: center;display: flex;"
                                            (click)="OpenSearchModal(searchOrderItem)"><i class="fa fa-plus"
                                                style="font-size: 15px;color: white;margin-right: 5px;"></i>Add
                                            New</button>
                                        <button class="btn btn-success" (click)="OrderItemSave()"
                                            [disabled]="isEditingOrderItemLoading"
                                            style="font-size: 11px;padding: 3px 15px;float: right;align-items: center;display: flex;margin-right: 10px;">Save<span
                                                *ngIf="isEditingOrderItemLoading"
                                                class="spinner-border spinner-border-sm ml-1"></span></button>
                                    </div>
                                    <div>
                                        <div class="table-responsive-sm">
                                            <table class="table" style="font-size: 12px;">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Photo</th>
                                                        <th scope="col">Product Detail</th>
                                                        <th scope="col">Unit Price</th>
                                                        <th scope="col">Unit</th>
                                                        <th scope="col">Vat Rate</th>
                                                        <th scope="col">Total Weight</th>
                                                        <th scope="col">Total Point</th>
                                                        <th scope="col">Product Price</th>
                                                        <th scope="col">Vat</th>
                                                        <th scope="col">Total Price</th>
                                                        <th scope="col"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let order of OrderLineModel; index as i">
                                                        <td><img [src]="order.ImageURL[0]"
                                                                style="width: 75px !important;"></td>
                                                        <td>
                                                            <strong> {{order.FirmModelCode}} -
                                                                {{order.ColorCode}}</strong><br>
                                                            <strong> {{order.StockName}}</strong><br>
                                                            <strong>Color:</strong> {{order.ColorName}}<br>
                                                            <strong>Set:</strong> {{order.SetCount}}<br>
                                                            <strong>Set Weight:</strong> {{order.ProductWeight}} GR<br>
                                                        </td>
                                                        <td>{{order.Price}} $</td>
                                                        <td>
                                                            <div class="add-product-form">
                                                                <div class="qty-box1">
                                                                    <div class="input-group"
                                                                        style="flex-wrap: inherit!important;">
                                                                        <i class="fa fa-minus btnGtr1"
                                                                            style="cursor: pointer;"
                                                                            (click)=decrement(order)></i>
                                                                        <input class="touchspin1 text-center"
                                                                            name="Amount{{order.Ref}}"
                                                                            [(ngModel)]="order.Amount"
                                                                            (ngModelChange)="UnitOnChange(order)"
                                                                            type="number">
                                                                        <i class="fa fa-plus btnLess1"
                                                                            style="cursor: pointer;"
                                                                            (click)=increment(order)></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="add-product-form">
                                                                <div class="qty-box1">
                                                                    <div class="input-group"
                                                                        style="flex-wrap: inherit!important;">
                                                                        <i class="fa fa-minus btnGtr1"
                                                                            style="cursor: pointer;"
                                                                            (click)=decrementVatRate(order)></i>
                                                                        <input class="touchspin1 text-center"
                                                                            mask="percent" suffix="%"
                                                                            name="Vat{{order.Ref}}"
                                                                            [(ngModel)]="order.Vat"
                                                                            (ngModelChange)="VatRateOnChange(order)"
                                                                            type="text">
                                                                        <i class="fa fa-plus btnLess1"
                                                                            style="cursor: pointer;"
                                                                            (click)=incrementVatRate(order)></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{order.ProductWeight * order.Amount / order.SetCount}} GR
                                                        </td>
                                                        <td>-</td>
                                                        <td>{{order.Total | currency:' '}} $</td>
                                                        <td>{{order.VatTotal | currency:' '}} $</td>
                                                        <td><span style="color: brown;">{{order.NetTotal | currency:'
                                                                '}} $</span></td>
                                                        <td> <a type="button"
                                                                (click)="DeleteModal(deleteOrderItem,order.Barcode)"><i
                                                                    class="fa fa-times"
                                                                    style="font-size: 19px;color: #ff5370;"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr style="background-color: #f0f2f5;">
                                                        <td> </td>
                                                        <td> </td>
                                                        <td> </td>
                                                        <td style="text-align: center;font-weight: 600;">{{
                                                            TotalOrderItemsUnit }} AD</td>
                                                        <td> </td>
                                                        <td style="text-align: center;font-weight: 600;">{{
                                                            TotalOrderItemsTotalWeight }} GR</td>
                                                        <td> </td>
                                                        <td style="text-align: center;font-weight: 600;">{{
                                                            TotalOrderItemsProductPrice | currency:' ' }} $</td>
                                                        <td style="text-align: center;font-weight: 600;">{{
                                                            TotalOrderItemsVatAmount | currency:' ' }} $</td>
                                                        <td style="text-align: center;font-weight: 600;">{{
                                                            TotalOrderItemsTotalPrice | currency:' ' }} $</td>
                                                        <td> </td>
                                                        <td> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-sm-7">
                                <div class="order-card mb-3" style="padding: 0;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="order-title-background">
                                                <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Order Note
                                                </h4>
                                            </div>
                                            <div style="padding: 10px;">
                                                {{OrderDetail.orderFiche.Description}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-card mb-3" style="padding: 0;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="order-title-background">
                                                <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Order Status
                                                </h4>
                                                <a (click)="EditStatusMethod()" *ngIf="!isEditingStatus"
                                                    style="cursor: pointer;"><i class="fa fa-pencil"
                                                        style="font-size: 19px;float: right;color: #ff8084;"></i></a>
                                                <div style="float: right;" *ngIf="isEditingStatus">
                                                    <a type="button" (click)="EditStatusOkay()"><i class="fa fa-check"
                                                            style="font-size: 19px;color: #22af47;"></i></a>
                                                    <a type="button" (click)="EditStatusCancel()"><i class="fa fa-times"
                                                            style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                                </div>
                                            </div>
                                            <div style="padding: 10px;">
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Status</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span class="order-info-text"
                                                            *ngIf="!isEditingStatus">{{OrderDetail.orderFiche.StatusStr}}</span>
                                                        <select class="form-control select" *ngIf="isEditingStatus"
                                                            name="Status" style="font-size: 13px;"
                                                            [(ngModel)]="StatusModel.Status" required>
                                                            <option *ngFor="let item of Status" [value]="item.Ref">
                                                                {{item.StatusName}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Date - Time</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span class="order-info-text">{{
                                                            OrderDetail.orderFiche.ModifiedDate | date:'dd/MM/yyyy
                                                            HH:mm'}}</span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Open on Web</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-check" *ngIf="!isEditingStatus">
                                                            <input class="form-check-input" style="filter: invert(1);"
                                                                type="checkbox" value="" disabled name="OpenOnWeb"
                                                                [(ngModel)]="OrderDetail.orderFiche.OpenOnWeb"
                                                                id="flexCheckDefault2">
                                                        </div>
                                                        <div class="form-check" *ngIf="isEditingStatus">
                                                            <input class="form-check-input" style="filter: invert(1);"
                                                                type="checkbox" value="" name="OpenOnWeb"
                                                                [(ngModel)]="StatusModel.OpenOnWeb"
                                                                id="flexCheckDefault2">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Information Mail</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-check" *ngIf="!isEditingStatus">
                                                            <input class="form-check-input" style="filter: invert(1);"
                                                                type="checkbox" value="" disabled name="MailNotify"
                                                                [(ngModel)]="OrderDetail.orderFiche.MailNotify"
                                                                id="flexCheckDefault">

                                                        </div>
                                                        <div class="form-check" *ngIf="isEditingStatus">
                                                            <input class="form-check-input" style="filter: invert(1);"
                                                                type="checkbox" value="" name="MailNotify"
                                                                [(ngModel)]="StatusModel.MailNotify"
                                                                id="flexCheckDefault">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Note</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <span class="order-info-text" *ngIf="!isEditingStatus">
                                                            <textarea class="form-control"
                                                                [(ngModel)]="OrderDetail.orderFiche.StatusNote"
                                                                style="height: 80px;font-size: 13px;padding: 5px;"
                                                                disabled></textarea>
                                                        </span>
                                                        <textarea class="form-control" *ngIf="isEditingStatus"
                                                            style="height: 80px;font-size: 13px;padding: 5px;"
                                                            name="StatusNote"
                                                            [(ngModel)]="StatusModel.StatusNote"></textarea>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-12">
                                                        <a (click)="ViewHistoryModal(viewhistory)"
                                                            style="font-size: 13px;cursor: pointer;">View
                                                            History({{ViewHistory.length}})</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-card" style="padding: 0;">
                                    <div class="order-title-background" style="padding: 16px 10px;">
                                        <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Additional Services
                                        </h4>
                                        <button class="btn btn-primary"
                                            (click)="OpenAdditionalServicesModal(additionalservicesmodal)"
                                            style="font-size: 11px;padding: 3px 15px;float: right;align-items: center;display: flex;"><i
                                                class="fa fa-plus"
                                                style="font-size: 15px;color: white;margin-right: 5px;"></i>Add
                                            New</button>
                                    </div>
                                    <div>
                                        <div class="table-responsive-sm">
                                            <table class="table" style="font-size: 12px;text-align: center;">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Resim</th>
                                                        <th scope="col">Ürün Adı</th>
                                                        <th scope="col">Adet</th>
                                                        <th scope="col">Birim Fiyat</th>
                                                        <th scope="col">KDV Oranı</th>
                                                        <th scope="col">KDV Tutarı</th>
                                                        <th scope="col">Toplam Tutar</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of AdditionalServicesbyOrder">
                                                        <td><img src="https://scm.vipstendo.com/AdminService/{{item.PhotoURL}}"
                                                                style="width: 25px;" /></td>
                                                        <td>{{item.NameTR}}</td>
                                                        <td>{{item.Amount}}</td>
                                                        <td>{{item.UnitPrice}}$</td>
                                                        <td>{{item.VatRate}}%</td>
                                                        <td>{{item.VatAmount}}$</td>
                                                        <td>{{item.NetTotal}}$</td>
                                                        <td>
                                                            <a type="button"
                                                                (click)="OpenEditAdditionalService(editadditionalservicesmodal,item)"><i
                                                                    class="fa fa-pencil"
                                                                    style="font-size: 19px;color: #22af47;"></i></a>
                                                            <a type="button"
                                                                (click)="OpenDeleteAdditionalService(deleteAdditionalServicemodal,item.Ref)"><i
                                                                    class="fa fa-times"
                                                                    style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-sm-5">
                                <div class="order-card mb-3" style="padding: 0;">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="order-title-background">
                                                <h4 style="margin-bottom: 0;display: -webkit-inline-box;">Order Summary
                                                </h4>
                                            </div>
                                            <div style="padding: 10px;">
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Total Product Price</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: rgb(98, 187, 216);font-size: 13px;">{{
                                                                OrderSummaryProductPrice | currency:' ' }}
                                                            </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">VAT</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: rgb(98, 187, 216);font-size: 13px;">{{
                                                                OrderSummaryVat | currency:' '}}
                                                            </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Cargo(<span class="label"
                                                                *ngIf="!OrderDetail.orderFiche.CargoPair"
                                                                style="color: brown;">Gönderici Ödemeli</span><span
                                                                class="label" *ngIf="OrderDetail.orderFiche.CargoPair"
                                                                style="color: brown;">Alıcı Ödemeli</span>)</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text">
                                                            <span style="color: rgb(98, 187, 216);font-size: 13px;"
                                                                *ngIf="OrderDetail.orderFiche.CargoPair">{{OrderDetail.orderFiche.CargoPrice}}
                                                            </span>
                                                            <span style="color: rgb(98, 187, 216);font-size: 13px;"
                                                                *ngIf="!OrderDetail.orderFiche.CargoPair">0
                                                            </span>
                                                            <span style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3" *ngFor="let item of AdditionalServicesbyOrder;">
                                                    <div class="col-sm-6">
                                                        <label class="label">{{item.NameTR}}</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: rgb(98, 187, 216);font-size: 13px;">{{
                                                                item.NetTotal | currency:' '}}
                                                            </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <hr style="width: 100%;" />
                                                <div class="row mb-3 mt-2">
                                                    <div class="col-sm-6">
                                                        <label class="label" style="color: brown;">Promotion
                                                            Code</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;display: flex;">
                                                        <input type="text" disabled value="frontend"
                                                            class="form-control mr-3" style="font-size: 11px;" />
                                                        <input type="text" disabled value="hesaplanan değer"
                                                            class="form-control" style="font-size: 11px;" />
                                                    </div>
                                                </div>

                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label" style="color: brown;">Point
                                                            Redemption</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: brown;font-size: 13px;">- </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label" style="color: brown;">Vat Refund</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <input type="number" class="form-control" name="VatRefund"
                                                            [(ngModel)]="OrderDetail.orderFiche.VatRefund"
                                                            (ngModelChange)="VatRefundOnChange()"
                                                            style="font-size: 13px;text-align: right;" />
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label" style="color: brown;">Special
                                                            Discount</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <input type="number" class="form-control" name="SpecialDiscount"
                                                            [(ngModel)]="OrderDetail.orderFiche.SpecialDiscount"
                                                            (ngModelChange)="SpecialDiscountOnChange()" ngmodel
                                                            style="font-size: 13px;text-align: right;" />
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Sub Total</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: black;font-size: 13px;font-weight: 600;">{{OrderSummarySubTotal
                                                                | currency:' '}}
                                                            </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Payment Commission(<span
                                                                style="color: brown;">%{{OrderDetail.orderFiche.CommissionRate}}</span>)</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: black;font-size: 13px;">{{OrderSummaryCommission
                                                                | currency:' '}} </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Total Price</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="color: rgb(98, 187, 216);font-size: 13px;">{{OrderSummaryTotalPrice
                                                                | currency:' '}}
                                                            </span><span
                                                                style="color: gray;font-size: 11px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div class="row mb-3">
                                                    <div class="col-sm-6">
                                                        <label class="label">Exchange Rate</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <input type="number" class="form-control" value="1" disabled
                                                            style="font-size: 13px;color: brown;" />
                                                    </div>
                                                </div>
                                                <div class="row mb-3" style="align-items: center;">
                                                    <div class="col-sm-6">
                                                        <label class="label"
                                                            style="font-weight: 600;color: #007bff;font-size: 18px;">GRAND
                                                            TOTAL</label>
                                                    </div>
                                                    <div class="col-sm-6" style="text-align: right;">
                                                        <span class="order-info-text"><span
                                                                style="font-weight: 600;color: #007bff;font-size: 18px;">{{OrderSummaryTotalPrice
                                                                | currency:' '}}
                                                            </span><span
                                                                style="font-weight: 600;color: #007bff;font-size: 18px;">$</span></span>
                                                    </div>
                                                </div>
                                                <div style="text-align: right;">
                                                    <button class="btn btn-success"
                                                        style="font-size: 12px;padding: 5px 10px;"
                                                        (click)="UpdateOrderSummary()"
                                                        [disabled]="isUpdatingOrderSummary">Güncelle<span
                                                            *ngIf="isUpdatingOrderSummary"
                                                            class="spinner-border spinner-border-sm ml-1"></span></button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Payment Detail">
                    <ng-template ngbTabContent>


                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<ng-template #deleteOrderItem let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="CloseDeleteModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            Ürünü Kaldırmak İstediğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                [disabled]="isDeleting" (click)="DeleteOrderItem()">Sil<span *ngIf="isDeleting"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="CloseDeleteModalClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>

<ng-template #deleteAdditionalServicemodal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="CloseDeleteAdditionalServiceModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            Hizmeti Silmek İstediğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                [disabled]="DeletingAdditionalServiceItemLoading" (click)="DeleteAdditionalService()">Sil<span
                    *ngIf="DeletingAdditionalServiceItemLoading"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="CloseDeleteAdditionalServiceModalClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>

<ng-template #additionalservicesmodal let-modal>
    <div class="modal-content">
        <form name="additionalservices" novalidate #additionalservices="ngForm"
            (ngSubmit)="additionalservices.form.valid && AddAdditionalServices()" enctype="multipart/form-data">
            <div class="modal-header">
                <button type="button" class="close" (click)="CloseAdditionalServicesModalClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Ürün</label>
                    </div>
                    <div class="col-md-6">
                        <select class="form-control" name="Name" #Name="ngModel"
                            style="font-size: 13px;letter-spacing: 0;" [(ngModel)]="AdditionalServicesModel.Name"
                            (change)="AdditionalServiceNameOnChange()"
                            [ngClass]="{ 'is-invalid': additionalservices.submitted && Name.invalid }" required>
                            <option *ngFor="let item of AdditionalServices" [value]="item.Ref">
                                {{item.NameTR}}</option>
                        </select>
                        <div *ngIf="additionalservices.submitted && Name.invalid" class="invalid-feedback">
                            <div>Ürün Seçiniz!</div>
                        </div>
                    </div>
                </div>
                <div class="form-row mb-3" *ngIf="selectedAdditionalService.PhotoURL">
                    <div class="col-md-6">
                        <label class="label">resim</label>
                    </div>
                    <div class="col-md-6" style="text-align: center;">
                        <img src="https://scm.vipstendo.com/AdminService/{{selectedAdditionalService.PhotoURL}}"
                            style="width: 50px;" />
                    </div>
                </div>
                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Adet</label>
                    </div>
                    <div class="col-md-6">
                        <input type="number" name="Unit" #Unit="ngModel" class="input form-control"
                            style="letter-spacing: 0;font-size: 13px;" [(ngModel)]="AdditionalServicesModel.Unit"
                            (ngModelChange)="AdditionalServicesUnitOnChange()"
                            [ngClass]="{ 'is-invalid': additionalservices.submitted && Unit.invalid }" required />
                        <div *ngIf="additionalservices.submitted && Unit.invalid" class="invalid-feedback">
                            <div>Adet Giriniz!</div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Birim Fiyat</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" name="Price" #Price="ngModel" class="input form-control"
                            style="letter-spacing: 0;font-size: 13px;" mask="separator.2" thousandSeparator="."
                            [(ngModel)]="AdditionalServicesModel.Price"
                            (ngModelChange)="AdditionalServicesPriceOnChange()"
                            [ngClass]="{ 'is-invalid': additionalservices.submitted && Price.invalid }" required />
                        <div *ngIf="additionalservices.submitted && Price.invalid" class="invalid-feedback">
                            <div>Birim Fiyat Giriniz!</div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">KDV Oranı</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" name="Vat" #Vat="ngModel" class="input form-control" mask="percent"
                            suffix="%" style="letter-spacing: 0;font-size: 13px;"
                            [(ngModel)]="AdditionalServicesModel.Vat" (ngModelChange)="AdditionalServicesVatOnChange()"
                            [ngClass]="{ 'is-invalid': additionalservices.submitted && Vat.invalid }" required />
                        <div *ngIf="additionalservices.submitted && Vat.invalid" class="invalid-feedback">
                            <div>KDV Oranı Giriniz!</div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">KDV Tutarı</label>
                    </div>
                    <div class="col-md-6">
                        <span class="spaninput form-control">{{AdditionalServicesModel.VatTotal}}<span
                                *ngIf="AdditionalServicesModel.VatTotal">%</span></span>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Toplam Tutar</label>
                    </div>
                    <div class="col-md-6">
                        <span class="spaninput form-control">{{AdditionalServicesModel.NetTotal | currency:' '}}<span
                                *ngIf="AdditionalServicesModel.NetTotal">$</span></span>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="text-align: center;display: block;">
                <button type="submit" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                    [disabled]="isAddingAdditionalServices">Ekle<span *ngIf="isAddingAdditionalServices"
                        class="spinner-border spinner-border-sm ml-1"></span></button>
                <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                    (click)="CloseAdditionalServicesModalClose()">Vazgeç</button>
            </div>
        </form>
    </div>

</ng-template>

<ng-template #editadditionalservicesmodal let-modal>
    <div class="modal-content">
        <form name="editadditionalservicesmodal" novalidate #editadditionalservicesmodal="ngForm"
            (ngSubmit)="editadditionalservicesmodal.form.valid && EditAdditionalServices()"
            enctype="multipart/form-data">
            <div class="modal-header">
                <button type="button" class="close" (click)="CloseEdititionalServicesModalClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Ürün</label>
                    </div>
                    <div class="col-md-6">
                        <select class="form-control" name="Name" #Name="ngModel"
                            style="font-size: 13px;letter-spacing: 0;" [(ngModel)]="EditAdditionalServicesModel.Name"
                            (change)="EditAdditionalServiceNameOnChange()"
                            [ngClass]="{ 'is-invalid': editadditionalservicesmodal.submitted && Name.invalid }"
                            required>
                            <option *ngFor="let item of AdditionalServices" [value]="item.Ref">
                                {{item.NameTR}}</option>
                        </select>
                        <div *ngIf="editadditionalservicesmodal.submitted && Name.invalid" class="invalid-feedback">
                            <div>Ürün Seçiniz!</div>
                        </div>
                    </div>
                </div>
                <div class="form-row mb-3" *ngIf="EditAdditionalServicesModel.PhotoURL">
                    <div class="col-md-6">
                        <label class="label">resim</label>
                    </div>
                    <div class="col-md-6" style="text-align: center;">
                        <img src="https://scm.vipstendo.com/AdminService/{{EditAdditionalServicesModel.PhotoURL}}"
                            style="width: 50px;" />
                    </div>
                </div>
                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Adet</label>
                    </div>
                    <div class="col-md-6">
                        <input type="number" name="Unit" #Unit="ngModel" class="input form-control"
                            style="letter-spacing: 0;font-size: 13px;" [(ngModel)]="EditAdditionalServicesModel.Unit"
                            (ngModelChange)="EditAdditionalServicesUnitOnChange()"
                            [ngClass]="{ 'is-invalid': editadditionalservicesmodal.submitted && Unit.invalid }"
                            required />
                        <div *ngIf="editadditionalservicesmodal.submitted && Unit.invalid" class="invalid-feedback">
                            <div>Adet Giriniz!</div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Birim Fiyat</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" name="Price" #Price="ngModel" class="input form-control"
                            style="letter-spacing: 0;font-size: 13px;" mask="separator.2" thousandSeparator="."
                            [(ngModel)]="EditAdditionalServicesModel.Price"
                            (ngModelChange)="EditAdditionalServicesPriceOnChange()"
                            [ngClass]="{ 'is-invalid': editadditionalservicesmodal.submitted && Price.invalid }"
                            required />
                        <div *ngIf="editadditionalservicesmodal.submitted && Price.invalid" class="invalid-feedback">
                            <div>Birim Fiyat Giriniz!</div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">KDV Oranı</label>
                    </div>
                    <div class="col-md-6">
                        <input type="text" name="Vat" #Vat="ngModel" class="input form-control" mask="percent"
                            suffix="%" style="letter-spacing: 0;font-size: 13px;"
                            [(ngModel)]="EditAdditionalServicesModel.Vat"
                            (ngModelChange)="EditAdditionalServicesVatOnChange()"
                            [ngClass]="{ 'is-invalid': editadditionalservicesmodal.submitted && Vat.invalid }"
                            required />
                        <div *ngIf="editadditionalservicesmodal.submitted && Vat.invalid" class="invalid-feedback">
                            <div>KDV Oranı Giriniz!</div>
                        </div>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">KDV Tutarı</label>
                    </div>
                    <div class="col-md-6">
                        <span class="spaninput form-control">{{EditAdditionalServicesModel.VatTotal}}<span
                                *ngIf="EditAdditionalServicesModel.VatTotal">%</span></span>
                    </div>
                </div>

                <div class="form-row mb-3">
                    <div class="col-md-6">
                        <label class="label">Toplam Tutar</label>
                    </div>
                    <div class="col-md-6">
                        <span class="spaninput form-control">{{EditAdditionalServicesModel.NetTotal | currency:'
                            '}}<span *ngIf="EditAdditionalServicesModel.NetTotal">$</span></span>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="text-align: center;display: block;">
                <button type="submit" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                    [disabled]="isAddingAdditionalServices">Düzenle<span *ngIf="isAddingAdditionalServices"
                        class="spinner-border spinner-border-sm ml-1"></span></button>
                <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                    (click)="CloseAdditionalServicesModalClose()">Vazgeç</button>
            </div>
        </form>
    </div>

</ng-template>

<ng-template #searchOrderItem let-modal>
    <div class="modal-content" style="height: 600px;">
        <div class="modal-header">
            <h3 style="margin: 0;color: black;font-weight: 500;letter-spacing: 0.1px;">Ürün Arama</h3>
            <button type="button" class="close" (click)="CloseSearchModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            <div class="row">
                <div class="col-lg-12 mb-3" style="display: flex;white-space: nowrap;align-items: center;">
                    <label
                        style="align-items: center;margin-bottom: 0!important;margin-right: 5px;font-size: 13px;color: black;font-weight: 600;">Ürün
                        Kodu : </label>
                    <input class="form-control" style="width: auto;margin-right: 5px;font-size: 13px;" maxlength="25"
                        name="searchInput" [(ngModel)]="searchModel.itemCode" type="text">
                    <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;font-size: 13px;
                    padding: 5px 25px;" [disabled]="isSearching" (click)="SearchOrderItem()">Ara<span
                            *ngIf="isSearching" class="spinner-border spinner-border-sm ml-1"></span></button>
                </div>
            </div>


            <div class="table-responsive-sm searchtable" style="max-height: 430px;overflow: auto;">
                <table class="table" style="font-size: 12px;">
                    <thead>
                        <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Model Code</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Color</th>
                            <th scope="col">Stock Amount</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of searchItems; index as i">
                            <td><img [src]="order.ImageURL" style="width: 75px !important;"></td>
                            <td>{{ order.FirmModelCode }} - {{ order.VariantCode }}</td>
                            <td>{{order.StockName}}</td>
                            <td>{{order.ColorName}}</td>
                            <td>{{order.Balance}}</td>
                            <td>{{order.Price}} $</td>

                            <td>
                                <button type="button" class="btn closebtn btn-lg" [disabled]="isAddingOrderItem" style="background: #2F80ED;font-size: 13px;
                    padding: 5px 25px;" (click)="AddOrderItem(order)">Ürünü Ekle<span *ngIf="isAddingOrderItem"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

    </div>
</ng-template>

<ng-template #viewhistory let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="ViewHistoryModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            <div class="table-responsive-sm searchtable" style="max-height: 430px;overflow: auto;">
                <table class="table" style="font-size: 12px;">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Auto Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let history of ViewHistory; index as i">
                            <td>{{history.ChangeDate | date:'dd/MM/yyyy HH:mm'}}</td>
                            <td>{{ history.AutoNote }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="ViewHistoryModalClose()">Tamam</button>
        </div>
    </div>
</ng-template>
