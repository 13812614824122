<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="isLoadingContact" [@fadeInOut]>
    <div class="card">
        <div class="card-header">
            <h5>İletişim Sayfasını Düzenle</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <div class="row mb-4">
                            <div class="col-sm-2">
                                <label>İçerik Dili</label>
                            </div>
                            <div class="col-sm-10">
                                <select class="form-control select" style="font-size: 13px;" name="LangCode"
                                    #LangCode="ngModel" [(ngModel)]="ContactModel.LangCode" required>
                                    <option selected disabled [value]="undefined">Seçiniz</option>
                                    <option value="TR">Türkçe</option>
                                    <option value="EN">English</option>
                                    <option value="RU">Russian</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-2">
                                <label>Görsel</label>
                            </div>
                            <div class="col-md-10">
                                <button type="button" class="btn btn-primary"
                                    *ngIf="!imageSrc && !ContactModel.ImageLocation" (click)="openImage()"
                                    [disabled]="isUploadImage"
                                    style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Yükle<span
                                        *ngIf="isUploadImage"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                                <button type="button" class="btn btn-primary"
                                    *ngIf="imageSrc || ContactModel.ImageLocation" (click)="openImage()"
                                    [disabled]="isUploadImage"
                                    style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Düzenle<span
                                        *ngIf="isUploadImage"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                                <input type="file" class="addimage" id="addimage"
                                    (change)="addImageChange($event.target.files)" style="display:none;" />
                                <img *ngIf="ContactModel.ImageLocation" alt=""
                                    style="margin-right:10px;border-radius: 5px;margin-left: 20px;width: 15%;"
                                    id="ImageBox"
                                    src="https://scm.vipstendo.com/AdminService/{{ContactModel.ImageLocation}}">
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-2">
                                <label>3 Kolon Text</label>
                            </div>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <ck-editor name="colon1" [(ngModel)]="ContactModel.Colon1" skin="moono-lisa"
                                            language="en" [fullPage]="true"></ck-editor>
                                    </div>
                                    <div class="col-sm-4">
                                        <ck-editor name="colon2" [(ngModel)]="ContactModel.Colon2" skin="moono-lisa"
                                            language="en" [fullPage]="true"></ck-editor>
                                    </div>
                                    <div class="col-sm-4">
                                        <ck-editor name="colon3" [(ngModel)]="ContactModel.Colon3" skin="moono-lisa"
                                            language="en" [fullPage]="true"></ck-editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-2">
                                <label>Sosyal Medya</label>
                            </div>
                            <div class="col-md-10">
                                <a (click)="addNewSocialMediaItem()" *ngIf="!isEditingSocialMedia"
                                    style="cursor: pointer;">
                                    <i class="fa fa-plus" style="font-size: 24px;color: #22af47;"></i>
                                </a>
                                <div class="table-responsive-sm">
                                    <table class="table" style="text-align: center;font-size: 12px;">
                                        <thead>
                                            <tr>
                                                <th scope="col">Durum</th>
                                                <th scope="col">Sıra</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Link</th>
                                                <th scope="col">Görsel</th>
                                                <th scope="col">Düzenle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of SocialMedias">
                                                <td scope="row">
                                                    <ng-container *ngIf="EditSocialMediaRef != item.Ref">
                                                        <div *ngIf="!item.Status">
                                                            <i class="fa fa-circle font-warning f-12"></i>
                                                        </div>
                                                        <div *ngIf="item.Status">
                                                            <i class="fa fa-circle font-success f-12"></i>
                                                        </div>
                                                    </ng-container>

                                                    <div class="checkbox checkbox-primary"
                                                        *ngIf="EditSocialMediaRef == item.Ref && isEditingSocialMedia">
                                                        <input type="checkbox" id="Status" name="Status"
                                                            style="font-size: 11px;" #Status="ngModel"
                                                            [(ngModel)]="EditSocailMediaItem.Status" required>
                                                        <label for="Status"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf=" EditSocialMediaRef != item.Ref">
                                                        {{ item.OrderNo }}
                                                    </ng-container>
                                                    <input type="number" style="font-size: 11px;"
                                                        *ngIf="EditSocialMediaRef == item.Ref && isEditingSocialMedia"
                                                        class="form-control input" name="OrderNo"
                                                        [(ngModel)]="EditSocailMediaItem.OrderNo" required />
                                                </td>
                                                <td>
                                                    <ng-container *ngIf=" EditSocialMediaRef != item.Ref">
                                                        {{item.SocialMedia }}
                                                    </ng-container>


                                                    <input type="text" style="font-size: 11px;"
                                                        *ngIf="EditSocialMediaRef == item.Ref && isEditingSocialMedia"
                                                        class="form-control input" name="SocialMedia"
                                                        [(ngModel)]="EditSocailMediaItem.SocialMedia" required />
                                                </td>
                                                <td>
                                                    <ng-container *ngIf=" EditSocialMediaRef != item.Ref">
                                                        {{item.URL}}
                                                    </ng-container>
                                                    <input type="text" style="font-size: 11px;"
                                                        *ngIf="EditSocialMediaRef == item.Ref && isEditingSocialMedia"
                                                        class="form-control input" name="URL"
                                                        [(ngModel)]="EditSocailMediaItem.URL" required />

                                                </td>
                                                <td>
                                                    <ng-container *ngIf=" EditSocialMediaRef != item.Ref">
                                                        <img alt="" *ngIf="item.SocialImageURL" style="width: 50px;"
                                                            src="https://scm.vipstendo.com/AdminService/{{ item.SocialImageURL }}">
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="EditSocialMediaRef == item.Ref && isEditingSocialMedia">
                                                        <button type="button" class="btn btn-primary"
                                                            style="font-size: 11px;" (click)="addSocialImage()"
                                                            [disabled]="isUploadSocialImage"
                                                            style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Ekle
                                                            <span *ngIf="isUploadSocialImage"
                                                                class="spinner-border spinner-border-sm ml-1"></span></button>
                                                        <input type="file" class="addimage"
                                                            id="addSocialimage"
                                                            (change)="addSocialImageChange($event.target.files)"
                                                            style="display:none;" />
                                                        <img alt="" style="width: 50px;margin-left: 10px;"
                                                            src="https://scm.vipstendo.com/AdminService/{{ EditSocailMediaItem.SocialImageURL }}">
                                                    </ng-container>

                                                </td>
                                                <td>
                                                    <a (click)="EditSocialMedia(item.Ref)" *ngIf="!isEditingSocialMedia"
                                                        style="cursor: pointer;"><i class="fa fa-pencil"
                                                            style="font-size: 19px;color: #ff8084;"></i></a>
                                                    <a type="button" *ngIf="!isEditingSocialMedia" (click)="DeleteSocialMediaItem(deleteSocialMedia,item)" style="margin-left: 10px;">
                                                        <i class="fa fa-trash" style="font-size: 19px;color: #ff8084;"></i>
                                                    </a>
                                                    <div style="float: right;" *ngIf="isEditingSocialMedia && EditSocialMediaRef == item.Ref">
                                                        <a type="button" (click)="SaveSocialMedia()"><i
                                                                class="fa fa-check"
                                                                style="font-size: 19px;color: #22af47;"></i></a>
                                                        <a type="button" (click)="CancelSocialMedia(item.Ref)"><i
                                                                class="fa fa-times"
                                                                style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-2">
                                <label>Google Map Iframe</label>
                            </div>
                            <div class="col-md-10">
                                <textarea class="form-control" #LangCode="ngModel"
                                    [(ngModel)]="ContactModel.GoogleMapIFrame"
                                    style="height: 200px;font-size: 13px;"></textarea>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-2">
                                <label>Deaty</label>
                            </div>
                            <div class="col-md-10">
                                <ck-editor name="Description" [(ngModel)]="ContactModel.Description" skin="moono-lisa"
                                    language="en" [fullPage]="true"></ck-editor>
                            </div>
                        </div>
                        <div class="row" style="float: right;">
                            <div class="col-lg-12">
                                <button type="button" class="btn btn-primary" (click)="EditContactPage()"
                                    [disabled]="isEditingContactPage || isEditingSocialMedia"
                                    style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Düzenle<span
                                        *ngIf="isEditingContactPage"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                            </div>

                        </div>
                    </ng-template>



                </ngb-tab>

            </ngb-tabset>


        </div>
    </div>
</div>
<!-- Container-fluid Ends-->

<span *ngIf="!isLoadingContact" class="spinner-border spinner-border-sm"
style="left: 50%;position: relative;margin-top: 250px;"></span>


<ng-template #deleteSocialMedia let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="DeleteSocialMediaClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            Sosyal Medyayı Silmek İstediğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" [attr.disabled]="IsDeletedSocialMediaItem ? '' : null" class="btn modalbtn btn-lg"
                style="background: #2F80ED;width:47%" (click)="DeleteSocialMedia()">Sil<span *ngIf="IsDeletedSocialMediaItem"
                    class="spinner-border spinner-border-sm ml-1" style="margin-bottom: 2px;"></span></button>
            <button type="button" class="btn modalbtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="DeleteSocialMediaClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>
