import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {
  isAdding = false;
  CreateRoleModel: any = {};
  RoleNameModel: any = {};
  RoleList;
  myDate;

  constructor(private router: Router,private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    if (!this.TokenService.getUser().IsAdmin) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  

  AddRole() {
    this.isAdding = true;
    const body = { Role: { RoleName: this.RoleNameModel.RoleName },Auth: this.CreateRoleModel };
    this.http.post<any>(environment.url + 'Roles/AddRole', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Rol Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/role-list"]);
      }
    },
      error => {
        this.toastr.error('Rol Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
