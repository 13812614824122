import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-shipment',
  templateUrl: './edit-shipment.component.html',
  styleUrls: ['./edit-shipment.component.scss']
})
export class EditShipmentComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditShipmentModel: any = {};
  MenuList;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['CargoTypeDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetShipmentDetail();
    });
  }

  GetShipmentDetail() {
    this.http.get<any>(environment.url + 'Shipment/GetShipmentMethods').subscribe(data => {
      data.forEach(element => {
        if (data.length) {
          if (element.Ref == this.id) {
            this.EditShipmentModel.ShipmentCode = element.ShipmentCode;
            this.EditShipmentModel.ShipmentDescriptionTR = element.ShipmentDescriptionTR;
            this.EditShipmentModel.ShipmentDescriptionEN = element.ShipmentDescriptionEN;
            this.EditShipmentModel.ShipmentDescriptionRU = element.ShipmentDescriptionRU;

            this.EditShipmentModel.EstimatedDescriptionTR = element.EstimatedDescriptionTR;
            this.EditShipmentModel.EstimatedDescriptionEN = element.EstimatedDescriptionEN;
            this.EditShipmentModel.EstimatedDescriptionRU = element.EstimatedDescriptionRU;

            this.EditShipmentModel.CargoCodeParameter = element.CargoCodeParameter;
            this.EditShipmentModel.TrackingURLTR = element.TrackingURLTR;
            this.EditShipmentModel.TrackingURLEN = element.TrackingURLEN;
            this.EditShipmentModel.TrackingURLRU = element.TrackingURLRU;
            this.EditShipmentModel.Price = element.Price;

            this.EditShipmentModel.Ref = element.Ref;
          }
        }

      });
    });
  }



  EditShipment() {
    this.isEditing = true;
    const body = { Ref: this.EditShipmentModel.Ref, ShipmentCode: this.EditShipmentModel.ShipmentCode, ShipmentDescriptionTR: this.EditShipmentModel.ShipmentDescriptionTR,  ShipmentDescriptionEN: this.EditShipmentModel.ShipmentDescriptionEN, ShipmentDescriptionRU: this.EditShipmentModel.ShipmentDescriptionRU,
      EstimatedDescriptionTR : this.EditShipmentModel.EstimatedDescriptionTR, EstimatedDescriptionEN  : this.EditShipmentModel.EstimatedDescriptionEN, EstimatedDescriptionRU  : this.EditShipmentModel.EstimatedDescriptionRU, EstimatedBegDay  :  0,
       EstimatedEndDay  : 0, Price  : this.EditShipmentModel.Price , Type  : 0,PaymentGroupRef :0,CargoCodeParameter : this.EditShipmentModel.CargoCodeParameter ,
       TrackingURLTR : this.EditShipmentModel.TrackingURLTR ,TrackingURLEN : this.EditShipmentModel.TrackingURLEN ,TrackingURLRU : this.EditShipmentModel.TrackingURLRU ,
       
    };
    this.http.post<any>(environment.url + 'Shipment/UpdateShipment', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Kargo Tanımı Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.isEditing = false;
        this.toastr.error('Kargo Tanımı Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
      }
    },
      error => {
        this.toastr.error('Kargo Tanımı Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
