<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Edit User</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="UserForm" class="needs-validation" novalidate #UserForm="ngForm"
                            (ngSubmit)="UserForm.form.valid && EditUser()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Kullanıcı Adı</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="UserName"
                                    #UserName="ngModel" [(ngModel)]="EditUserModel.UserName" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': UserForm.submitted && UserName.invalid }" required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Şifre</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="Password"
                                    #Password="ngModel" [(ngModel)]="EditUserModel.Password" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': UserForm.submitted && Password.invalid }">
                            </div>
                          
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Rol</label>
                                <select class="form-control select col-xl-8 col-md-7" style="font-size: 13px;"
                                    [(ngModel)]="EditUserModel.RoleRef" name="RoleRef" required>
                                    <option *ngFor="let item of Roles" [value]="item.Ref">{{item.RoleName}}</option>
                                </select>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Admin</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input id="IsAdmin" type="checkbox" name="IsAdmin" #IsAdmin="ngModel"
                                        [(ngModel)]="EditUserModel.IsAdmin">
                                    <label for="IsAdmin"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Aktiflik</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input id="IsActive" type="checkbox" name="IsActive" #IsActive="ngModel"
                                        [(ngModel)]="EditUserModel.IsActive">
                                    <label for="IsActive"></label>
                                </div>
                            </div>
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isEditing"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Düzenle<span
                                        *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->