import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-footer-menu',
  templateUrl: './create-footer-menu.component.html',
  styleUrls: ['./create-footer-menu.component.scss']
})
export class CreateFooterMenuComponent implements OnInit {

  CreateMenuModel: any = {};
  loading = false;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['FooterMenu']) {
      router.navigateByUrl('/');
    }
   }

  ngOnInit() {
  }


  CreateFooterMenu(){
    this.loading = true;
    
    const body = {  MenuName: this.CreateMenuModel.MenuName,  LanguageCode: this.CreateMenuModel.LanguageCode ,IsActive : this.CreateMenuModel.IsActive ,IsFooterMenu: true };
    this.http.post<any>(environment.url + 'Menu/CreateMegaMenu', body).subscribe(data => {
      if (data) {
        this.loading = false;
        this.toastr.success("Footer Menü Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/menus/footer-menu"]);
      }
    },
      error => {
        this.toastr.error('Footer Menü Oluşturulamadı!', 'Hata!', {
          timeOut: 2000,
        });
        this.loading = false;
      });

  }

}
