import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { DefinitionRoutingModule } from './definition-routing.module';
import { CreateShipmentComponent } from './shipment-definitions/create-shipment/create-shipment.component';
import { EditShipmentComponent } from './shipment-definitions/edit-shipment/edit-shipment.component';
import { ShipmentListComponent } from './shipment-definitions/shipment-list/shipment-list.component';

import { CreatePaymentComponent } from './payment-definitions/create-payment/create-payment.component';
import { EditPaymentComponent } from './payment-definitions/edit-payment/edit-payment.component';
import { PaymentListComponent } from './payment-definitions/payment-list/payment-list.component';

import { CreateOrderStatusComponent } from './order-status-definitions/create-order-status/create-order-status.component';
import { EditOrderStatusComponent } from './order-status-definitions/edit-order-status/edit-order-status.component';
import { OrderStatusListComponent } from './order-status-definitions/order-status-list/order-status-list.component';

import { CreateTransportComponent } from './transport-definitions/create-transport/create-transport.component';
import { EditTransportComponent } from './transport-definitions/edit-transport/edit-transport.component';
import { TransportListComponent } from './transport-definitions/transport-list/transport-list.component';

import { CreateCountryComponent } from './country-definitions/create-country/create-country.component';
import { EditCountryComponent } from './country-definitions/edit-country/edit-country.component';
import { CountryListComponent } from './country-definitions/country-list/country-list.component';

import { CreateAdditionalServicesComponent } from './additional-services-definitions/create-additional-services/create-additional-services.component';
import { EditAdditionalServicesComponent } from './additional-services-definitions/edit-additional-services/edit-additional-services.component';
import { AdditionalServicesListComponent } from './additional-services-definitions/additional-services-list/additional-services-list.component';

import { CreateRoleComponent } from './role-definitions/create-role/create-role.component';
import { EditRoleComponent } from './role-definitions/edit-role/edit-role.component';
import { RoleListComponent } from './role-definitions/role-list/role-list.component';

import { CreateBannerTypeComponent } from './banner-type-definitions/create-banner-type/create-banner-type.component';
import { EditBannerTypeComponent } from './banner-type-definitions/edit-banner-type/edit-banner-type.component';
import { BannerTypeListComponent } from './banner-type-definitions/banner-type-list/banner-type-list.component';

@NgModule({
  declarations: [CreateShipmentComponent, EditShipmentComponent, ShipmentListComponent,CreatePaymentComponent,EditPaymentComponent,PaymentListComponent, CreateBannerTypeComponent, EditBannerTypeComponent, BannerTypeListComponent,
    CreateOrderStatusComponent,EditOrderStatusComponent,OrderStatusListComponent,CreateTransportComponent,EditTransportComponent,TransportListComponent,
    CreateAdditionalServicesComponent,EditAdditionalServicesComponent,AdditionalServicesListComponent,CreateRoleComponent,EditRoleComponent,RoleListComponent
    ,CreateCountryComponent,EditCountryComponent,CountryListComponent,],
  imports: [
    CommonModule,
    DefinitionRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule
  ],
  providers: [DatePipe]
})
export class DefinitionModule { }
