<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h5>Pop Up Ekle</h5>
    </div>
    <div class="card-body tab2-card">
      <ngb-tabset class="tab-coupon">
        <ngb-tab title="General">
          <ng-template ngbTabContent>
            <div class="row mb-4">
              <div class="col-sm-2">
                <label>İçerik Dili</label>
              </div>
              <div class="col-sm-10">
                <select class="form-control select" style="font-size: 13px;" name="LangCode" #LangCode="ngModel"
                  [(ngModel)]="AddPopUpModel.LangCode" required>
                  <option selected disabled [value]="undefined">Seçiniz</option>
                  <option value="TR">Türkçe</option>
                  <option value="EN">English</option>
                  <option value="RU">Russian</option>
                </select>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-sm-2">
                <label>Görsel</label>
              </div>
              <div class="col-md-10">
                <button type="button" class="btn btn-primary" *ngIf="!imageSrc && !AddPopUpModel.PopupImage"
                  (click)="openImage()" [disabled]="isUploadImage"
                  style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Yükle<span *ngIf="isUploadImage"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
                <button type="button" class="btn btn-primary" *ngIf="imageSrc || AddPopUpModel.PopupImage"
                  (click)="openImage()" [disabled]="isUploadImage"
                  style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Resmi Değiştir<span *ngIf="isUploadImage"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
                <input type="file" class="addimage" id="addimage" (change)="addImageChange($event.target.files)"
                  style="display:none;" />
                <ng-container *ngIf="AddPopUpModel.PopupImage">
                  <video *ngIf="isVideo" class="video" playsinline loop muted
                    style="width: 250px;margin-top: 10px;border-radius: 5px;" autoplay oncanplay="this.play()"
                    onloadedmetadata="this.muted = true">
                    <source src="https://scm.vipstendo.com/AdminService/{{AddPopUpModel.PopupImage}}" type="video/mp4" />
                  </video>
                  <img *ngIf="!isVideo" alt="" style="border-radius: 5px;margin-top: 10px;width: 15%;" id="ImageBox"
                    src="https://scm.vipstendo.com/AdminService/{{AddPopUpModel.PopupImage}}">
                </ng-container>


              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-2">
                <label>Yazı</label>
              </div>
              <div class="col-md-10">
                <ck-editor name="InnerText" [(ngModel)]="AddPopUpModel.InnerText" skin="moono-lisa" language="en"
                  [fullPage]="true"></ck-editor>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-2">
                <label>Button URL</label>
              </div>
              <div class="col-md-10">
                <input type="text" style="font-size: 11px;" class="form-control input" name="URL"
                  [(ngModel)]="AddPopUpModel.URL" required />

              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-2">
                <label>Popup Yenileme Saati</label>
              </div>
              <div class="col-md-10">
                <input type="number" style="font-size: 11px;" class="form-control input" name="PopupRefresh"
                  [(ngModel)]="AddPopUpModel.PopupRefresh" required />
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-sm-2">
                <label>Logo (935x178)</label>
              </div>
              <div class="col-md-10">
                <button type="button" class="btn btn-primary" *ngIf="!imageSrc2 && !AddPopUpModel.PopupLogo"
                  (click)="openImage('addimage2')" [disabled]="isUploadImage"
                  style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Yükle<span *ngIf="isUploadImage2"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
                <button type="button" class="btn btn-primary" *ngIf="imageSrc2 || AddPopUpModel.PopupLogo"
                  (click)="openImage('addimage2')" [disabled]="isUploadImage2"
                  style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Logoyu Değiştir<span
                    *ngIf="isUploadImage2" class="spinner-border spinner-border-sm ml-1"></span></button>
                <input type="file" class="addimage" id="addimage2" (change)="addImageChange2($event.target.files)"
                  style="display:none;" />
                <ng-container class="m-2" *ngIf="AddPopUpModel.PopupLogo">
                  <video *ngIf="isVideo2" class="video" playsinline loop muted
                    style="width: 250px;margin-top: 10px;border-radius: 5px;" autoplay oncanplay="this.play()"
                    onloadedmetadata="this.muted = true">
                    <source src="https://scm.vipstendo.com/AdminService/{{AddPopUpModel.PopupLogo}}" type="video/mp4" />
                  </video>
                  <img *ngIf="!isVideo2" alt="" style="border-radius: 5px;margin-top: 10px;width: 15%;" id="ImageBox2"
                    src="https://scm.vipstendo.com/AdminService/{{AddPopUpModel.PopupLogo}}">
                </ng-container>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-md-2">
                <label>Aktiflik</label>
              </div>
              <div class="col-md-10">
                <div class="checkbox checkbox-primary">
                  <input type="checkbox" id="IsActive" name="IsActive" style="font-size: 11px;" #Status="ngModel"
                    [(ngModel)]="AddPopUpModel.IsActive" required>
                  <label for="IsActive"></label>
                </div>
              </div>
            </div>

            <div class="row" style="float: right;">
              <div class="col-lg-12">
                <button type="button" class="btn btn-primary" (click)="AddPopup()" [disabled]="isAddingPopUp"
                  style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Ekle<span *ngIf="isAddingPopUp"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
