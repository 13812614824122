import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-additional-services',
  templateUrl: './edit-additional-services.component.html',
  styleUrls: ['./edit-additional-services.component.scss']
})
export class EditAdditionalServicesComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditAdditionalServicesModel: any = {};
  MenuList;
  isUploadImage;
  imageSrc;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['ServiceDefinitions']) {
      router.navigateByUrl('/');
    }

  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetAdditionalServicesDetail();
    });
  }

  GetAdditionalServicesDetail() {
    this.http.get<any>(environment.url + 'AdditionalService/GetAdditionalServices').subscribe(data => {
      if (data.length) {
        data.forEach(element => {
          if (element.Ref == this.id) {
            this.EditAdditionalServicesModel.Ref = element.Ref;
            this.EditAdditionalServicesModel.NameTR = element.NameTR;
            this.EditAdditionalServicesModel.NameEN = element.NameEN;
            this.EditAdditionalServicesModel.NameRU = element.NameRU;
            this.EditAdditionalServicesModel.PhotoURL = element.PhotoURL;
          }
        });
      }

    });
  }



  EditAdditionalServices() {
    this.isEditing = true;
    const body = {
      Ref: this.EditAdditionalServicesModel.Ref, NameTR: this.EditAdditionalServicesModel.NameTR,
      NameEN: this.EditAdditionalServicesModel.NameEN, NameRU: this.EditAdditionalServicesModel.NameRU,
      PhotoURL: this.EditAdditionalServicesModel.PhotoURL
    };
    this.http.post<any>(environment.url + 'AdditionalService/Update', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Hizmet Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Hizmet Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Hizmet Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }

  openImage() { // Click Image File Button
    let element: HTMLElement = document.getElementById('addimage') as HTMLElement;
    element.click();
  }
  addImageChange(files: FileList) { // Show Image File on change 
    this.isUploadImage = true;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http.post<any>(environment.url + 'AdditionalService/UploadImage', formData).subscribe(data => {
      this.isUploadImage = false;
      if (data) {
        if (files) {
          const reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = () => {
            this.imageSrc = reader.result as string;
            this.EditAdditionalServicesModel.PhotoURL = data;
          };
        }
        this.toastr.success("Resim Yüklendi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("İcon Yüklenemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });

  }
}
