<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Edit User</h5>
                </div>
                <div class="card-body tab2-card" [@fadeInOut] *ngIf="UserDetail">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Üye Detayı">
                            <ng-template ngbTabContent>
                                <div class="row" style="margin-left: 0px;">
                                    <div class="col-sm-6"
                                        style="padding: 20px;border: 1px solid lightgray;border-radius: 10px;">
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Adı</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="text" *ngIf="!isEditingUserDetail">{{ UserDetail.Account.Firstname }}</span>
                                                <input type="text" *ngIf="isEditingUserDetail"
                                                    class="form-control input" name="Firstname"
                                                    [(ngModel)]="UserDetailModel.Firstname" />
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Soyadı</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="text" *ngIf="!isEditingUserDetail">{{
                                                    UserDetail.Account.Surname }}</span>
                                                <input type="text" *ngIf="isEditingUserDetail"
                                                    class="form-control input" name="Surname"
                                                    [(ngModel)]="UserDetailModel.Surname" />
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">E-Mail</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="text" *ngIf="!isEditingUserDetail">{{
                                                    UserDetail.Account.Email }}</span>
                                                <input type="text" *ngIf="isEditingUserDetail"
                                                    class="form-control input" name="Email"
                                                    [(ngModel)]="UserDetailModel.Email" />
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Mobil Telefon</label>
                                            </div>
                                            <div class="col-sm-8">

                                                <span class="text" *ngIf="!isEditingUserDetail">{{
                                                    UserDetail.Account.Mobile }}</span>
                                                <input type="text" *ngIf="isEditingUserDetail"
                                                    class="form-control input" name="Mobile"
                                                    [(ngModel)]="UserDetailModel.Mobile" />
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3 mt-5">
                                            <div class="col-sm-4">
                                                <label class="label">E-Bülten Aboneliği</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <div class="custom-control custom-checkbox"
                                                    *ngIf="!isEditingUserDetail">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="UserControl2" disabled
                                                        [(ngModel)]="UserDetail.Account.EBulletinSubscription">
                                                    <label class="custom-control-label" for="UserControl2"></label>
                                                </div>
                                                <div class="custom-control custom-checkbox" *ngIf="isEditingUserDetail">
                                                    <input type="checkbox" class="custom-control-input"
                                                        id="UserControl2" [(ngModel)]="UserDetailModel.EBulletinSubscription">
                                                    <label class="custom-control-label" for="UserControl2"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">E-Bülten Mail</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="text"
                                                    *ngIf="!isEditingUserDetail && UserDetail.Account.EBulletinSubscription">{{
                                                    UserDetail.Account.EBulletinMail }}</span>
                                                <span class="text"
                                                    *ngIf="!isEditingUserDetail && !UserDetail.Account.EBulletinSubscription"> -
                                                </span>
                                                <input type="text"
                                                    *ngIf="isEditingUserDetail && !UserDetailModel.EBulletinSubscription"
                                                    class="form-control input" disabled name="EBulletinMail"
                                                    [(ngModel)]="UserDetailModel.EBulletinMail" />
                                                <input type="text"
                                                    *ngIf="isEditingUserDetail && UserDetailModel.EBulletinSubscription"
                                                    class="form-control input" name="EBulletinMail"
                                                    [(ngModel)]="UserDetailModel.EBulletinMail" />
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Cari Grubu</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="text" *ngIf="!isEditingUserDetail">{{
                                                    UserDetail.Account.CustomerGroup }}</span>
                                                <input type="text" *ngIf="isEditingUserDetail"
                                                    class="form-control input" name="CustomerGroup"
                                                    [(ngModel)]="UserDetailModel.CustomerGroup" />
                                            </div>
                                        </div>
                                        <div class="row user-row mb-3">
                                            <div class="col-sm-4">
                                                <label class="label">Müşteri Temsilcisi</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <span class="text" *ngIf="!isEditingUserDetail">{{
                                                    UserDetail.Account.CustomerRepresentative }}</span>
                                                <input type="text" *ngIf="isEditingUserDetail"
                                                    class="form-control input" name="CustomerRepresentative"
                                                    [(ngModel)]="UserDetailModel.CustomerRepresentative" />
                                            </div>
                                        </div>
                                      
                                        <div class="row mt-4" *ngIf="!isEditingUserDetail">
                                            <div class="col-sm-12" style="text-align: right;">
                                                <button class="btn btn-secondary btn-edit"
                                                    (click)="EditUserDetail()">Düzenle<i class="fa fa-pencil"
                                                        style="font-size: 15px;color: white;margin-left: 5px;"></i></button>
                                            </div>
                                        </div>
                                        <div class="row mt-4" *ngIf="isEditingUserDetail">
                                            <div class="col-sm-12" style="text-align: right;">
                                                <button class="btn btn-secondary btn-edit" style="margin-right: 10px;"
                                                    (click)="CancelUserDetail()">Vazgeç</button>
                                                <button class="btn btn-primary btn-edit" [disabled]="EditUserDetailLoading"
                                                    (click)="SaveUserDetail()">Kaydet <span *ngIf="EditUserDetailLoading"
                                                    class="spinner-border spinner-border-sm ml-1"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div style="padding: 20px;border: 1px solid lightgray;border-radius: 10px;">
                                            <h4 style="color: black;font-weight: 500;display: contents;">Şifre İşlemleri
                                            </h4>
                                            <a (click)="EditUserDetailPassword()" *ngIf="!isEditingUserDetailPassword"
                                                style="cursor: pointer;"><i class="fa fa-pencil"
                                                    style="font-size: 19px;float: right;color: #ff8084;"></i></a>
                                            <div style="float: right;" *ngIf="isEditingUserDetailPassword">
                                                <a type="button" (click)="SaveUserDetailPassword()"><i
                                                        class="fa fa-check"
                                                        style="font-size: 19px;color: #22af47;"></i></a>
                                                <a type="button" (click)="CancelUserDetailPassword()"><i
                                                        class="fa fa-times"
                                                        style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                            </div>
                                            <hr />
                                            <div style="display: flex;align-items: center;margin-top: 20px;">
                                                <label class="label" style="margin-right: 15px;">Şifre</label>
                                                <span class="text" *ngIf="!isEditingUserDetailPassword">{{
                                                    UserDetail.Pass }}</span>
                                                <input type="text" *ngIf="isEditingUserDetailPassword"
                                                    class="form-control input" name="WebPassword"
                                                    [(ngModel)]="UserDetailPasswordModel.WebPassword" />

                                            </div>
                                            <div style="margin-top: 20px;text-align: right;">
                                                <button class="btn btn-secondary btn-edit" (click)="SendRecoverMail()"  [disabled]="isEditingUserDetailPassword || isSendingPasswordMail"
                                                    style="margin-right: 10px;text-transform: none;">Yenileme Linki
                                                    Yolla</button>
                                            </div>
                                        </div>

                                        <div
                                            style="margin-top: 20px;padding: 20px;border: 1px solid lightgray;border-radius: 10px;">
                                            <h4 style="color: black;font-weight: 500;display: contents;">Hareketler</h4>
                                            <hr />
                                            <div class="row mb-2 mt-2">
                                                <div class="col-sm-6">
                                                    <label class="label">Üyelik
                                                        Tarihi</label>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="text">{{UserDetail.CustomerInfo.CreateDate | date:'dd/MM/yyyy HH:mm'}}</span>
                                                  
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-6">
                                                    <label class="label">Üye IP</label>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="text">{{ UserDetail.CustomerInfo.IPAddress }}</span>
                                                </div>
                                            </div>

                                            <div class="table-responsive mt-3 active-tasks"
                                                style="max-height: 190px!important;overflow: auto;" >
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Login Tarihi</th>
                                                            <th scope="col">Login IP</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of UserDetail.UserLogingHistory">
                                                            <td>{{item.CreateDate | date:'dd/MM/yyyy HH:mm'}}</td>
                                                            <td>{{item.IPNo}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div class="row">
                                    <div class="col-sm-6">
                                        <h4 style="color: #b13636;font-weight: 600;text-align: center;font-size: 16px;">Sevkiyat Adresleri</h4>
                                        <div class="mb-3"
                                            style="text-transform: none;background-color: #fff;padding: 15px;border: 1px solid grey;border-radius: 5px;"
                                            *ngFor="let address of ShipmentAddress">
                                            <div class="row">
                                                <div class="col-sm-10">
                                                    <h5 style="letter-spacing: 0;font-weight: 500;">{{ address.FirstName}} {{ address.LastName}}</h5>
                                                    <div class="content">
                                                        <table class="tables">
                                                            <tr>
                                                                <td>{{address.CompanyName}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.TaxNr}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.Address}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.City}} / {{address.Country}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.MobilePhone}}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2"
                                                    style="text-align: right;align-items: center;display: inline-grid;">
                                                    <div class="shopping-option">
                                                        <a (click)="EditUserDetailShipmentAddress(address)"
                                                            style="cursor: pointer;"><span>Düzenle</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div [@slideIn] *ngIf="isEditingUserDetailShipmentAddress">
                                            <h4 style="color: black;font-weight: 500;display: contents;">Adres Bilgileri
                                            </h4>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Şirket
                                                                Adı</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="CompanyName"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.CompanyName" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">VAT
                                                                Number</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input" name="TaxNr"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.TaxNr" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Adı</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="FirstName"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.FirstName" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Soyadı</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="LastName"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.LastName" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label"
                                                                style="margin-right: 15px;">Adres</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <textarea class="form-control input" style="height: 150px;"
                                                                name="Address"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.Address"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Telefon</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="MobilePhone"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.MobilePhone" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label"
                                                                style="margin-right: 15px;">Ülke</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input" name="Country"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.Country" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label"
                                                                style="margin-right: 15px;">Şehir</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input" name="City"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.City" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Posta
                                                                Kodu</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="PostCode"
                                                                [(ngModel)]="UserDetailShipmentAddressModel.PostCode" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-primary" [disabled]="isEditingShipmentAddressLoading" (click)="SaveUserDetailShipmentAddress()" 
                                                style="float: right;">Kaydet <span *ngIf="isEditingShipmentAddressLoading"
                                                class="spinner-border spinner-border-sm ml-1"></span></button>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <h4 style="color: #b13636;font-weight: 600;text-align: center;font-size: 16px;">Fatura Adresleri</h4>
                                        <div class="mb-3"
                                            style="text-transform: none;background-color: #fff;padding: 15px;border: 1px solid grey;border-radius: 5px;"
                                            *ngFor="let address of InvoiceAddress">
                                            <div class="row">
                                                <div class="col-sm-10">
                                                    <h5 style="font-weight: 500;">{{ address.FirstName}} {{
                                                        address.LastName}}</h5>
                                                    <div class="content">
                                                        <table class="tables">
                                                            <tr>
                                                                <td>{{address.CompanyName}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.TaxNr}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.Address}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.City}} / {{address.Country}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{address.MobilePhone}}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="col-sm-2"
                                                    style="text-align: right;align-items: center;display: inline-grid;">
                                                    <div class="shopping-option">
                                                        <a (click)="EditUserDetailInvoiceAddress(address)"
                                                            style="cursor: pointer;"><span>Düzenle</span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div [@slideIn] *ngIf="isEditingUserDetailInvoiceAddress">
                                            <h4 style="color: black;font-weight: 500;display: contents;">Adres Bilgileri
                                            </h4>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Şirket
                                                                Adı</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="CompanyName"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.CompanyName" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">VAT
                                                                Number</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input" name="TaxNr"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.TaxNr" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Adı</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="FirstName"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.FirstName" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Soyadı</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="LastName"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.LastName" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label"
                                                                style="margin-right: 15px;">Adres</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <textarea class="form-control input" style="height: 150px;"
                                                                name="Address"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.Address"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Telefon</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="MobilePhone"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.MobilePhone" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label"
                                                                style="margin-right: 15px;">Ülke</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input" name="Country"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.Country" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label"
                                                                style="margin-right: 15px;">Şehir</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input" name="City"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.City" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-sm-4">
                                                            <label class="label" style="margin-right: 15px;">Posta
                                                                Kodu</label>
                                                        </div>
                                                        <div class="col-sm-8">
                                                            <input type="text" class="form-control input"
                                                                name="PostCode"
                                                                [(ngModel)]="UserDetailInvoiceAddressModel.PostCode" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" (click)="SaveUserDetailInvoiceAddress()" [disabled]="isEditingInvoiceAddressLoading" class="btn btn-primary" style="float: right;">Kaydet <span *ngIf="isEditingInvoiceAddressLoading"
                                                class="spinner-border spinner-border-sm ml-1"></span></button>
                                        </div>
                                    </div>
                                </div>


                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Sipariş Listesi">
                            <ng-template ngbTabContent>
                                <div class="table-responsive-sm">
                                    <table class="table" style="text-align: center;">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Müşteri</th>
                                                <th scope="col">Ülke</th>
                                                <th scope="col">Toplam Adet</th>
                                                <th scope="col">Toplam Tutar</th>
                                                <th scope="col">Ödeme Yöntemi</th>
                                                <th scope="col">Sipariş Tarihi</th>
                                                <th scope="col">Durum</th>
                                                <th scope="col">Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let order of UserDetail.CustomerOrders; index as i"
                                                style=" font-size: 12px;">
                                                <td scope="row">{{ order.FicheNo }}</td>
                                                <td scope="row">{{ order.CustName }}</td>
                                                <td scope="row">{{ order.Country }}</td>
                                                <td scope="row">
                                                    <span *ngIf="order.ItemCount">{{ order.ItemCount }}</span>
                                                    <span *ngIf="!order.ItemCount">Ek Hizmet</span>
                                                </td>
                                                <td scope="row">$ {{ order.NetTotal |currency:' '}}</td>
                                                <td scope="row"> {{order.PaymentNameTR}} </td>
                                                <td scope="row">{{ order.ShippedDate | date:'dd.MM.yyyy'}} <br>{{
                                                    order.Time_ | date:'HH:mm' }}</td>
                                                <td scope="row">
                                                    <span class="badge badge-warning" *ngIf="order.Status == 1"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                    <span class="badge badge-info" *ngIf="order.Status == 2"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                    <span class="badge badge-primary"
                                                        style="background-color: #007bff!important;"
                                                        *ngIf="order.Status == 3"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                    <span class="badge badge-primary"
                                                        style="background-color: #6c757d!important;"
                                                        *ngIf="order.Status == 4"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                    <span class="badge badge-success" *ngIf="order.Status == 5"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                    <span class="badge badge-danger" *ngIf="order.Status == 8"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                    <span class="badge badge-danger" *ngIf="order.Status == 9"
                                                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                                                        order.StatusStr }}</span>
                                                </td>
                                                <td><a type="button" (click)="gotoOrderDetail(order.Ref)"><i
                                                            class="fa fa-edit" style="font-size: 17px;"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Üretim Talepleri">
                            <ng-template ngbTabContent>


                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Tamamlanmamış Siparişler">
                            <ng-template ngbTabContent>
                                <div class="table-responsive-sm">
                                    <table class="table" style="text-align: center;">
                                        <thead>
                                            <tr>
                                                <th scope="col">Ürün Görseli</th>
                                                <th scope="col">Model Kodu</th>
                                                <th scope="col">Ürün Adı</th>
                                                <th scope="col">Birim Fiyatı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let product of CartList; index as i" style=" font-size: 12px;">
                                                <td>
                                                    <img style="width: 75px;"
                                                        [src]="product.ProductVariant.Images[0].ImageLocation">
                                                </td>
                                                <td>
                                                        {{ product.FirmModelCode }} - {{
                                                        product.ProductVariant.VariantCode }}
                                                </td>
                                                <td>
                                                    {{ product.Name | titlecase }}
                                                </td>
                                                <td style="font-size: 20px;">
                                                    {{ product.CardStoreSalesPrice | currency:' '}}$
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Favoriler">
                            <ng-template ngbTabContent>
                                <div class="table-responsive-sm">
                                    <table class="table" style="text-align: center;">
                                        <thead>
                                            <tr>
                                                <th scope="col">Ürün Görseli</th>
                                                <th scope="col">Model Kodu</th>
                                                <th scope="col">Ürün Adı</th>
                                                <th scope="col">Birim Fiyatı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let product of UserDetail.WishList; index as i"
                                                style=" font-size: 12px;">
                                                <td>
                                                    <img style="width: 75px;"
                                                        [src]="product.ProductVariant.Images[0].ImageLocation">
                                                </td>
                                                <td>
                                                    <a>
                                                        {{ product.FirmModelCode }} - {{
                                                        product.ProductVariant.VariantCode }}
                                                    </a>
                                                </td>
                                                <td>
                                                    {{ product.Name | titlecase }}
                                                </td>
                                                <td style="font-size: 20px;">
                                                    {{ product.CardStoreSalesPrice | currency:' '}}$
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab title="Tıkladığı Ürünler">
                            <ng-template ngbTabContent>
                                <div class="table-responsive-sm">
                                    <table class="table" style="text-align: center;">
                                        <thead>
                                            <tr>
                                                <th scope="col">Ürün Görseli</th>
                                                <th scope="col">Model Kodu</th>
                                                <th scope="col">Ürün Adı</th>
                                                <th scope="col">Birim Fiyatı</th>
                                                <th scope="col">Son Tıklanma Tarihi</th>
                                                <th scope="col">Toplam Tıklanma Sayısı</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let product of UserDetail.ViewHistory; index as i" style=" font-size: 12px;">
                                                <td>
                                                    <img style="width: 75px;"
                                                        [src]="product.StockVariants[0].Images[0].ImageLocation">
                                                </td>
                                                <td>
                                                        {{ product.FirmModelCode }} - {{
                                                        product.StockVariants[0].VariantCode }}
                                                </td>
                                                <td>
                                                    {{ product.Name | titlecase }}
                                                </td>
                                                <td>
                                                    {{ product.CardStoreSalesPrice | currency:' '}}$
                                                </td>
                                                <td>
                                                    {{ product.LastViewDate | date:'dd/MM/yyyy HH:mm' }}
                                                </td>
                                                <td style="font-size: 20px;">
                                                    {{ product.ViewCount }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                </div>
                <div class="card-body tab2-card" [@fadeInOut] *ngIf="!UserDetail"
                    style="padding: 250px 0;text-align: center;">
                    <span class="spinner-border spinner-border-sm ml-1"></span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->