<div class="container-fluid">
    <div class="page-header">
        <div class="row">
            <div class="col-lg-6">
                <div class="page-header-left">
                    <a (click)="backClicked()" *ngIf="visible" style="cursor: pointer;margin-right: 15px;"><img alt=""
                            src="assets/images/left-arrow.svg"
                            style="filter: invert(56%) sepia(66%) saturate(1567%) hue-rotate(315deg) brightness(119%) contrast(100%);width: 25px;" /></a>
                    <h3>{{title}}
                        <small>VipStendo Admin Paneli</small>
                    </h3>
                </div>
            </div>
            <div class="col-lg-6">
                <ol class="breadcrumb pull-right">
                    <li class="breadcrumb-item"><a [routerLink]="'/home/dashboard'">
                            <app-feather-icons [icon]="'home'"></app-feather-icons>
                        </a></li>
                    <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}
                    </li>
                    <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">
                        {{breadcrumbs?.childBreadcrumb}}
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
