<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Country</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="CountryForm" class="needs-validation" novalidate #CountryForm="ngForm"
                            (ngSubmit)="CountryForm.form.valid && AddCountry()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Ülke</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="Name" #Name="ngModel"
                                    [(ngModel)]="CreateCountryModel.Name "
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && Name.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Ülke Kodu</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="Code" #Code="ngModel"
                                    [(ngModel)]="CreateCountryModel.Code"
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && Code.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">3 Haneli Ülke Kodu</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="Code3" #Code3="ngModel"
                                    [(ngModel)]="CreateCountryModel.Code3 "
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && Code3.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Telefon Kodu</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="PhoneCode" #PhoneCode="ngModel"
                                    [(ngModel)]="CreateCountryModel.PhoneCode "
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && PhoneCode.invalid  }"
                                    required>
                            </div>
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Oluştur<span
                                        *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->