export class menuDB {
    static data = [
        {
            name: "MEN",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
            "created on": "2018-04-18T00:00:00",
            edit: "<a><i class=\"fa fa-edit\" style=\"font-size: 17px;\"></i></a>"
        },
        {
            name: "NEW ARRIVALS",
            status: "<i class=\"fa fa-circle font-warning f-12\"></i>",
            "created on": "2018-04-18T00:00:00",
        },
        {
            name: "SALE",
            status: "<i class=\"fa fa-circle font-success f-12\"></i>",
            "created on": "2018-04-18T00:00:00",
        },
        {
            name: "TIMELESS STYLE",
            status: "<i class=\"fa fa-circle font-danger f-12\"></i>",
            "created on": "2018-04-18T00:00:00",
        }
    ]
}
