import { Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';

export const content: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      title: "Anasayfa",
      breadcrumb: "Anasayfa"
    }
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'orders',
    loadChildren: () => import('../../components/orders/order.module').then(m => m.OrderModule),
    data: {
      breadcrumb: "Orders"
    }
  },
  {
    path: 'instagrams',
    loadChildren: () => import('../../components/instagramShop/instagram.module').then(m => m.InstagramModule),
    data: {
      breadcrumb: "Instagram"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'cms',
    loadChildren: () => import('../../components/cms/cms.module').then(m => m.CMSModule),
    data: {
      breadcrumb: "CMS"
    }
  },
  {
    path: 'definitions',
    loadChildren: () => import('../../components/definitions/definition.module').then(m => m.DefinitionModule),
    data: {
      breadcrumb: "Definition"
    }
  },
  {
    path: 'banner-slider',
    loadChildren: () => import('../../components/banner-slider/banner-slider.module').then(m => m.BannerSliderModule),
    data: {
      breadcrumb: "Banner/Slider"
    }
  },
  {
    path: 'media',
    loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  },
  {
    path: 'menus',
    loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
    data: {
      breadcrumb: "Menü"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'vendors',
    loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
    data: {
      breadcrumb: "Vendors"
    }
  },
  {
    path: 'localization',
    loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
    data: {
      breadcrumb: "Localization"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },
  {
    path: 'setting',
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: {
      breadcrumb: "Settings"
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
    data: {
      breadcrumb: "Invoice"
    }
  }
];
