import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-admin-user',
  templateUrl: './edit-admin-user.component.html',
  styleUrls: ['./edit-admin-user.component.scss']
})
export class EditAdminUserComponent implements OnInit {
  id;
  isEditing = false;
  EditUserModel: any = {};
  Roles: any = [];

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService, private modalService: NgbModal, private TokenService: TokenService) {
    if (!this.TokenService.getUser().IsAdmin) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetUserDetail();
      this.GetRoles();
    });
  }
  GetRoles() {
    this.http.get<any>(environment.url + 'Roles/GetRoles').subscribe(data => {
      this.Roles = data;
    });
  }
  GetUserDetail() {
    this.http.get<any>(environment.url + 'User/GetUsers').subscribe(data => {
      if (data.length) {
        data.forEach(element => {
          if (element.UserId == this.id) {
            this.EditUserModel.UserId = element.UserId;
            this.EditUserModel.UserName = element.UserName;
            this.EditUserModel.Password = element.Password;
            this.EditUserModel.IsAdmin = element.IsAdmin;
            this.EditUserModel.RoleRef = element.RoleRef;
            this.EditUserModel.IsDeleted = element.IsDeleted;
            this.EditUserModel.IsActive = element.IsActive;
          }
        });
      }

    });
  }



  EditUser() {
    this.isEditing = true;
    const body = {
      UserId: this.EditUserModel.UserId, UserName: this.EditUserModel.UserName, Password: this.EditUserModel.Password, IsAdmin: this.EditUserModel.IsAdmin,
      RoleRef: this.EditUserModel.RoleRef, IsActive: this.EditUserModel.IsActive, IsDeleted: this.EditUserModel.IsDeleted
    };
    this.http.post<any>(environment.url + 'User/UpdateUser', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Kullanıcı Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Kullanıcı Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Kullanıcı Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
