<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">

        <div class="card-body tab2-card">
          <ngb-tabset class="tab-coupon">
            <ngb-tab title="Tüm Ürünler">
              <ng-template ngbTabContent>
                <div class="table-responsive-sm">
                  <table class="table" style="text-align: center;" *ngIf="isLoading">
                    <thead>
                      <tr>
                        <th scope="col">Photo</th>
                        <th scope="col">Kod</th>
                        <th scope="col">Ürün Adı</th>
                        <th scope="col">Marka</th>
                        <th scope="col">Kategori</th>
                        <th scope="col">Stok Adeti</th>
                        <th scope="col">İlk Fiyat</th>
                        <th scope="col">Web Satış Fiyatı</th>
                        <th scope="col">Veri Kaynağı</th>
                        <th scope="col">Status</th>
                        <th scope="col">ID</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of ProductList;let i = index">
                        <td><img src="{{product.StockVariants[0].Images[0].ImageLocation}}" style="width: 50px;" /></td>
                        <td>{{product.Code}}</td>
                        <td>{{product.Name}}</td>
                        <td>{{product.MarkName}}</td>
                        <td>{{product.CategoryName}}</td>
                        <td> - </td>
                        <td>{{product.CardStoreSalesPrice}}</td>
                        <td>{{product.CardStoreSalesPrice}}</td>
                        <td>Entegrasyon</td>
                        <td>
                          <div *ngIf="!product.IsActive">
                            <i class="fa fa-circle font-warning f-12"></i>
                          </div>
                          <div *ngIf="product.IsActive">
                            <i class="fa fa-circle font-success f-12"></i>
                          </div>
                        </td>
                        <td>{{product.StockRef}}</td>
                        <td><a type="button" (click)="gotoProductEdit(product.StockRef)"><i class="fa fa-edit"
                              style="font-size: 17px;"></i></a></td>
                        <td>
                          <a type="button" (click)="DeleteProductModal(deleteproduct,product.StockRef)"
                            *ngIf="DeletingItem != product.StockRef"><i class="fa fa-trash"
                              style="font-size: 17px;"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                    style="left: 50%;position: relative;"></span>
                </div>
              </ng-template>
            </ngb-tab>
            <ngb-tab title="Yayındaki Ürünler">
              <ng-template ngbTabContent>

              </ng-template>
            </ngb-tab>
            <ngb-tab title="Üretim Talebi Ürünleri">
              <ng-template ngbTabContent>

              </ng-template>
            </ngb-tab>
            <ngb-tab title="Yayına Kapalı Ürünler">
              <ng-template ngbTabContent>

              </ng-template>
            </ngb-tab>
            <ngb-tab title="Kampanya Ürünleri">
              <ng-template ngbTabContent>


              </ng-template>
            </ngb-tab>
            <ngb-tab title="Ürün Pozisyonları">
              <ng-template ngbTabContent>

              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteproduct let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="CloseDeleteModalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      Ürünü Silmek İstediğinizden Emin misiniz ?
    </div>
    <div class="modal-footer" style="text-align: center;display: block;">
      <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeleting"
        (click)="DeleteProduct()">Sil<span *ngIf="isDeleting"
          class="spinner-border spinner-border-sm ml-1"></span></button>
      <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
        (click)="CloseDeleteModalClose()">Vazgeç</button>
    </div>
  </div>
</ng-template>