<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header" style="display: flex;">
          <h5 style="align-items: center;display: flex;">Sipariş Listesi</h5>
          <input type="text" name="daterangeInput" class="form-control daterangeInput" autocomplete="off"
            id="daterangeInput" #Date="ngModel" [(ngModel)]="FilterModel.Date" (focusout)="DateOnFocusOut()"
            placeholder="Tarih Aralığı Seçiniz.." style="font-size: 12px;width: 250px;margin-left: 10px;"
            daterangepicker [options]="options" (selected)="selectedDate($event)" />
        </div>
        <div class="card-body">
          <div class="custom-datatable">

            <div class="clearfix"></div>

            <div class="table-responsive-sm">
              <table class="table" style="text-align: center;" *ngIf="isLoading">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Müşteri</th>
                    <th scope="col">Ülke</th>
                    <th scope="col">Toplam Adet</th>
                    <th scope="col">Toplam Tutar</th>
                    <th scope="col">Ödeme Yöntemi</th>
                    <th scope="col">Sipariş Tarihi</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let order of OrderList; index as i" style=" font-size: 12px;">
                    <td scope="row">{{ order.FicheNo }}</td>
                    <td scope="row">{{ order.CustName }}</td>
                    <td scope="row">{{ order.Country }}</td>
                    <td scope="row">
                      <span *ngIf="order.ItemCount">{{ order.ItemCount }}</span>
                      <span *ngIf="!order.ItemCount">Ek Hizmet</span>
                    </td>
                    <td scope="row">$ {{ order.NetTotal |currency:' '}}</td>
                    <td scope="row"> {{order.PaymentNameTR}} </td>
                    <td scope="row">{{ order.ShippedDate | date:'dd.MM.yyyy'}} <br>{{ order.Time_ | date:'HH:mm' }}</td>
                    <td scope="row">
                      <span class="badge badge-warning" *ngIf="order.Status == 1"
                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{ order.StatusStr }}</span>
                      <span class="badge badge-info" *ngIf="order.Status == 2"
                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{ order.StatusStr }}</span>
                      <span class="badge badge-primary" style="background-color: #007bff!important;"
                        *ngIf="order.Status == 3" style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                        order.StatusStr }}</span>
                      <span class="badge badge-primary" style="background-color: #6c757d!important;"
                        *ngIf="order.Status == 4" style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{
                        order.StatusStr }}</span>
                      <span class="badge badge-success" *ngIf="order.Status == 5"
                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{ order.StatusStr }}</span>
                      <span class="badge badge-danger" *ngIf="order.Status == 8"
                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{ order.StatusStr }}</span>
                      <span class="badge badge-danger" *ngIf="order.Status == 9"
                        style="font-size: 13px;font-weight: 500;padding: 5px 15px;">{{ order.StatusStr }}</span>
                    </td>
                    <td><a type="button" (click)="gotoOrderDetail(order.Ref)"><i class="fa fa-edit"
                          style="font-size: 17px;"></i></a></td>
                  </tr>
                </tbody>
              </table>
              <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                style="left: 50%;position: relative;"></span>
              <div class="d-flex justify-content-between p-2" style="font-size: 11px;">
                <ngb-pagination [collectionSize]="totalCount" [(page)]="pageNumber" [pageSize]="pageSizePagination"
                  [rotate]="true" [maxSize]="5" [boundaryLinks]="true" [ellipses]="false"
                  style="justify-content: center;display: flex;">
                </ngb-pagination>
                <select class="custom-select" style="width: auto;font-size: 12px;" name="pageSize"
                  [(ngModel)]="pageSize">
                  <option [ngValue]="10">10 Adet Listele</option>
                  <option [ngValue]="25">25 Adet Listele</option>
                  <option [ngValue]="50">50 Adet Listele</option>
                  <option [ngValue]="100">100 Adet Listele</option>
                  <option [ngValue]="250">250 Adet Listele</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>