import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { CreateMenuComponent } from './create-menu/create-menu.component';
import { CreateFooterMenuComponent } from './create-footer-menu/create-footer-menu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { EditFooterMenuComponent } from './edit-footer-menu/edit-footer-menu.component';
import { SEOComponent } from './seo/seo.component';
import { FooterMenuComponent } from './footer-menu/footer-menu.component';

const routes: Routes = [
  {
    path: 'mega-menu',
    component: MegaMenuComponent,
    data: {
      title: 'Mega Menu Lists',
      breadcrumb: 'Mega Menu Lists',
    },
  },
  {
    path: 'account-menu',
    component: AccountMenuComponent,
    data: {
      title: 'Account Menu Lists',
      breadcrumb: 'Account Menu Lists',
    },
  },
  {
    path: 'footer-menu',
    component: FooterMenuComponent,
    data: {
      title: 'Footer Menu Lists',
      breadcrumb: 'Footer Menu Lists',
    },
  },
  {
    path: 'create-menu',
    component: CreateMenuComponent,
    data: {
      title: 'Create Menu',
      breadcrumb: 'Create Menu',
    },
  },
  {
    path: 'create-footer-menu',
    component: CreateFooterMenuComponent,
    data: {
      title: 'Create Footer Menu',
      breadcrumb: 'Create Footer Menu',
    },
  },
  {
    path: 'edit-menu',
    component: EditMenuComponent,
    data: {
      title: 'Edit Menu',
      breadcrumb: 'Edit Menu',
    },
  },
  {
    path: 'edit-footer-menu',
    component: EditFooterMenuComponent,
    data: {
      title: 'Edit Footer Menu',
      breadcrumb: 'Edit Footer Menu',
    },
  },
  {
    path: 'seo',
    component: SEOComponent,
    data: {
      title: 'SEO Yönetimi',
      breadcrumb: 'SEO Yönetimi',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MenusRoutingModule {}
