import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})
export class EditRoleComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditRoleModel: any = {};
  MenuList;
  IsLoading = false;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService,private TokenService: TokenService) {
    if (!this.TokenService.getUser().IsAdmin) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetRoleDetail();
    });
  }

  GetRoleDetail() {
    this.http.get<any>(environment.url + 'Roles/GetRole?roleRef=' + this.id).subscribe(data => {
      this.EditRoleModel = data;
      this.IsLoading = true;
    });
  }



  EditRole() {
    this.isEditing = true;
    
    this.http.post<any>(environment.url + 'Roles/UpdateRole', this.EditRoleModel).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Rol Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.isEditing = false;
        this.toastr.error('Rol Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
      }
    },
      error => {
        this.toastr.error('Rol Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }
}
