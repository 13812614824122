<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="IsLoading">
    <div class="card">
        <div class="card-header">
            <h5>Edit Role</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="RoleForm" class="needs-validation" novalidate #RoleForm="ngForm"
                            (ngSubmit)="RoleForm.form.valid && EditRole()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Rol Tanımı</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="RoleName"
                                    #RoleName="ngModel" [(ngModel)]="EditRoleModel.Role.RoleName"
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': RoleForm.submitted && RoleName.invalid  }" required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Admin</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Admin"name="Admin" #Admin="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Admin">
                                    <label for="Admin"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Member</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Member"name="Member" #Member="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Member">
                                    <label for="Member"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MemberList</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MemberList"name="MemberList" #MemberList="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MemberList">
                                    <label for="MemberList"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MemberAdd</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MemberAdd"name="MemberAdd" #MemberAdd="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MemberAdd">
                                    <label for="MemberAdd"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MemberEdit</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MemberEdit"name="MemberEdit" #MemberEdit="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MemberEdit">
                                    <label for="MemberEdit"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MemberDelete</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MemberDelete" name="MemberDelete" #MemberDelete="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MemberDelete">
                                    <label for="MemberDelete"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Orders</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Orders"name="Orders" #Orders="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Orders">
                                    <label for="Orders"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">OrderList</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="OrderList"name="OrderList" #OrderList="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.OrderList">
                                    <label for="OrderList"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">OrderAdd</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="OrderAdd"name="OrderAdd" #OrderAdd="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.OrderAdd">
                                    <label for="OrderAdd"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">OrderEdit</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="OrderEdit"name="OrderEdit" #OrderEdit="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.OrderEdit">
                                    <label for="OrderEdit"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">OrderDelete</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="OrderDelete"name="OrderDelete" #OrderDelete="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.OrderDelete">
                                    <label for="OrderDelete"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ProductionRequest</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ProductionRequest" name="ProductionRequest" #ProductionRequest="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ProductionRequest">
                                    <label for="ProductionRequest"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Products</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Products" name="Products" #Products="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Products">
                                    <label for="Products"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ProductList</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ProductList" name="ProductList" #ProductList="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ProductList">
                                    <label for="ProductList"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ProductAdd</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ProductAdd" name="ProductAdd" #ProductAdd="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ProductAdd">
                                    <label for="ProductAdd"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ProductDelete</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ProductDelete" name="ProductDelete" #ProductDelete="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ProductDelete">
                                    <label for="ProductDelete"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ProductUpdate</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ProductUpdate" name="ProductUpdate" #ProductUpdate="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ProductUpdate">
                                    <label for="ProductUpdate"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">FavoriteProducts</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="FavoriteProducts" name="FavoriteProducts" #FavoriteProducts="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.FavoriteProducts">
                                    <label for="FavoriteProducts"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">InstagramShop</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="InstagramShop" name="InstagramShop" #InstagramShop="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.InstagramShop">
                                    <label for="InstagramShop"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">BatchProcess</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="BatchProcess" name="BatchProcess" #BatchProcess="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.BatchProcess">
                                    <label for="BatchProcess"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ProductProcess</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ProductProcess" name="ProductProcess" #ProductProcess="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ProductProcess">
                                    <label for="ProductProcess"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">PriceProcess</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="PriceProcess" name="PriceProcess" #PriceProcess="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.PriceProcess">
                                    <label for="PriceProcess"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CMS</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CMS" name="CMS" #CMS="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CMS">
                                    <label for="CMS"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">TextContents</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="TextContents" name="TextContents" #TextContents="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.TextContents">
                                    <label for="TextContents"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ContactContents</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ContactContents" name="ContactContents" #ContactContents="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ContactContents">
                                    <label for="ContactContents"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MailContents</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MailContents" name="MailContents" #MailContents="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MailContents">
                                    <label for="MailContents"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">SocialMediaContents</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="SocialMediaContents" name="SocialMediaContents" #SocialMediaContents="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.SocialMediaContents">
                                    <label for="SocialMediaContents"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">PopupContents</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="PopupContents" name="PopupContents" #PopupContents="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.PopupContents">
                                    <label for="PopupContents"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">NewPageContents</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="NewPageContents" name="NewPageContents" #NewPageContents="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.NewPageContents">
                                    <label for="NewPageContents"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MenuManagement</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MenuManagement" name="MenuManagement" #MenuManagement="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MenuManagement">
                                    <label for="MenuManagement"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MegaMenu</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MegaMenu" name="MegaMenu" #MegaMenu="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MegaMenu">
                                    <label for="MegaMenu"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">AccountMenu</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="AccountMenu" name="AccountMenu" #AccountMenu="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.AccountMenu">
                                    <label for="AccountMenu"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">FooterMenu</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="FooterMenu" name="FooterMenu" #FooterMenu="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.FooterMenu">
                                    <label for="FooterMenu"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">SEOManagement</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="SEOManagement" name="SEOManagement" #SEOManagement="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.SEOManagement">
                                    <label for="SEOManagement"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">BannerSliderManagement</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="BannerSliderManagement" name="BannerSliderManagement"
                                        #BannerSliderManagement="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.BannerSliderManagement">
                                    <label for="BannerSliderManagement"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">BannerManagement</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="BannerManagement" name="BannerManagement" #BannerManagement="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.BannerManagement">
                                    <label for="BannerManagement"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">SliderManagement</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="SliderManagement" name="SliderManagement" #SliderManagement="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.SliderManagement">
                                    <label for="SliderManagement"></label>
                                </div>
                            </div>
                          
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Translates</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Translates" name="Translates" #Translates="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Translates">
                                    <label for="Translates"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">WebSiteTranslate</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="WebSiteTranslate" name="WebSiteTranslate" #WebSiteTranslate="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.WebSiteTranslate">
                                    <label for="WebSiteTranslate"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">AdminPanelTranslate</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="AdminPanelTranslate" name="AdminPanelTranslate" #AdminPanelTranslate="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.AdminPanelTranslate">
                                    <label for="AdminPanelTranslate"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Reports</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Reports" name="Reports" #Reports="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Reports">
                                    <label for="Reports"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Definitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Definitions" name="Definitions" #Definitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Definitions">
                                    <label for="Definitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">LanguageDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="LanguageDefinitions" name="LanguageDefinitions" #LanguageDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.LanguageDefinitions">
                                    <label for="LanguageDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CurrencyDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CurrencyDefinitions" name="CurrencyDefinitions" #CurrencyDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CurrencyDefinitions">
                                    <label for="CurrencyDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">MarkDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="MarkDefinitions" name="MarkDefinitions" #MarkDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.MarkDefinitions">
                                    <label for="MarkDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Colors</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Colors" name="Colors" #Colors="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Colors">
                                    <label for="Colors"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ColorTypes</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ColorTypes" name="ColorTypes" #ColorTypes="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ColorTypes">
                                    <label for="ColorTypes"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ColorGroups</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ColorGroups" name="ColorGroups" #ColorGroups="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ColorGroups">
                                    <label for="ColorGroups"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Categories</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Categories" name="Categories" #Categories="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Categories">
                                    <label for="Categories"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Properties</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Properties" name="Properties" #Properties="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Properties">
                                    <label for="Properties"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CategoryProperties</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CategoryProperties" name="CategoryProperties" #CategoryProperties="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CategoryProperties">
                                    <label for="CategoryProperties"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Pairing</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Pairing" name="Pairing" #Pairing="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Pairing">
                                    <label for="Pairing"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CargoTypeDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CargoTypeDefinitions" name="CargoTypeDefinitions" #CargoTypeDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CargoTypeDefinitions">
                                    <label for="CargoTypeDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CargoPriceList</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CargoPriceList" name="CargoPriceList" #CargoPriceList="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CargoPriceList">
                                    <label for="CargoPriceList"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">PaymentTypesDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="PaymentTypesDefinitions" name="PaymentTypesDefinitions"
                                        #PaymentTypesDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.PaymentTypesDefinitions">
                                    <label for="PaymentTypesDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ShippingMethodDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ShippingMethodDefinitions" name="ShippingMethodDefinitions"
                                        #ShippingMethodDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ShippingMethodDefinitions">
                                    <label for="ShippingMethodDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">OrderStatus</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="OrderStatus" name="OrderStatus" #OrderStatus="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.OrderStatus">
                                    <label for="OrderStatus"></label>
                                </div>
                            </div>
                           
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ServiceDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ServiceDefinitions" name="ServiceDefinitions" #ServiceDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ServiceDefinitions">
                                    <label for="ServiceDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CountryDefinitions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CountryDefinitions" name="CountryDefinitions" #CountryDefinitions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CountryDefinitions">
                                    <label for="CountryDefinitions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Settings</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Settings" name="Settings" #Settings="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Settings">
                                    <label for="Settings"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ConnectionSettings</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ConnectionSettings" name="ConnectionSettings" #ConnectionSettings="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ConnectionSettings">
                                    <label for="ConnectionSettings"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">IntegrationsSettings</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="IntegrationsSettings" name="IntegrationsSettings" #IntegrationsSettings="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.IntegrationsSettings">
                                    <label for="IntegrationsSettings"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">CompanyFactors</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="CompanyFactors" name="CompanyFactors" #CompanyFactors="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.CompanyFactors">
                                    <label for="CompanyFactors"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">SeoFormulas</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="SeoFormulas" name="SeoFormulas" #SeoFormulas="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.SeoFormulas">
                                    <label for="SeoFormulas"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">ThirdPartyExtensions</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="ThirdPartyExtensions" name="ThirdPartyExtensions" #ThirdPartyExtensions="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.ThirdPartyExtensions">
                                    <label for="ThirdPartyExtensions"></label>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Scripts</label>
                                <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                    <input type="checkbox" id="Scripts" name="Scripts" #Scripts="ngModel"
                                        [(ngModel)]="EditRoleModel.Auth.Scripts">
                                    <label for="Scripts"></label>
                                </div>
                            </div>
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isEditing"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Güncelle<span
                                        *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->