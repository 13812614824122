import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TokenService } from 'src/app/shared/service/token.service';
import { menuDB } from 'src/app/shared/tables/menu';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {

  public menus = [];
  public selected = [];

  constructor(private router: Router, private TokenService: TokenService) {
    this.menus = menuDB.data;
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['AccountMenu']) {
      router.navigateByUrl('/');
    }
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ngOnInit() { }


  gotoAccountMenuEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/menus/edit-menu'], navigatioExtra);
  }

  DeleteAccountMenu(item){
    
  }
}
