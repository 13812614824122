import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  LoginModel: any = {};
  loading = false;
  isVisible = false;
  isChecked: boolean;

  constructor(
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  login() {
    this.loading = true;

    const body = {
      UserName: this.LoginModel.UserName,
      Password: this.LoginModel.Password,
    };
    this.http
      .post<any>(environment.url + 'Login/AdminAuthenticate', body)
      .subscribe(
        (data) => {
          if (data) {
            this.loading = false;

            localStorage.setItem('AdminToken', data);
            this.router.navigate(['/home/dashboard']);
            this.toastr.success('Giriş Başarılı!', '', {
              timeOut: 2000,
            });
          }
        },
        (error) => {
          this.toastr.error(
            'Kullanıcı Adı veya Şifre Yanlış!',
            'Giriş Başarısız !',
            {
              timeOut: 2000,
            }
          );
          this.loading = false;
        }
      );
  }
  PasswordVisibility() {
    this.isVisible = !this.isVisible;
  }
}
