<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Shipment Definition</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="ShipmentForm" class="needs-validation" novalidate #ShipmentForm="ngForm"
                            (ngSubmit)="ShipmentForm.form.valid && AddShipment()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Kargo Tanımı</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="ShipmentCode" 
                                    #ShipmentCode="ngModel" [(ngModel)]="CreateShipmentModel.ShipmentCode" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': ShipmentForm.submitted && ShipmentCode.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">English</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="ShipmentDescriptionEN"
                                #ShipmentDescriptionEN="ngModel" [(ngModel)]="CreateShipmentModel.ShipmentDescriptionEN" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && ShipmentDescriptionEN.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Türkçe</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="ShipmentDescriptionTR"
                                #ShipmentDescriptionTR="ngModel" [(ngModel)]="CreateShipmentModel.ShipmentDescriptionTR" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && ShipmentDescriptionTR.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Russian</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="ShipmentDescriptionRU"
                                #ShipmentDescriptionRU="ngModel" [(ngModel)]="CreateShipmentModel.ShipmentDescriptionRU" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && ShipmentDescriptionRU.invalid  }"
                                required>
                            </div>


                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Tahmini Teslim English</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="EstimatedDescriptionEN"
                                #EstimatedDescriptionEN="ngModel" [(ngModel)]="CreateShipmentModel.EstimatedDescriptionEN" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && EstimatedDescriptionEN.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Tahmini Teslim Türkçe</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="EstimatedDescriptionTR"
                                #EstimatedDescriptionTR="ngModel" [(ngModel)]="CreateShipmentModel.EstimatedDescriptionTR" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && EstimatedDescriptionTR.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Tahmini Teslim Russian</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="EstimatedDescriptionRU"
                                #EstimatedDescriptionRU="ngModel" [(ngModel)]="CreateShipmentModel.EstimatedDescriptionRU" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && EstimatedDescriptionRU.invalid  }"
                                required>
                            </div>

                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Kargo Takip Parametresi</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="CargoCodeParameter"
                                #CargoCodeParameter ="ngModel" [(ngModel)]="CreateShipmentModel.CargoCodeParameter" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && CargoCodeParameter .invalid  }"
                                required>
                            </div>

                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Kargo Takip URL English</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="TrackingURLEN"
                                #TrackingURLEN ="ngModel" [(ngModel)]="CreateShipmentModel.TrackingURLEN" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && TrackingURLEN.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Kargo Takip URL Türkçe</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="TrackingURLTR"
                                #TrackingURLTR="ngModel" [(ngModel)]="CreateShipmentModel.TrackingURLTR" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && TrackingURLTR.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Kargo Takip URL Russian</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="TrackingURLRU"
                                #TrackingURLRU="ngModel" [(ngModel)]="CreateShipmentModel.TrackingURLRU" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && TrackingURLRU.invalid  }"
                                required>
                            </div>
                            
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Fiyat</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="Price"
                                #Price="ngModel" [(ngModel)]="CreateShipmentModel.Price" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': ShipmentForm.submitted && Price.invalid  }"
                                required>
                            </div>

                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Oluştur<span
                                    *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                    style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
      
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->