import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-pop-up-content',
  templateUrl: './add-pop-up-content.component.html',
  styleUrls: ['./add-pop-up-content.component.scss'],
})
export class AddPopUpContentsComponent implements OnInit {
  AddPopUpModel: any = {};
  id;
  isAddingPopUp;
  isUploadImage;
  isUploadImage2;
  imageSrc;
  imageSrc2;
  isVideo;
  isVideo2;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private TokenService: TokenService,
    private http: HttpClient
  ) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['PopupContents']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((x) => {
      this.id = x['id'];
    });
  }

  openImage(id = 'addimage') {
    // Click Image File Button
    let element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }
  addImageChange(files: FileList) {
    // Show Image File on change
    this.isUploadImage = true;

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http
      .post<any>(environment.url + 'PopUp/UploadImage', formData)
      .subscribe((data) => {
        this.isUploadImage = false;
        if (data) {
          if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
              this.imageSrc = reader.result as string;
              if (
                this.AddPopUpModel.PopupImage.substring(
                  this.AddPopUpModel.PopupImage.length - 3
                ) == 'mp4'
              )
                this.isVideo = true;
              else this.isVideo = false;
              this.AddPopUpModel.PopupImage = data;
            };
          }
          this.toastr.success('Dosya Yüklendi', '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Dosya Yüklenemedi', 'Hata !', {
            timeOut: 3000,
          });
        }
      });
  }

  addImageChange2(files: FileList) {
    // Show Image File on change
    this.isUploadImage2 = true;

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http
      .post<any>(environment.url + 'PopUp/UploadImage', formData)
      .subscribe((data) => {
        this.isUploadImage2 = false;
        if (data) {
          if (files) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
              this.imageSrc2 = reader.result as string;
              this.AddPopUpModel.PopupLogo = data;
              if (
                this.AddPopUpModel.PopupLogo.substring(
                  this.AddPopUpModel.PopupLogo.length - 3
                ) == 'mp4'
              )
                this.isVideo2 = true;
              else this.isVideo2 = false;
            };
          }
          this.toastr.success('Dosya Yüklendi', '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Dosya Yüklenemedi', 'Hata !', {
            timeOut: 3000,
          });
        }
      });
  }

  AddPopup() {
    this.isAddingPopUp = true;
    this.AddPopUpModel.PopupCounter = 0;
    this.http
      .post<any>(environment.url + 'PopUp/AddPopup', this.AddPopUpModel)
      .subscribe((data) => {
        if (data) {
          this.isAddingPopUp = false;
          this.toastr.success('Popup Eklendi.', '', {
            timeOut: 3000,
          });
          this.router.navigate(['/home/cms/pop-up-contents']);
        } else {
          this.isAddingPopUp = false;
          this.toastr.error('Popup Eklenemedi.', 'Hata !', {
            timeOut: 3000,
          });
        }
      });
  }
}
