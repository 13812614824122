import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-order-status',
  templateUrl: './create-order-status.component.html',
  styleUrls: ['./create-order-status.component.scss']
})
export class CreateOrderStatusComponent implements OnInit {
  isAdding = false;
  CreateOrderStatusModel: any = {};
  OrderStatusList;
  myDate;

  constructor(private router: Router,private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    this.myDate = new Date();
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['OrderStatus']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }


  AddOrderStatus() {
    this.isAdding = true;
    const body = { StatusTextTR : this.CreateOrderStatusModel.StatusTextTR, StatusTextEN  : this.CreateOrderStatusModel.StatusTextEN ,StatusTextRU  : this.CreateOrderStatusModel.StatusTextRU };
    this.http.post<any>(environment.url + 'Order/AddOrderStatus', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Sipariş Durumu Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/order-status-list"]);
      }
    },
      error => {
        this.toastr.error('Sipariş Durumu Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
