import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreatePageComponent } from './create-page/create-page.component';
import { EditPageComponent } from './edit-page/edit-page.component';
import { PopUpContentsComponent } from './pop-up-contents/pop-up-list/pop-up-contents.component';
import { EditPopUpContentsComponent } from './pop-up-contents/edit-pop-up/edit-pop-up-content.component';
import { AddPopUpContentsComponent } from './pop-up-contents/add-pop-up/add-pop-up-content.component';
import { TextContentsComponent } from './text-contents/text-contents.component';
import { ContactContentsComponent } from './contact-contents/contact-contents.component';
import { EditContactPageComponent } from './edit-contact-page/edit-contact-page.component';
import { MailContentListComponent } from './mail-contents/mail-content-list/mail-content-list.component';
import { EditMailContentComponent } from './mail-contents/edit-mail-content/edit-mail-content.component';
import { CreateMailContentComponent } from './mail-contents/create-mail-content/create-mail-content.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'text-contents',
        component: TextContentsComponent,
        data: {
          title: "Text Contents",
          breadcrumb: "Text Contents"
        }
      },
      {
        path: 'pop-up-contents',
        component: PopUpContentsComponent,
        data: {
          title: "Pop Up Contents",
          breadcrumb: "Pop Up Contents"
        }
      },
      
      {
        path: 'edit-pop-up-contents',
        component: EditPopUpContentsComponent,
        data: {
          title: "Edit Pop Up Contents",
          breadcrumb: "Edit Pop Up Contents"
        }
      },
      {
        path: 'add-pop-up-contents',
        component: AddPopUpContentsComponent,
        data: {
          title: "Add Pop Up Contents",
          breadcrumb: "Add Pop Up Contents"
        }
      },
      {
        path: 'create-page',
        component: CreatePageComponent,
        data: {
          title: "Create Page",
          breadcrumb: "Create Page"
        }
      },
    
      {
        path: 'edit-contact-page',
        component: EditContactPageComponent,
        data: {
          title: "Edit Contact Page",
          breadcrumb: "Edit Contact Page"
        }
      },
      {
        path: 'contact-contents',
        component: ContactContentsComponent,
        data: {
          title: "Contact Contents Page",
          breadcrumb: "Contact Contents Page"
        }
      },
      {
        path: 'edit-page',
        component: EditPageComponent,
        data: {
          title: "Edit Page",
          breadcrumb: "Edit Page"
        }
      },
      {
        path: 'mail-content-list',
        component: MailContentListComponent,
        data: {
          title: "Mail Content List",
          breadcrumb: "Mail Content List"
        }
      },
      {
        path: 'edit-mail-content',
        component: EditMailContentComponent,
        data: {
          title: "Edit Mail Content",
          breadcrumb: "Edit Mail Content"
        }
      },
      {
        path: 'create-mail-content',
        component: CreateMailContentComponent,
        data: {
          title: "Create Mail Content",
          breadcrumb: "Create Mail Content"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CMSRoutingModule { }
