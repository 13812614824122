import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-page',
  templateUrl: './create-page.component.html',
  styleUrls: ['./create-page.component.scss']
})
export class CreatePageComponent implements OnInit {
  isAdding = false;
  public editorValue: string = '';
  CreatePageModel: any = {};
  MenuList;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal, private TokenService: TokenService,) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['TextContents']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.GetMegaMenuList();
  }

  GetMegaMenuList() {
    this.http.get<any>(environment.url + 'Menu/GetMegaMenu').subscribe(data => {
      this.MenuList = data.Data;
    });
  }

  PageNameOnChange() {
    this.CreatePageModel.PageURL = "/" + this.CreatePageModel.PageName.replace(/ /g, '-').replace(/Ğ/gim, "g")
      .replace(/Ü/g, "u")
      .replace(/Ş/g, "s")
      .replace(/I/g, "i")
      .replace(/İ/g, "i")
      .replace(/Ö/g, "o")
      .replace(/Ç/g, "c")
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c").toLowerCase();
  }

  AddCMSContentPage() {
    this.isAdding = true;
    const body = { PageName: this.CreatePageModel.PageName, PageURL: this.CreatePageModel.PageURL, InnerHTML: this.editorValue, MenuRef: this.CreatePageModel.MenuRef, LanguageCode: this.CreatePageModel.LanguageCode, IsActive: this.CreatePageModel.IsActive,IsContactForm: this.CreatePageModel.IsContactForm  };
    this.http.post<any>(environment.url + 'Policies/CreatePolicies', body).subscribe(data => {
      if (data.Success) {
        this.isAdding = false;
        this.toastr.success("Sayfa Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/cms/text-contents"]);
      }
    },
      error => {
        this.toastr.error('Sayfa Oluşturulamadı!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
