import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-shipment',
  templateUrl: './create-shipment.component.html',
  styleUrls: ['./create-shipment.component.scss']
})
export class CreateShipmentComponent implements OnInit {
  isAdding = false;
  CreateShipmentModel: any = {};
  ShipmentList;
  myDate;

  constructor(private router: Router,private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['CargoTypeDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  

  AddShipment() {
    this.isAdding = true;
    const body = { ShipmentCode: this.CreateShipmentModel.ShipmentCode, ShipmentDescriptionTR: this.CreateShipmentModel.ShipmentDescriptionTR,  ShipmentDescriptionEN: this.CreateShipmentModel.ShipmentDescriptionEN, ShipmentDescriptionRU: this.CreateShipmentModel.ShipmentDescriptionRU,
      EstimatedDescriptionTR : this.CreateShipmentModel.EstimatedDescriptionTR, EstimatedDescriptionEN  : this.CreateShipmentModel.EstimatedDescriptionEN, EstimatedDescriptionRU  : this.CreateShipmentModel.EstimatedDescriptionRU, EstimatedBegDay  :  0,
       EstimatedEndDay  : 0, Price  : this.CreateShipmentModel.Price , Type  : 0,PaymentGroupRef :0 ,CargoCodeParameter : this.CreateShipmentModel.CargoCodeParameter ,
       TrackingURLTR : this.CreateShipmentModel.TrackingURLTR ,TrackingURLEN : this.CreateShipmentModel.TrackingURLEN ,TrackingURLRU : this.CreateShipmentModel.TrackingURLRU ,
    };
    this.http.post<any>(environment.url + 'Shipment/AddShipment', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Kargo Tanımı Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/shipment-list"]);
      }
    },
      error => {
        this.toastr.error('Kargo Tanımı Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
