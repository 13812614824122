import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pop-up-contents',
  templateUrl: './pop-up-contents.component.html',
  styleUrls: ['./pop-up-contents.component.scss']
})
export class PopUpContentsComponent implements OnInit {

  PopupList;

  constructor(private router: Router, private TokenService: TokenService, private http: HttpClient) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['PopupContents']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.http.get<any>(environment.url + 'Popup/GetAllPopups').subscribe(data => {
      this.PopupList = data.Data;
    });
  }

  gotoPageEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/cms/edit-pop-up-contents'], navigatioExtra);
  }

}
