import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss'],
})
export class EditMenuComponent implements OnInit {
  id;
  ColumnList: Array<any> = [];
  isFirstLoading = false;
  Model: any = {};
  RowModel: any = {};
  isSaving = false;
  MenuName;
  isUploadImage = false;
  MenuDetails;
  NewColumns = true;
  Categories = [];
  Genders = [];

  constructor(
    private modalService: NgbModal,
    private TokenService: TokenService,
    private elementRef: ElementRef,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['MegaMenu']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((x) => {
      this.id = x['id'];
      this.GetCategories(true);
      this.GetGenders();
    });
  }

  GetMegaMenuDetail() {
    this.isFirstLoading = true;
    this.http
      .get<any>(environment.url + 'Menu/GetMegaMenuByRef?MenuRef=' + this.id)
      .subscribe(
        (data) => {
          this.MenuDetails = data.Data[0];
          this.MenuName =
            this.MenuDetails.MenuName + ' - ' + this.MenuDetails.LanguageCode;
          this.Model.IsActive = this.MenuDetails.IsActive;
          this.Model.LanguageCode = this.MenuDetails.LanguageCode;
          this.Model.SortOrder = this.Model.SortOrder;
          this.Model.PropValRef = undefined;
          this.Model.MenuTag = this.MenuDetails.MenuTag
            ? this.MenuDetails.MenuTag
            : '';
          this.Model.FontColor = this.MenuDetails.FontColor
            ? this.MenuDetails.FontColor
            : '#000000';
          this.Model.BackColor = this.MenuDetails.BackColor
            ? this.MenuDetails.BackColor
            : '#FFFFFF';
          this.Model.FontStyle = this.MenuDetails.FontStyle
            ? this.MenuDetails.FontStyle
            : undefined;
          this.Model.MenuType = this.MenuDetails.MenuType
            ? this.MenuDetails.MenuType
            : undefined;
          this.Model.MenuTypeStr = this.MenuDetails.MenuTypeStr;
          this.Model.ColumnAmount = this.MenuDetails.Columns.length
            ? this.MenuDetails.Columns.length
            : undefined;
          this.Model.MenuLink = this.MenuDetails.MenuLink
            ? this.MenuDetails.MenuLink
            : '';
          this.Model.OpenType = this.MenuDetails.OpenType
            ? this.MenuDetails.OpenType
            : undefined;
          if (data.Data[0].Columns.length) {
            data.Data[0].Columns.forEach((element: any) => {
              const rows = element.Rows.map((row: any) => {
                if (row.RowType == 0) {
                  const ctgr = this.Categories.find(
                    (x) => x.CategoryRef == row.CategoryRef
                  );
                  if (ctgr) {
                    row.Props = ctgr.Props || [];

                    if (row.PropValRef && row.PropValRef != 0) {
                      const prop = row.Props.find(
                        (x: any) => x.PropRef == row.PropRef
                      );

                      if (prop) row.PropVals = prop.Vals;
                    }
                  }
                }
                return row;
              });

              this.ColumnList.push({
                Id: element.Id,
                Ref: element.Ref,
                ParentMenuRef: element.ParentMenuRef,
                Rows: rows,
                IsActive: true,
              });
            });
            this.NewColumns = true;
          } else {
            this.NewColumns = false;
          }

          this.isFirstLoading = false;
        },
        (error) => {
          this.isFirstLoading = false;
        }
      );
  }
  GetCategories(getmenuDetail: boolean = false) {
    if (getmenuDetail) this.isFirstLoading = true;
    this.http
      .get<any>(environment.url + 'Category/GetAllCategories?LangCode=tr')
      .subscribe((data) => {
        this.Categories = data;
        if (getmenuDetail) {
          this.GetMegaMenuDetail();
        }
      });
  }
  GetGenders() {
    this.http
      .get<any>(environment.url + 'Gender/GetGenders?LangCode=tr')
      .subscribe((data) => {
        this.Genders = data;
      });
  }

  CategoryOnChange(itemId, rowId, CategoryRef) {
    let index = this.ColumnList.findIndex((x) => x.Id === itemId);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === rowId
    );
    this.ColumnList[index].Rows[indexRows].CategoryRef = CategoryRef;

    const ctgr = this.Categories.find((x) => x.CategoryRef == CategoryRef);

    this.ColumnList[index].Rows[indexRows].Props = ctgr.Props;
  }

  PropOnChange(itemId, rowId, PropRef) {
    let index = this.ColumnList.findIndex((x) => x.Id === itemId);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === rowId
    );
    this.ColumnList[index].Rows[indexRows].PropRef = PropRef;

    const prop = this.ColumnList[index].Rows[indexRows].Props.find(
      (x) => x.PropRef == PropRef
    );

    this.ColumnList[index].Rows[indexRows].PropVals = prop.Vals;
  }

  GenderOnChange(itemId, rowId, GenderRef) {
    let index = this.ColumnList.findIndex((x) => x.Id === itemId);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === rowId
    );
    this.ColumnList[index].Rows[indexRows].GenderRef = GenderRef;
  }

  MenuTagOnChange() {
    if (this.Model.MenuType == '4') {
      this.Model.MenuLink = '';
    } else {
      this.Model.MenuLink =
        '/' +
        this.Model.MenuTag.replace(/ /g, '-')
          .replace(/Ğ/gim, 'g')
          .replace(/Ü/g, 'u')
          .replace(/Ş/g, 's')
          .replace(/I/g, 'i')
          .replace(/İ/g, 'i')
          .replace(/Ö/g, 'o')
          .replace(/Ç/g, 'c')
          .replace(/ğ/g, 'g')
          .replace(/ü/g, 'u')
          .replace(/ş/g, 's')
          .replace(/ı/g, 'i')
          .replace(/ö/g, 'o')
          .replace(/ç/g, 'c')
          .toLowerCase();
    }
  }
  FontColorOnChange() {
    this.Model.FontColor = $('#favcolor').val();
  }
  BackColorOnChange() {
    this.Model.BackColor = $('#backcolor').val();
  }
  MenuTypeOnChange($event) {
    this.Model.MenuTypeStr =
      $event.target.options[$event.target.options.selectedIndex].text;
    if (
      $event.target.options[$event.target.options.selectedIndex].text == '#'
    ) {
      this.Model.MenuLink = '';
    } else {
      this.Model.MenuLink =
        '/' +
        this.Model.MenuTag.replace(/ /g, '-')
          .replace(/Ğ/gim, 'g')
          .replace(/Ü/g, 'u')
          .replace(/Ş/g, 's')
          .replace(/I/g, 'i')
          .replace(/İ/g, 'i')
          .replace(/Ö/g, 'o')
          .replace(/Ç/g, 'c')
          .replace(/ğ/g, 'g')
          .replace(/ü/g, 'u')
          .replace(/ş/g, 's')
          .replace(/ı/g, 'i')
          .replace(/ö/g, 'o')
          .replace(/ç/g, 'c')
          .toLowerCase();
    }
  }

  CreateTableColumn() {
    let columnamount = Number(this.Model.ColumnAmount);
    for (let index = 0; index < columnamount; index++) {
      this.ColumnList.push({
        Id: index + 1,
        Ref: 0,
        ParentMenuRef: this.id,
        IsActive: true,
      });
    }
    this.toastr.success('Kolon Oluşturuldu!', '', {
      timeOut: 3000,
    });
  }
  AddTableColumn() {
    if (Number(this.Model.ColumnAmount) < 10) {
      let columnamount = Number(this.Model.ColumnAmount);
      this.ColumnList.push({
        Id: this.ColumnList.slice(-1)[0].Id + 1,
        Ref: 0,
        IsActive: true,
      });
      this.Model.ColumnAmount = columnamount + 1;
      this.toastr.success('Yeni Kolon Eklendi!', '', {
        timeOut: 3000,
      });
    } else {
      this.toastr.error('Kolon Sayısı Aşılamaz !', '', {
        timeOut: 3000,
      });
    }
  }
  DeleteTableColumn(Id) {
    let index = this.ColumnList.findIndex((x) => x.Id === Id);
    if (index != -1) {
      this.ColumnList.splice(index, 1);
      this.Model.ColumnAmount = Number(this.Model.ColumnAmount) - 1;
      this.toastr.success('Kolon Silindi!', '', {
        timeOut: 3000,
      });
    } else {
      this.toastr.error('Bilinmeyen Hata Oluştu !', '', {
        timeOut: 3000,
      });
    }
  }

  RowMenuTagOnChange(itemId, rowId, MenuTag) {
    let index = this.ColumnList.findIndex((x) => x.Id === itemId);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === rowId
    );
    if (this.ColumnList[index].Rows[indexRows].RowType == '3') {
      this.ColumnList[index].Rows[indexRows].RowLink = '';
    } else {
      this.ColumnList[index].Rows[indexRows].RowLink =
        '/' +
        MenuTag.replace(/ /g, '-')
          .replace(/Ğ/gim, 'g')
          .replace(/Ü/g, 'u')
          .replace(/Ş/g, 's')
          .replace(/I/g, 'i')
          .replace(/İ/g, 'i')
          .replace(/Ö/g, 'o')
          .replace(/Ç/g, 'c')
          .replace(/ğ/g, 'g')
          .replace(/ü/g, 'u')
          .replace(/ş/g, 's')
          .replace(/ı/g, 'i')
          .replace(/ö/g, 'o')
          .replace(/ç/g, 'c')
          .toLowerCase();
    }
  }
  RowContentTypeOnChange(itemId, rowId, $event) {
    let index = this.ColumnList.findIndex((x) => x.Id === itemId);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === rowId
    );
    this.ColumnList[index].Rows[indexRows].ContentTypeStr =
      $event.target.options[$event.target.options.selectedIndex].text;
  }
  RowTypeOnChange(itemId, rowId, $event) {
    let index = this.ColumnList.findIndex((x) => x.Id === itemId);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === rowId
    );
    this.ColumnList[index].Rows[indexRows].RowTypeStr =
      $event.target.options[$event.target.options.selectedIndex].text;
    if (
      $event.target.options[$event.target.options.selectedIndex].text == '#'
    ) {
      this.ColumnList[index].Rows[indexRows].RowLink = '';
    } else {
      this.ColumnList[index].Rows[indexRows].RowLink =
        '/' +
        this.ColumnList[index].Rows[indexRows].MenuTag.replace(/ /g, '-')
          .replace(/Ğ/gim, 'g')
          .replace(/Ü/g, 'u')
          .replace(/Ş/g, 's')
          .replace(/I/g, 'i')
          .replace(/İ/g, 'i')
          .replace(/Ö/g, 'o')
          .replace(/Ç/g, 'c')
          .replace(/ğ/g, 'g')
          .replace(/ü/g, 'u')
          .replace(/ş/g, 's')
          .replace(/ı/g, 'i')
          .replace(/ö/g, 'o')
          .replace(/ç/g, 'c')
          .toLowerCase();
    }
  }

  AddRows(Id) {
    let item = this.ColumnList.find((x) => x.Id === Id);
    let index = this.ColumnList.findIndex((x) => x.Id === Id);
    if (this.ColumnList[index].Rows) {
      if (this.ColumnList[index].Rows.length) {
        this.ColumnList[index].Rows.push({
          Id: this.ColumnList[index].Rows.slice(-1)[0].Id + 1,
          CategoryRef: undefined,
          Ref: 0,
          MegaMenuRef: this.id,
          MenuColumnRef: item.Ref,
          ContentType: undefined,
          GenderRef: 0,
          PropRef: 0,
          PropValRef: 0,
          ContentTypeStr: '',
          MenuTag: '',
          ImageName: '',
          ImageLocation: '',
          FontColor: '#000000',
          BackColor: '#FFFFFF',
          FontStyle: undefined,
          RowType: undefined,
          RowTypeStr: '',
          RowLink: '',
          OpenType: undefined,
          IsActive: true,
          StockControl: '',
          LanguageCode: this.Model.LanguageCode,
        });
      } else {
        this.ColumnList[index] = {
          Id: item.Id,
          Ref: this.NewColumns ? item.Ref : 0,
          ParentMenuRef: this.id,
          Rows: [
            {
              Id: 1,
              CategoryRef: undefined,
              Ref: this.NewColumns ? 1 : 0,
              MegaMenuRef: this.id,
              MenuColumnRef: item.Ref,
              ContentType: undefined,
              GenderRef: 0,
              PropRef: 0,
              PropValRef: 0,
              ContentTypeStr: '',
              MenuTag: '',
              ImageName: '',
              ImageLocation: '',
              FontColor: '#000000',
              BackColor: '#FFFFFF',
              FontStyle: undefined,
              RowType: undefined,
              RowTypeStr: '',
              RowLink: '',
              OpenType: undefined,
              IsActive: true,
              StockControl: '',
              LanguageCode: this.Model.LanguageCode,
            },
          ],
        };
      }
    } else {
      this.ColumnList[index] = {
        Id: item.Id,
        Ref: this.NewColumns ? item.Ref : 0,
        ParentMenuRef: this.id,
        Rows: [
          {
            Id: 1,
            CategoryRef: undefined,
            Ref: this.NewColumns ? 1 : 0,
            MegaMenuRef: this.id,
            MenuColumnRef: item.Ref,
            ContentType: undefined,
            GenderRef: 0,
            PropRef: 0,
            PropValRef: 0,
            ContentTypeStr: '',
            MenuTag: '',
            ImageName: '',
            ImageLocation: '',
            FontColor: '#000000',
            BackColor: '#FFFFFF',
            FontStyle: undefined,
            RowType: undefined,
            RowTypeStr: '',
            RowLink: '',
            OpenType: undefined,
            IsActive: true,
            StockControl: '',
            LanguageCode: this.Model.LanguageCode,
          },
        ],
      };
    }
  }
  DeleteTableRows(Id, RowsId) {
    let index = this.ColumnList.findIndex((x) => x.Id === Id);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === RowsId
    );
    if (indexRows != -1) {
      this.ColumnList[index].Rows.splice(indexRows, 1);
      this.toastr.success('Satır Silindi!', '', {
        timeOut: 3000,
      });
    } else {
      this.toastr.error('Bilinmeyen Hata Oluştu !', '', {
        timeOut: 3000,
      });
    }
  }

  SaveMegaMenu() {
    this.isSaving = true;
    let i = 0;
    if (this.ColumnList.length) {
      this.ColumnList.forEach((element) => {
        i++;
        if (
          (!element.Rows || element.Rows.length == 0) &&
          this.isSaving == true
        ) {
          this.toastr.error('Boş Kolon Kaydı Yapılamaz.', 'Hata !', {
            timeOut: 3000,
          });
          this.isSaving = false;
        } else if (element.Rows && this.isSaving) {
          if (element.Rows.length != 0) {
            // Remove Props and PropVals from Rows

            element.Rows.map((row: any) => {
              delete row.Props;
              delete row.PropVals;
              return row;
            });

            if (i != this.ColumnList.length) return;

            const body = {
              Ref: this.id,
              MenuName: this.MenuDetails.MenuName,
              IsActive: this.Model.IsActive,
              LanguageCode: this.MenuDetails.LanguageCode,
              ColumnCount: this.Model.ColumnAmount,
              OpenType: this.Model.OpenType,
              MenuTag: this.Model.MenuTag,
              FontColor: this.Model.FontColor,
              FontStyle: this.Model.FontStyle,
              BackColor: this.Model.BackColor,
              MenuType: this.Model.MenuType,
              MenuTypeStr: this.Model.MenuTypeStr,
              MenuLink: this.Model.MenuLink,
              SortOrder: this.Model.SortOrder,
              Columns: this.ColumnList,
            };
            this.http
              .post<any>(environment.url + 'Menu/SaveMegaMenu', body)
              .subscribe((data) => {
                this.isSaving = false;
                if (data.Success == true) {
                  this.modalService.dismissAll();
                  this.toastr.success('Mega Menü Kaydedildi', '', {
                    timeOut: 3000,
                  });
                  this.router.navigate(['/home/menus/mega-menu']);
                } else {
                  this.toastr.error('Mega Menü Kaydedilemedi', 'Hata !', {
                    timeOut: 3000,
                  });
                }
              });
          }
        }
      });
    } else {
      const body = {
        Ref: this.id,
        MenuName: this.MenuDetails.MenuName,
        IsActive: this.Model.IsActive,
        LanguageCode: this.MenuDetails.LanguageCode,
        ColumnCount: this.Model.ColumnAmount,
        OpenType: this.Model.OpenType,
        MenuTag: this.Model.MenuTag,
        FontColor: this.Model.FontColor,
        FontStyle: this.Model.FontStyle,
        BackColor: this.Model.BackColor,
        MenuType: this.Model.MenuType,
        MenuTypeStr: this.Model.MenuTypeStr,
        MenuLink: this.Model.MenuLink,
        SortOrder: this.Model.SortOrder,
        Columns: this.ColumnList,
      };
      this.http
        .post<any>(environment.url + 'Menu/SaveMegaMenu', body)
        .subscribe((data) => {
          this.isSaving = false;
          if (data.Success == true) {
            this.modalService.dismissAll();
            this.toastr.success('Mega Menü Kaydedildi', '', {
              timeOut: 3000,
            });
          } else {
            this.toastr.error('Mega Menü Kaydedilemedi', 'Hata !', {
              timeOut: 3000,
            });
          }
        });
    }
  }

  DeleteImage(Id, RowsId) {
    let index = this.ColumnList.findIndex((x) => x.Id === Id);
    let indexRows = this.ColumnList[index].Rows.findIndex(
      (x) => x.Id === RowsId
    );
    if (indexRows != -1) {
      this.ColumnList[index].Rows[indexRows].ImageLocation = '';
      this.ColumnList[index].Rows[indexRows].ImageName = '';
      this.toastr.success('Resim Silindi!', '', {
        timeOut: 3000,
      });
    } else {
      this.toastr.error('Bilinmeyen Hata Oluştu !', '', {
        timeOut: 3000,
      });
    }
  }

  openImage(Id, RowsId) {
    // Click Image File Button
    let element: HTMLElement = document.getElementById(
      'addimage' + Id + RowsId
    ) as HTMLElement;
    element.click();
  }
  addImageChange(files: FileList, itemId, rowId) {
    // Show Image File on change
    this.isUploadImage = true;
    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    this.http
      .post<any>(environment.url + 'Menu/UploadRowImage', formData)
      .subscribe((data) => {
        this.isUploadImage = false;
        if (data) {
          let index = this.ColumnList.findIndex((x) => x.Id === itemId);
          let indexRows = this.ColumnList[index].Rows.findIndex(
            (x) => x.Id === rowId
          );
          this.ColumnList[index].Rows[indexRows].ImageName = files[0].name;
          this.ColumnList[index].Rows[indexRows].ImageLocation = data;
          this.modalService.dismissAll();
          this.toastr.success('Resim Yüklendi', '', {
            timeOut: 3000,
          });
        } else {
          this.toastr.error('Resim Yüklenemedi', 'Hata !', {
            timeOut: 3000,
          });
        }
      });
  }
}
