import { HttpClient } from '@angular/common/http';
import { AssertNotNull } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-mail-content',
  templateUrl: './create-mail-content.component.html',
  styleUrls: ['./create-mail-content.component.scss']
})
export class CreateMailContentComponent implements OnInit {

  MailTemplateModel: any = {};
  SMTPTemplates: any = [];
  CCLists: any = [];
  public editorValue: string = '';
  isAdding;
  MailModules;
  UseOrderStatusRef;
  Status;
  tokens: any = [];
  isLoadingtokens;
  constructor(private router: Router, private TokenService: TokenService,private modalService: NgbModal, private toastr: ToastrService,private http: HttpClient) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['MailContents']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.GetCCList();
    this.GetSMTPTemplates();
    this.GetMailModules();
    this.MailTemplateModel.ContentType = 'ckeditor';
  }

  GetSMTPTemplates() {
    this.http.get<any>(environment.url + 'Mail/GetSMTPTemplates').subscribe(data => {
      this.SMTPTemplates = data.SMTP;
    });
  }
  GetMailModules() {
    this.http.get<any>(environment.url + 'Mail/GetMailModules').subscribe(data => {
      this.MailModules = data.MailModules;
    });
  }
  GetCCList(){
    this.http.get<any>(environment.url + 'Mail/GetMailCCList').subscribe(data => {
      this.CCLists = data.MailTemplate;
      console.log(this.CCLists);
    });
  }
  onChangeModule(){
    this.tokens = [];
    this.isLoadingtokens = true;
    let item = this.MailModules.find(x => x.Ref == this.MailTemplateModel.MailModuleRef);
    if(item.UseOrderStatusRef){
      this.http.get<any>(environment.url + 'Order/GetOrderStatus').subscribe(data => {
        this.UseOrderStatusRef = true;
        this.Status = data;
      });
    }
    else this.UseOrderStatusRef = false;
    if(this.MailTemplateModel.MailModuleRef == 1){
      this.http.get<any>(environment.url + 'Mail/GetRegisterTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    else if(this.MailTemplateModel.MailModuleRef == 2){
      this.http.get<any>(environment.url + 'Mail/GetOrderTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    
    else if(this.MailTemplateModel.MailModuleRef == 3){
      this.http.get<any>(environment.url + 'Mail/GetRegisterTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    
    else if(this.MailTemplateModel.MailModuleRef == 4){
      this.http.get<any>(environment.url + 'Mail/GetPaymentTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    
    else if(this.MailTemplateModel.MailModuleRef == 5){
      this.http.get<any>(environment.url + 'Mail/GetShipmentTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }

    else if(this.MailTemplateModel.MailModuleRef == 6){
      this.http.get<any>(environment.url + 'Mail/GetOrderTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    else if(this.MailTemplateModel.MailModuleRef == 7){
      this.http.get<any>(environment.url + 'Mail/GetContactPageTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    else if(this.MailTemplateModel.MailModuleRef == 8){
      this.http.get<any>(environment.url + 'Mail/GetRequestPageToken').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
  }
  AddMailTemplate(){
    this.isAdding = true;
    let cc;
    if(this.MailTemplateModel.ToList){
      cc = this.MailTemplateModel.ToList.join(";");
    }
    const body = { ContentType: this.MailTemplateModel.ContentType,TemplateName: this.MailTemplateModel.TemplateName, Subject : this.MailTemplateModel.Subject, SMTPRef  : this.MailTemplateModel.SMTPRef ,MailModuleRef : this.MailTemplateModel.MailModuleRef,
     LangCode : this.MailTemplateModel.LangCode, MailHTML : this.editorValue, ToList : cc,IsActive: this.MailTemplateModel.IsActive,OrderStatusRef: this.MailTemplateModel.OrderStatusRef
    };
    this.http.post<any>(environment.url + 'Mail/AddMailTemplate', body).subscribe(data => {
      if (data) {
        this.isAdding = false;
        this.toastr.success("Mail Şablonu Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/cms/mail-content-list"]);
      }
    },
      error => {
        this.toastr.error('Mail Şablonu Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });
  }
}
