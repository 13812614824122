<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Edit Banner Location Type</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="BannerTypeForm" class="needs-validation" novalidate #BannerTypeForm="ngForm"
                            (ngSubmit)="BannerTypeForm.form.valid && EditBannerType()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Banner Lokasyon Tanımı</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="BannerType"
                                    #BannerType="ngModel" [(ngModel)]="EditBannerTypeModel.BannerType"
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': BannerTypeForm.submitted && BannerType.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Satır Tipi Tanımı</label>
                                <select class="form-control select col-xl-8 col-md-7" name="RowTypeStr" #RowTypeStr style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': BannerTypeForm.submitted && RowTypeStr.invalid  }"
                                    [(ngModel)]="EditBannerTypeModel.RowTypeStr" required>
                                    <option value="Kategori">Kategori</option>
                                    <option value="Link">Link</option>
                                    <option value="İçerik">İçerik</option>
                                    <option value="Home">Home</option>
                                    <option value="Tükenmiş">Tükenmiş</option>
                                    <option value="Yeni">Yeni Ürünler</option>
                                    <option value="indirim">İndirimli Ürünler</option>
                                    <option value="Group">Group</option>
                                </select>
                                <div *ngIf="BannerTypeForm.submitted && RowTypeStr.invalid" class="invalid-feedback">
                                    <div>Row Type Seçiniz!</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Size WH</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="SizeWH"
                                    #SizeWH="ngModel" [(ngModel)]="EditBannerTypeModel.SizeWH" style="font-size: 13px;">
                            </div>


                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isEditing"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Güncelle<span
                                        *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
