import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-mail-content',
  templateUrl: './edit-mail-content.component.html',
  styleUrls: ['./edit-mail-content.component.scss']
})
export class EditMailContentComponent implements OnInit {
  public editorValue: string = '';
  id;
  EditMailTemplateModel: any = {};
  isEditing;
  MailModules;
  UseOrderStatusRef;
  Status;
  tokens: any = [];
  isLoadingtokens;
  SMTPTemplates: any = [];
  CCLists: any = [];
  isLoading;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private TokenService: TokenService, private modalService: NgbModal, private toastr: ToastrService, private http: HttpClient) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['MailContents']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.http.get<any>(environment.url + 'Mail/GetMailTemplates').subscribe(data => {
        data.MailTemplate.forEach(element => {
          if (element.Ref == this.id) {
            this.editorValue = element.MailHTML;
            this.isLoading = true;
            this.EditMailTemplateModel = element;
            this.EditMailTemplateModel.ToList = element.ToList.split(";");
            this.http.get<any>(environment.url + 'Mail/GetMailModules').subscribe(data => {
              this.MailModules = data.MailModules;
              let item = this.MailModules.find(x => x.Ref == this.EditMailTemplateModel.MailModuleRef);
              if (item.UseOrderStatusRef) {
                this.http.get<any>(environment.url + 'Order/GetOrderStatus').subscribe(data => {
                  this.UseOrderStatusRef = true;
                  this.Status = data;
                });
              }
              else this.UseOrderStatusRef = false;
            });

            if (this.EditMailTemplateModel.MailModuleRef == 1) {
              this.http.get<any>(environment.url + 'Mail/GetRegisterTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }
            else if (this.EditMailTemplateModel.MailModuleRef == 2) {
              this.http.get<any>(environment.url + 'Mail/GetOrderTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }

            else if (this.EditMailTemplateModel.MailModuleRef == 3) {
              this.http.get<any>(environment.url + 'Mail/GetRegisterTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }

            else if (this.EditMailTemplateModel.MailModuleRef == 4) {
              this.http.get<any>(environment.url + 'Mail/GetPaymentTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }

            else if (this.EditMailTemplateModel.MailModuleRef == 5) {
              this.http.get<any>(environment.url + 'Mail/GetShipmentTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }

            else if (this.EditMailTemplateModel.MailModuleRef == 6) {
              this.http.get<any>(environment.url + 'Mail/GetOrderTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }
            else if(this.EditMailTemplateModel.MailModuleRef == 7){
              this.http.get<any>(environment.url + 'Mail/GetContactPageTokens').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }
            else if(this.EditMailTemplateModel.MailModuleRef == 8){
              this.http.get<any>(environment.url + 'Mail/GetRequestPageToken').subscribe(data => {
                this.tokens = data;
                this.isLoadingtokens = false;
              });
            }
          }
        });
      });

      this.GetCCList();
      this.GetSMTPTemplates();
    });
  }
  GetSMTPTemplates() {
    this.http.get<any>(environment.url + 'Mail/GetSMTPTemplates').subscribe(data => {
      this.SMTPTemplates = data.SMTP;
    });
  }
  GetCCList() {
    this.http.get<any>(environment.url + 'Mail/GetMailCCList').subscribe(data => {
      this.CCLists = data.MailTemplate;
      console.log(this.CCLists);
    });
  }

  onChangeModule() {
    this.tokens = [];
    this.isLoadingtokens = true;
    let item = this.MailModules.find(x => x.Ref == this.EditMailTemplateModel.MailModuleRef);
    if (item.UseOrderStatusRef) {
      this.http.get<any>(environment.url + 'Order/GetOrderStatus').subscribe(data => {
        this.UseOrderStatusRef = true;
        this.Status = data;
      });
    }
    else this.UseOrderStatusRef = false;
    if (this.EditMailTemplateModel.MailModuleRef == 1) {
      this.http.get<any>(environment.url + 'Mail/GetRegisterTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
    else if (this.EditMailTemplateModel.MailModuleRef == 2) {
      this.http.get<any>(environment.url + 'Mail/GetOrderTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }

    else if (this.EditMailTemplateModel.MailModuleRef == 3) {
      this.http.get<any>(environment.url + 'Mail/GetRegisterTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }

    else if (this.EditMailTemplateModel.MailModuleRef == 4) {
      this.http.get<any>(environment.url + 'Mail/GetPaymentTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }

    else if (this.EditMailTemplateModel.MailModuleRef == 5) {
      this.http.get<any>(environment.url + 'Mail/GetShipmentTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }

    else if (this.EditMailTemplateModel.MailModuleRef == 6) {
      this.http.get<any>(environment.url + 'Mail/GetOrderTokens').subscribe(data => {
        this.tokens = data;
        this.isLoadingtokens = false;
      });
    }
  }
  EditMailTemplate() {
    this.isEditing = true;
    let cc;
    if(this.EditMailTemplateModel.ToList){
      cc = this.EditMailTemplateModel.ToList.join(";");
    }
    const body = { Ref: this.id, ContentType: this.EditMailTemplateModel.ContentType,
      TemplateName: this.EditMailTemplateModel.TemplateName, Subject: this.EditMailTemplateModel.Subject, SMTPRef: this.EditMailTemplateModel.SMTPRef, MailModuleRef: this.EditMailTemplateModel.MailModuleRef,
      LangCode: this.EditMailTemplateModel.LangCode, MailHTML: this.editorValue, ToList: cc, IsActive: this.EditMailTemplateModel.IsActive, OrderStatusRef: this.EditMailTemplateModel.OrderStatusRef
    };
    this.http.post<any>(environment.url + 'Mail/UpdateMailTemplate', body).subscribe(data => {
      if (data.MailTemplate) {
        this.isEditing = false;
        this.toastr.success("Mail Şablonu Düzenlendi !", '', {
          timeOut: 2000,
        });
      }
      else{
        this.toastr.error('Mail Şablonu Düzenlenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Mail Şablonu Düzenlenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });
  }
}
