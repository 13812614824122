<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Edit Country</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="CountryForm" class="needs-validation" novalidate #CountryForm="ngForm"
                            (ngSubmit)="CountryForm.form.valid && EditCountry()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Ülke</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="CountryName" #CountryName="ngModel"
                                    [(ngModel)]="EditCountryModel.Name " style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && CountryName.invalid  }" required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Ülke Kodu</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="Code" #Code="ngModel"
                                    [(ngModel)]="EditCountryModel.Code" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && Code.invalid  }" required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">3 Haneli Ülke Kodu</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="Code3" #Code3="ngModel"
                                    [(ngModel)]="EditCountryModel.Code3 " style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && Code3.invalid  }" required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Telefon Kodu</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="PhoneCode"
                                    #PhoneCode="ngModel" [(ngModel)]="EditCountryModel.PhoneCode "
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': CountryForm.submitted && PhoneCode.invalid  }" required>
                            </div>
                            <div class="form-group row" style="justify-content: center;">
                                <div class="col-lg-4" style="text-align: center;">
                                    <h3 style="color: black;margin-bottom: 10px;font-weight: 600;">ŞEHİRLER <button
                                            type="button" [disabled]="IsEditingCity || IsAdding" (click)="AddNewCity()"
                                            style="border: none;background: none;"><i class="fa fa-plus"
                                                style="font-size: 24px;color: #22af47;"></i></button></h3>

                                    <div class="row" id="style-3" *ngIf="CityList.length"
                                        style="border: 1px solid gray;text-align: center;max-height: 500px;overflow: auto;border-radius: 4px;">
                                        <ng-container *ngFor="let item of CityList;let last = last;">
                                            <div class="col-lg-12" [ngClass]="{'border-bottom': !last}" style="padding: 10px;">
                                                <span *ngIf="item.Ref != EditItemID"
                                                    style="background-color: #eceaea;border-radius: 5px;border: 1px solid gray;padding: 3px 10px;display: block;">{{item.Name}}
                                                    <a type="button" (click)="OpenDeleteCity(deletecity,item)"
                                                        style="float: right;cursor: pointer;"><i class="fa fa-trash"
                                                            style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>

                                                    <a (click)="OpenEditCity(item.Ref,item.Name)"
                                                        *ngIf="!IsEditingCity && !IsAdding"
                                                        style="cursor: pointer;float: right;"><i class="fa fa-pencil"
                                                            style="font-size: 19px;color: #ff5370;"></i></a>

                                                </span>
                                                <div style="display: flex; align-items: center;"
                                                    *ngIf="IsEditingCity && item.Ref === EditItemID">
                                                    <input class="form-control" type="text" name="EditCityName"
                                                        #EditCityName="ngModel" [(ngModel)]="EditModelItem.Name"
                                                        style="font-size: 13px;margin-right: 10px;"
                                                        [ngClass]="{ 'is-invalid': CountryForm.submitted && EditCityName.invalid  }"
                                                        required>

                                                    <div style="display: flex;">
                                                        <a type="button" (click)="SaveCity()"><i class="fa fa-check"
                                                                style="font-size: 19px;color: #22af47;"></i></a>
                                                        <a type="button" (click)="CancelCity()"><i class="fa fa-times"
                                                                style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                                    </div>
                                                </div>

                                                <div style="display: flex; align-items: center;"
                                                    *ngIf="IsAdding && item.Key == 'New'">
                                                    <input class="form-control" type="text" name="AddName"
                                                        #AddName="ngModel" [(ngModel)]="AddModelItem.Name"
                                                        style="font-size: 13px;margin-right: 10px;"
                                                        [ngClass]="{ 'is-invalid': CountryForm.submitted && AddName.invalid  }"
                                                        required>

                                                    <div style="display: flex;">
                                                        <a type="button" (click)="AddCity()"><i class="fa fa-check"
                                                                style="font-size: 19px;color: #22af47;"></i></a>
                                                        <a type="button" (click)="CancelCity()"><i class="fa fa-times"
                                                                style="font-size: 19px;margin-left: 10px;color: #ff5370;"></i></a>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>


                                    </div>
                                </div>
                            </div>

                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isEditing"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Düzenle<span
                                        *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<ng-template #deletecity let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" (click)="CloseDeleteModalClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="text-align: center;">
            Şehiri Silmek İstediğinizden Emin misiniz ?
        </div>
        <div class="modal-footer" style="text-align: center;display: block;">
            <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%"
                [disabled]="isDeleting" (click)="DeleteCity()">Sil<span *ngIf="isDeleting"
                    class="spinner-border spinner-border-sm ml-1"></span></button>
            <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
                (click)="CloseDeleteModalClose()">Vazgeç</button>
        </div>
    </div>
</ng-template>