<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Edit Instagram </h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="InstagramForm" class="needs-validation" novalidate #InstagramForm="ngForm"
                            (ngSubmit)="InstagramForm.form.valid && EditInstagramDetail()">
                            <div class="form-group row">
                                <div class="col-lg-12" style="text-align: center;">
                                    <label class="label">Resim</label>
                                    <img style="width: 15%;border-radius: 10px;border: 1px solid;"
                                        src="https://scm.vipstendo.com/AdminService/{{EditInstagramModel.ImageURL}}">
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4">
                                    <label class="label">Media ID</label>
                                    <span class="input-fake">{{ EditInstagramModel.MediaID }}</span>
                                </div>
                                <div class="col-lg-4">
                                    <label class="label">Perma Link</label>
                                    <span class="input-fake">{{ EditInstagramModel.PermaLink }}</span>
                                </div>
                                <div class="col-lg-4">
                                    <label class="label">Caption</label>
                                    <span class="input-fake">{{ EditInstagramModel.Caption }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-6">
                                    <label class="label">Yayın Tarihi</label>
                                    <span class="input-fake">{{ EditInstagramModel.PublishDate | date:'dd-MM-yyyy hh:mm'
                                        }}</span>
                                </div>
                                <div class="col-lg-6">
                                    <label class="label">Onay Tarihi</label>
                                    <span class="input-fake">{{ EditInstagramModel.ApproveDate | date:'dd-MM-yyyy hh:mm'
                                        }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-6">
                                    <label class="label">Link</label>
                                    <span class="input-fake">{{ EditInstagramModel.Link }}</span>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label class="label">Resim ALT TR</label>
                                            <input class="form-control" type="text" name="ImageAlt" #ImageAlt="ngModel"
                                                [(ngModel)]="EditInstagramModel.ImageAlt" style="font-size: 13px;">
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="label">Resim ALT EN</label>
                                            <input class="form-control" type="text" name="ImageAltEN"
                                                #ImageAltEN="ngModel" [(ngModel)]="EditInstagramModel.ImageAltEN"
                                                style="font-size: 13px;">
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="label">Resim ALT RU</label>
                                            <input class="form-control" type="text" name="ImageAltRU"
                                                #ImageAltRU="ngModel" [(ngModel)]="EditInstagramModel.ImageAltRU"
                                                style="font-size: 13px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-4">
                                    <label class="label">Ürün URL TR</label>
                                    <input class="form-control" type="text" name="ProductURL" #ProductURL="ngModel"
                                        [(ngModel)]="EditInstagramModel.ProductURL" style="font-size: 13px;">
                                </div>
                                <div class="col-lg-4">
                                    <label class="label">Ürün URL EN</label>
                                    <input class="form-control" type="text" name="ProductURLEN" #ProductURLEN="ngModel"
                                        [(ngModel)]="EditInstagramModel.ProductURLEN" style="font-size: 13px;">
                                </div>
                                <div class="col-lg-4">
                                    <label class="label">Ürün URL RU</label>
                                    <input class="form-control" type="text" name="ProductURLRU" #ProductURLRU="ngModel"
                                        [(ngModel)]="EditInstagramModel.ProductURLRU" style="font-size: 13px;">
                                </div>

                            </div>
                            <div class="form-group row">
                                <div class="col-lg-6">
                                    <label class="label">Sitede Göster</label>
                                    <div class="checkbox checkbox-primary col-xl-8 col-md-7">
                                        <input id="checkbox-primary-2" type="checkbox" name="IsActive"
                                            #IsActive="ngModel" [(ngModel)]="EditInstagramModel.IsActive">
                                        <label for="checkbox-primary-2">Aktif</label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label class="label">Kaçıncı Sıra</label>
                                    <input class="form-control" type="text" name="OrderNo" #OrderNo="ngModel"
                                        [(ngModel)]="EditInstagramModel.OrderNo" style="font-size: 13px;">
                                </div>
                            </div>
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isEditing"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Düzenle<span
                                        *ngIf="isEditing" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
