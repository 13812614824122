import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateShipmentComponent } from './shipment-definitions/create-shipment/create-shipment.component';
import { EditShipmentComponent } from './shipment-definitions/edit-shipment/edit-shipment.component';
import { ShipmentListComponent } from './shipment-definitions/shipment-list/shipment-list.component';

import { CreatePaymentComponent } from './payment-definitions/create-payment/create-payment.component';
import { EditPaymentComponent } from './payment-definitions/edit-payment/edit-payment.component';
import { PaymentListComponent } from './payment-definitions/payment-list/payment-list.component';

import { CreateOrderStatusComponent } from './order-status-definitions/create-order-status/create-order-status.component';
import { EditOrderStatusComponent } from './order-status-definitions/edit-order-status/edit-order-status.component';
import { OrderStatusListComponent } from './order-status-definitions/order-status-list/order-status-list.component';

import { CreateTransportComponent } from './transport-definitions/create-transport/create-transport.component';
import { EditTransportComponent } from './transport-definitions/edit-transport/edit-transport.component';
import { TransportListComponent } from './transport-definitions/transport-list/transport-list.component';

import { CreateCountryComponent } from './country-definitions/create-country/create-country.component';
import { EditCountryComponent } from './country-definitions/edit-country/edit-country.component';
import { CountryListComponent } from './country-definitions/country-list/country-list.component';


import { CreateAdditionalServicesComponent } from './additional-services-definitions/create-additional-services/create-additional-services.component';
import { EditAdditionalServicesComponent } from './additional-services-definitions/edit-additional-services/edit-additional-services.component';
import { AdditionalServicesListComponent } from './additional-services-definitions/additional-services-list/additional-services-list.component';

import { CreateRoleComponent } from './role-definitions/create-role/create-role.component';
import { EditRoleComponent } from './role-definitions/edit-role/edit-role.component';
import { RoleListComponent } from './role-definitions/role-list/role-list.component';

import { CreateBannerTypeComponent } from './banner-type-definitions/create-banner-type/create-banner-type.component';
import { EditBannerTypeComponent } from './banner-type-definitions/edit-banner-type/edit-banner-type.component';
import { BannerTypeListComponent } from './banner-type-definitions/banner-type-list/banner-type-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'shipment-list',
        component: ShipmentListComponent,
        data: {
          title: "Shipment List",
          breadcrumb: "Shipment List"
        }
      },
      {
        path: 'create-shipment',
        component: CreateShipmentComponent,
        data: {
          title: "Create Shipment",
          breadcrumb: "Create Shipment"
        }
      },
      {
        path: 'edit-shipment',
        component: EditShipmentComponent,
        data: {
          title: "Edit Shipment",
          breadcrumb: "Edit Shipment"
        }
      },
      {
        path: 'banner-type-list',
        component: BannerTypeListComponent,
        data: {
          title: "BannerType List",
          breadcrumb: "BannerType List"
        }
      },
      {
        path: 'create-banner-type',
        component: CreateBannerTypeComponent,
        data: {
          title: "Create BannerType",
          breadcrumb: "Create BannerType"
        }
      },
      {
        path: 'edit-banner-type',
        component: EditBannerTypeComponent,
        data: {
          title: "Edit BannerType",
          breadcrumb: "Edit BannerType"
        }
      },
      {
        path: 'payment-list',
        component: PaymentListComponent,
        data: {
          title: "Payment List",
          breadcrumb: "Payment List"
        }
      },
      {
        path: 'create-payment',
        component: CreatePaymentComponent,
        data: {
          title: "Create Payment",
          breadcrumb: "Create Payment"
        }
      },
      {
        path: 'edit-payment',
        component: EditPaymentComponent,
        data: {
          title: "Edit Payment",
          breadcrumb: "Edit Payment"
        }
      },
      {
        path: 'order-status-list',
        component: OrderStatusListComponent,
        data: {
          title: "Order Status List",
          breadcrumb: "Order Status List"
        }
      },
      {
        path: 'create-order-status',
        component: CreateOrderStatusComponent,
        data: {
          title: "Create Order Status",
          breadcrumb: "Create Order Status"
        }
      },
      {
        path: 'edit-order-status',
        component: EditOrderStatusComponent,
        data: {
          title: "Edit Order Status",
          breadcrumb: "Edit Order Status"
        }
      },
      {
        path: 'transport-list',
        component: TransportListComponent,
        data: {
          title: "Transport Way List",
          breadcrumb: "Transport Way List"
        }
      },
      {
        path: 'create-transport',
        component: CreateTransportComponent,
        data: {
          title: "Create Transport Way",
          breadcrumb: "Create Transport Way"
        }
      },
      {
        path: 'edit-transport',
        component: EditTransportComponent,
        data: {
          title: "Edit Transport Way",
          breadcrumb: "Edit Transport Way"
        }
      },
      {
        path: 'country-list',
        component: CountryListComponent,
        data: {
          title: "Country List",
          breadcrumb: "Country List"
        }
      },
      {
        path: 'create-country',
        component: CreateCountryComponent,
        data: {
          title: "Create Country",
          breadcrumb: "Create Country"
        }
      },
      {
        path: 'edit-country',
        component: EditCountryComponent,
        data: {
          title: "Edit Country",
          breadcrumb: "Edit Country"
        }
      },
      {
        path: 'additional-services-list',
        component: AdditionalServicesListComponent,
        data: {
          title: "AdditionalServices List",
          breadcrumb: "Additional Services List"
        }
      },
      {
        path: 'create-additional-services',
        component: CreateAdditionalServicesComponent,
        data: {
          title: "Create Additional Services",
          breadcrumb: "Create Additional Services"
        }
      },
      {
        path: 'edit-additional-services',
        component: EditAdditionalServicesComponent,
        data: {
          title: "Edit Additional Services",
          breadcrumb: "Edit Additional Services"
        }
      },

      {
        path: 'role-list',
        component: RoleListComponent,
        data: {
          title: "Role List",
          breadcrumb: "Role List"
        }
      },
      {
        path: 'create-role',
        component: CreateRoleComponent,
        data: {
          title: "Create Role",
          breadcrumb: "Create Role"
        }
      },
      {
        path: 'edit-role',
        component: EditRoleComponent,
        data: {
          title: "Edit Role",
          breadcrumb: "Edit Role"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DefinitionRoutingModule { }
