import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {
  isAdding = false;
  CreatePaymentModel: any = {};
  PaymentList;
  myDate;

  constructor(private router: Router,private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    this.myDate = new Date();
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['PaymentTypesDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }


  AddPayment() {
    this.isAdding = true;
    const body = { PaymentNameEN : this.CreatePaymentModel.PaymentNameEN, PaymentNameTR  : this.CreatePaymentModel.PaymentNameTR ,PaymentNameRU  : this.CreatePaymentModel.PaymentNameRU ,
      HasWebService  : this.CreatePaymentModel.HasWebService ,RedirectURL  : this.CreatePaymentModel.RedirectURL};
    this.http.post<any>(environment.url + 'Payment/AddPaymentTypes', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Ödeme Tipi Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/payment-list"]);
      }
    },
      error => {
        this.toastr.error('Ödeme Tipi Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
