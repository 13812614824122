import { DatePipe, formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-banner-type',
  templateUrl: './create-banner-type.component.html',
  styleUrls: ['./create-banner-type.component.scss']
})
export class CreateBannerTypeComponent implements OnInit {
  isAdding = false;
  CreateBannerTypeModel: any = {};
  BannerTypeList;
  myDate;

  constructor(private router: Router,private datePipe: DatePipe, private http: HttpClient, private toastr: ToastrService,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['BannerManagement']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
  }

  

  AddBannerType() {
    this.isAdding = true;
    const body = { BannerType : this.CreateBannerTypeModel.BannerType , RowTypeStr : this.CreateBannerTypeModel.RowTypeStr , SizeWH : this.CreateBannerTypeModel.SizeWH };
    this.http.post<any>(environment.url + 'Banner/AddBannerType', body).subscribe(data => {
      if (!data.Error) {
        this.isAdding = false;
        this.toastr.success("Banner Lokasyon Tipi Eklendi !", '', {
          timeOut: 2000,
        });
        this.router.navigate(["/home/definitions/banner-type-list"]);
      }
    },
      error => {
        this.toastr.error('Banner Lokasyon Tipi Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });

  }

}
