<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Order Status Lists</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <a class="btn btn-primary mr-1 mb-3" routerLinkActive="active" [routerLink]="'../create-order-status'"
              style="float: right;text-transform: none;font-size: 11px;font-weight: 500;">Yeni Sipariş Durumu Oluştur</a>
            <div class="clearfix"></div>

            <div class="table-responsive-sm">
              <table class="table" style="text-align: center;" *ngIf="isLoading">
                <thead>
                  <tr>
                    <th scope="col">English</th>
                    <th scope="col">Türkçe</th>
                    <th scope="col">Russian</th>
                    <th scope="col">Düzenle</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let orderstatus of OrderStatusList;let i = index">
                    <td>{{orderstatus.StatusTextEN}}</td>
                    <td>{{orderstatus.StatusTextTR}}</td>
                    <td>{{orderstatus.StatusTextRU}}</td>
                  
                    <td><a type="button" (click)="gotoOrderStatusEdit(orderstatus.StatusRef)"><i class="fa fa-edit"
                          style="font-size: 17px;"></i></a></td>
                    <td>
                      <a type="button" (click)="DeleteOrderStatusModal(deleteorderstatus,orderstatus.StatusRef)"
                        *ngIf="DeletingItem != orderstatus.StatusRef"><i class="fa fa-trash" style="font-size: 17px;"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                style="left: 50%;position: relative;"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteorderstatus let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="CloseDeleteModalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      Sipariş Durumunu Silmek İstediğinizden Emin misiniz ?
    </div>
    <div class="modal-footer" style="text-align: center;display: block;">
      <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeleting"
        (click)="DeleteOrderStatus()">Sil<span *ngIf="isDeleting"
          class="spinner-border spinner-border-sm ml-1"></span></button>
      <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
        (click)="CloseDeleteModalClose()">Vazgeç</button>
    </div>
  </div>
</ng-template>