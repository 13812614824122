<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header" style="padding: 20px 30px;">
                    <h5>SEO Yönetimi</h5>
                </div>
                <ngb-tabset class="tab-coupon">
                    <ngb-tab title="Menü SEO">
                        <ng-template ngbTabContent>
                            <div class="card-body" style="padding: 10px 30px;">
                                <div class="row mb-4">
                                    <div class="col-6">
                                        <label class="label" style="margin-bottom: 10px;">Mega Menü</label>
                                        <select class="form-control select" name="MegaMenuRef" #MegaMenuRef
                                            [(ngModel)]="Model.MegaMenuRef"
                                            (change)="MegaMenuOnChange($event.target.value)">
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option *ngFor="let item of MegaMenuList" [value]="item.Ref">{{
                                                item.MenuName }} - {{
                                                item.LanguageCode }}</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <label class="label" style="margin-bottom: 10px;">Sub Menü</label>
                                        <select class="form-control select" name="SubMenuRef" #SubMenuRef
                                            [disabled]="!Model.MegaMenuRef" [(ngModel)]="Model.SubMenuRef"
                                            (change)="SubMenuOnChange($event.target.value)">
                                            <option selected value="0">Yok</option>
                                            <option *ngFor="let item of SubMenuList" [value]="item.Ref">{{ item.MenuTag
                                                }} - {{
                                                item.LanguageCode }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="seo-div mb-4" [@slideIn]
                                    *ngIf="Model.MegaMenuRef && Model.SubMenuRef && !isChangeSubMenuOrMegaMenu">
                                    <h4 class="column-header">SEO BİLGİLERİ</h4>
                                    <hr />
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Title</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="MetaTitle" [(ngModel)]="Model.MetaTitle"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Keywords</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="MetaKeywords"
                                                        [(ngModel)]="Model.MetaKeywords"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <textarea class="form-control column-input" name="MetaDescription"
                                                        [(ngModel)]="Model.MetaDescription"
                                                        style="height: 200px"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Page Subject</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="PageSubject"
                                                        [(ngModel)]="Model.PageSubject"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Page Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <textarea class="form-control column-input" name="PageDescription"
                                                        [(ngModel)]="Model.PageDescription"
                                                        style="height: 200px"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="button" class="btn btn-primary" (click)="SaveMenuSeo()"
                                    [disabled]="loading"
                                    style="font-size: 11px;padding: 5px 15px;float: right;">Kaydet<span *ngIf="loading"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Anasayfa SEO">
                        <ng-template ngbTabContent>
                            <div class="card-body" style="padding: 10px 30px;">
                                <div class="row mb-4">
                                    <div class="col-6">
                                        <label class="label" style="margin-bottom: 10px;">Dil</label>
                                        <select class="form-control select " style="font-size: 13px;"
                                            (change)="LangCodeOnChange($event.target.value)"
                                            [(ngModel)]="MainModel.LangCode" name="LangCode" required>
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option value="TR">Türkçe</option>
                                            <option value="EN">English</option>
                                            <option value="RU">Russian</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="seo-div mb-4" [@slideIn] *ngIf="MainModel.LangCode && !isChangeMain">
                                    <h4 class="column-header">SEO BİLGİLERİ</h4>
                                    <hr />
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Title</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="MetaTitle"
                                                        [(ngModel)]="MainModel.MetaTitle"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Keywords</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="MetaKeywords"
                                                        [(ngModel)]="MainModel.MetaKeywords"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <textarea class="form-control column-input" name="MetaDescription"
                                                        [(ngModel)]="MainModel.MetaDescription"
                                                        style="height: 200px"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Page Subject</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="PageSubject"
                                                        [(ngModel)]="MainModel.PageSubject"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Page Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <textarea class="form-control column-input" name="PageDescription"
                                                        [(ngModel)]="MainModel.PageDescription"
                                                        style="height: 200px"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="button" class="btn btn-primary" (click)="SaveMainSeo()"
                                    [disabled]="loadingMain"
                                    style="font-size: 11px;padding: 5px 15px;float: right;">Kaydet<span
                                        *ngIf="loadingMain"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="URL Sayfası SEO">
                        <ng-template ngbTabContent>
                            <div class="card-body" style="padding: 10px 30px;">
                                <div class="row mb-4">
                                    <div class="col-6">
                                        <label class="label" style="margin-bottom: 10px;">Sayfa</label>
                                        <select class="form-control select" (change)="PageURLOnChange($event.target.value)" style="font-size: 13px;"
                                            [(ngModel)]="PageModel.PageURL" name="PageURL" required>
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option value="SoldOut">Tükenmiş Ürünler</option>
                                            <option value="NewProducts">Yeni Ürünler</option>
                                            <option value="DiscProducts">İndirimli Ürünler</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <label class="label" style="margin-bottom: 10px;">Dil</label>
                                        <select class="form-control select " style="font-size: 13px;"
                                            [disabled]="!PageModel.PageURL" (change)="PageLangCodeOnChange($event.target.value)"
                                            [(ngModel)]="PageModel.PageLangCode" name="PageLangCode" required>
                                            <option selected disabled [value]="undefined">Seçiniz</option>
                                            <option value="TR">Türkçe</option>
                                            <option value="EN">English</option>
                                            <option value="RU">Russian</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="seo-div mb-4" [@slideIn] *ngIf="PageModel.PageLangCode && !isChangePage">
                                    <h4 class="column-header">SEO BİLGİLERİ</h4>
                                    <hr />
                                    <div class="row">
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Title</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="MetaTitle"
                                                        [(ngModel)]="PageModel.MetaTitle"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Keywords</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="MetaKeywords"
                                                        [(ngModel)]="PageModel.MetaKeywords"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Meta Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <textarea class="form-control column-input" name="MetaDescription"
                                                        [(ngModel)]="PageModel.MetaDescription"
                                                        style="height: 200px"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Page Subject</span>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" name="PageSubject"
                                                        [(ngModel)]="PageModel.PageSubject"
                                                        class="form-control column-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-3">
                                                    <span>Page Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <textarea class="form-control column-input" name="PageDescription"
                                                        [(ngModel)]="PageModel.PageDescription"
                                                        style="height: 200px"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="button" class="btn btn-primary" (click)="SavePageSeo()"
                                    [disabled]="loadingPage"
                                    style="font-size: 11px;padding: 5px 15px;float: right;">Kaydet<span
                                        *ngIf="loadingPage"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>

            </div>
        </div>
    </div>
</div>