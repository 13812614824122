import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MenusRoutingModule } from './menus-routing.module';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { CreateMenuComponent } from './create-menu/create-menu.component';
import { CreateFooterMenuComponent } from './create-footer-menu/create-footer-menu.component';
import { EditFooterMenuComponent } from './edit-footer-menu/edit-footer-menu.component';
import { SEOComponent } from './seo/seo.component';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AccountMenuComponent } from './account-menu/account-menu.component';
import { FooterMenuComponent } from './footer-menu/footer-menu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    MegaMenuComponent,
    FooterMenuComponent,
    CreateFooterMenuComponent,
    CreateMenuComponent,
    EditFooterMenuComponent,
    EditMenuComponent,
    SEOComponent,
    AccountMenuComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    MenusRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ToastrModule.forRoot(),
  ],
})
export class MenusModule {}
