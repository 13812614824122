<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Transport Way</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="TransportForm" class="needs-validation" novalidate #TransportForm="ngForm"
                            (ngSubmit)="TransportForm.form.valid && AddTransport()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">English</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="TransportWayDefinitionEN" #TransportWayDefinitionEN="ngModel"
                                    [(ngModel)]="CreateTransportModel.TransportWayDefinitionEN "
                                    style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': TransportForm.submitted && TransportWayDefinitionEN.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Türkçe</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="TransportWayDefinitionTR" #TransportWayDefinitionTR="ngModel"
                                    [(ngModel)]="CreateTransportModel.TransportWayDefinitionTR" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': TransportForm.submitted && TransportWayDefinitionTR.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Russian</label>
                                <input class="form-control col-xl-8 col-md-7" type="text"
                                    name="TransportWayDefinitionRU" #TransportWayDefinitionRU="ngModel"
                                    [(ngModel)]="CreateTransportModel.TransportWayDefinitionRU" style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': TransportForm.submitted && TransportWayDefinitionRU.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">İcon</label>
                                <button type="button" class="btn btn-primary" (click)="openImage()"
                                    [disabled]="isUploadImage"
                                    style="font-size: 12px;line-height: 13px;padding: 9px 25px;">Yükle<span
                                        *ngIf="isUploadImage"
                                        class="spinner-border spinner-border-sm ml-1"></span></button>
                                <input type="file" class="addimage" id="addimage" accept='image/*'
                                    (change)="addImageChange($event.target.files)" style="display:none;" />
                                <img width="40" height="25" alt=""
                                    style="margin-right:10px;border-radius: 5px;margin-left: 20px;" id="ImageBox"
                                    src="{{imageSrc}}" *ngIf="imageSrc">
                            </div>


                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Oluştur<span
                                        *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                        style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>

        </div>
    </div>
</div>
<!-- Container-fluid Ends-->