<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Order Status</h5>
        </div>
        <div class="card-body tab2-card">
            <ngb-tabset class="tab-coupon">
                <ngb-tab title="General">
                    <ng-template ngbTabContent>
                        <form name="OrderStatusForm" class="needs-validation" novalidate #OrderStatusForm="ngForm"
                            (ngSubmit)="OrderStatusForm.form.valid && AddOrderStatus()">
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">English</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="StatusTextEN" 
                                    #StatusTextEN="ngModel" [(ngModel)]="CreateOrderStatusModel.StatusTextEN " style="font-size: 13px;"
                                    [ngClass]="{ 'is-invalid': OrderStatusForm.submitted && StatusTextEN.invalid  }"
                                    required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Türkçe</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="StatusTextTR"
                                #StatusTextTR="ngModel" [(ngModel)]="CreateOrderStatusModel.StatusTextTR" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': OrderStatusForm.submitted && StatusTextTR.invalid  }"
                                required>
                            </div>
                            <div class="form-group row">
                                <label class="col-xl-3 col-md-4">Russian</label>
                                <input class="form-control col-xl-8 col-md-7" type="text" name="StatusTextRU"
                                #StatusTextRU="ngModel" [(ngModel)]="CreateOrderStatusModel.StatusTextRU" style="font-size: 13px;"
                                [ngClass]="{ 'is-invalid': OrderStatusForm.submitted && StatusTextRU.invalid  }"
                                required>
                            </div>
                          
                            <div class="pull-right">
                                <button type="submit" class="btn btn-primary" [disabled]="isAdding"
                                    style="margin-top: 10px;text-transform: none;font-family: 'Poppins';font-size: 12px;font-weight: 500;">Oluştur<span
                                    *ngIf="isAdding" class="spinner-border spinner-border-sm ml-1"
                                    style="margin-bottom: 2px;"></span></button>
                            </div>
                        </form>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
      
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->