<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Mega Menu Lists</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <a class="btn btn-primary mr-1 mb-3" routerLinkActive="active" [routerLink]="'../create-menu'"
              style="float: right;text-transform: none;font-size: 11px;font-weight: 500;">Mega Menu Oluştur</a>
            <div class="clearfix"></div>
            <div class="table-responsive-sm">
              <table class="table" style="text-align: center;" *ngIf="isLoading">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Language</th>
                    <th scope="col">Status</th>
                    <th scope="col">Sorting</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let menu of MenuList;let i = index">
                    <th scope="row">{{menu.Ref}}</th>
                    <td>{{menu.MenuName }} - {{menu.LanguageCode }}</td>
                    <td>{{menu.LanguageCode}} </td>
                    <td>
                      <div *ngIf="!menu.IsActive">
                        <i class="fa fa-circle font-warning f-12"></i>
                      </div>
                      <div *ngIf="menu.IsActive">
                        <i class="fa fa-circle font-success f-12"></i>
                      </div>
                    </td>
                    <td>{{menu.SortOrder}} </td>
                    <td><a type="button" (click)="gotoMegaMenuEdit(menu.Ref)"><i class="fa fa-edit"
                          style="font-size: 17px;"></i></a></td>
                    <td>
                      <a type="button" (click)="DeleteMegaMenuModal(deletemegamenu,menu.Ref)" *ngIf="DeletingItem != menu.Ref"><i class="fa fa-trash"
                          style="font-size: 17px;"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <span *ngIf="!isLoading" class="spinner-border spinner-border-sm ml-1"
                style="left: 50%;position: relative;"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deletemegamenu let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="CloseDeleteModalClose()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="text-align: center;">
      Sayfayı Silmek İstediğinizden Emin misiniz ?
    </div>
    <div class="modal-footer" style="text-align: center;display: block;">
      <button type="button" class="btn closebtn btn-lg" style="background: #2F80ED;width:47%" [disabled]="isDeleting"
        (click)="DeleteMegaMenu()">Sil<span *ngIf="isDeleting"
          class="spinner-border spinner-border-sm ml-1"></span></button>
      <button type="button" class="btn closebtn btn-lg" style="background: #FF4B55;width:47%"
        (click)="CloseDeleteModalClose()">Vazgeç</button>
    </div>
  </div>
</ng-template>
