import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-country',
  templateUrl: './edit-country.component.html',
  styleUrls: ['./edit-country.component.scss']
})
export class EditCountryComponent implements OnInit {
  public editorValue: string = '';
  id;
  isEditing = false;
  EditCountryModel: any = {};
  MenuList;
  CityList: any = [];
  EditModelItem: any = {};
  AddModelItem: any = {};
  IsEditingCity = false;
  IsAdding = false;
  EditItemID;
  isDeleting;
  DeletingItem;

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, private modalService: NgbModal, private router: Router, private toastr: ToastrService, private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['CountryDefinitions']) {
      router.navigateByUrl('/');
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(x => {
      this.id = x["id"];
      this.GetCountryDetail();
    });
  }

  GetCountryDetail() {
    this.http.get<any>(environment.url + 'Country/CountryList').subscribe(data => {
      if (data.length) {
        data.forEach(element => {
          if (element.Ref == this.id) {
            this.EditCountryModel.Name = element.Name;
            this.EditCountryModel.Code = element.Code;
            this.EditCountryModel.Code3 = element.Code3;
            this.EditCountryModel.PhoneCode = element.PhoneCode;
            this.http.get<any>(environment.url + 'Country/CityList?CountryRef=' + this.id).subscribe(data => {
              this.CityList = data;
            });
          }
        });
      }

    });
  }



  EditCountry() {
    this.isEditing = true;
    const body = { Ref: this.id,
      Name: this.EditCountryModel.Name, Code: this.EditCountryModel.Code, Code3: this.EditCountryModel.Code3, PhoneCode: this.EditCountryModel.PhoneCode

    };
    this.http.post<any>(environment.url + 'Country/UpdateCountry', body).subscribe(data => {
      if (!data.Error) {
        this.isEditing = false;
        this.toastr.success("Ülke Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Ülke Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      }
    },
      error => {
        this.toastr.error('Ülke Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });

  }

  OpenEditCity(ref, name) {
    this.IsEditingCity = true;
    this.EditItemID = ref;
    this.EditModelItem.Name = name;
  }
  AddNewCity() {
    this.CityList.unshift({ Name: "", CountryRef: this.id, Key: "New" });
    this.IsAdding = true;
  }
  OpenDeleteCity(file,item){
    this.DeletingItem = item;
    this.modalService.open(file, { centered: true });
  }
  DeleteCity() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItem.Ref };
    this.http.post<any>(environment.url + 'Country/DeleteCity', body).subscribe(data => {
      if (!data.Error) {
        this.isDeleting = false;
        let index = this.CityList.indexOf(this.CityList.find(x => x.Ref === this.DeletingItem.Ref));
        this.CityList.splice(index, 1);
        this.toastr.success("Ülke Silindi", '', {
          timeOut: 3000,
        });
        this.modalService.dismissAll();
      }
      else {
        this.isDeleting = false;
        this.toastr.error("Ülke Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
  AddCity() {
    const body = { CountryRef: this.id, Name: this.AddModelItem.Name };
    this.http.post<any>(environment.url + 'Country/AddCity', body).subscribe(data => {
      if (!data.Error) {
        this.CityList[0].Name = this.AddModelItem.Name;
        this.CityList[0].Ref = data.Ref;
        this.CityList[0].Key = null;
        this.AddModelItem.Name = null;
        this.IsAdding = false;
        this.toastr.success("Şehir Eklendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Şehir Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.IsAdding = false;
      }
    },
      error => {
        this.toastr.error('Şehir Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.IsAdding = false;
      });
  }
  SaveCity() {
    const body = { Ref: this.EditItemID, CountryRef: this.id, Name: this.EditModelItem.Name };
    this.http.post<any>(environment.url + 'Country/UpdateCity', body).subscribe(data => {
      if (!data.Error) {
        let index = this.CityList.indexOf(this.CityList.find(x => x.Ref === this.EditItemID));
        this.CityList[index].Name = this.EditModelItem.Name;
        this.EditModelItem.Name = null;
        this.IsEditingCity = false;
        this.EditItemID = null;
        this.IsAdding = false;
        this.toastr.success("Şehir Güncellendi !", '', {
          timeOut: 2000,
        });
      }
      else {
        this.toastr.error('Şehir Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.EditModelItem.Name = null;
        this.IsEditingCity = false;
        this.IsAdding = false;
        this.EditItemID = null;
      }
    },
      error => {
        this.toastr.error('Şehir Güncellenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.EditModelItem.Name = null;
        this.IsEditingCity = false;
        this.IsAdding = false;
        this.EditItemID = null;
      });

  }
  CancelCity() {
    if (this.IsAdding) {
      this.CityList.splice(0, 1);
    }
    this.IsEditingCity = false;
    this.IsAdding = false;
    this.EditItemID = null;
    this.AddModelItem.Name = null;
    this.EditModelItem.Name = null;
  }

}
