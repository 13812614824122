import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {

  public menus = [];
  public selected = [];
  UserList;
  isLoading;
  isDeleting;
  DeletingItem;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal, private TokenService: TokenService) {
    if (!this.TokenService.getUser().IsAdmin) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.GetUserList();
  }

  GetUserList() {
    this.http.get<any>(environment.url + 'User/GetUsers').subscribe(data => {
      this.UserList = data;
      this.isLoading = true;
    });
  }
  DeleteUser() {
    this.isDeleting = true;
    var body = { UserId: this.DeletingItem };
    this.http.post<any>(environment.url + 'User/DeleteUser', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetUserList();
      if (!data.Error) {
        this.modalService.dismissAll();
        this.toastr.success("Kullanıcı Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Kullanıcı Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  gotoUserEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/setting/edit-admin-user'], navigatioExtra);
  }
  DeleteUserModal(file, ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose() {
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
}
