import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/shared/service/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit {

  public menus = [];
  public selected = [];
  ShipmentList;
  isLoading;
  isDeleting;
  DeletingItem;

  constructor(private router: Router, private http: HttpClient, private toastr: ToastrService, private modalService: NgbModal,private TokenService: TokenService) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['CargoTypeDefinitions']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.GetShipmentList();
  }

  GetShipmentList() {
    this.http.get<any>(environment.url + 'Shipment/GetShipmentMethods').subscribe(data => {
      this.ShipmentList = data;
      this.isLoading = true;
    });
  }
  DeleteShipment() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItem };
    this.http.post<any>(environment.url + 'Shipment/DeleteShipment', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.GetShipmentList();
      if (!data.Error) {
        this.modalService.dismissAll();
        this.toastr.success("Kargo Tanımı Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Kargo Tanımı Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  gotoShipmentEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/definitions/edit-shipment'], navigatioExtra);
  }
  DeleteShipmentModal(file,ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
  }
}
