import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TokenService } from 'src/app/shared/service/token.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mail-content-list',
  templateUrl: './mail-content-list.component.html',
  animations: [
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(500, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(500, style({ height: '*' }))
      ])
    ])
  ],
  styleUrls: ['./mail-content-list.component.scss']
})
export class MailContentListComponent implements OnInit {

  public menus = [];
  public selected = [];

  isLoading;
  MailContents;
  SMTPTemplates;
  isLoadingSMTP;
  AddSMTPModel: any = {};
  EditSMTPModel: any = {};
  isAddingSMTP;
  isAdding;
  isEditingSMTP;
  isEditing;
  isDeleting;
  DeletingItem;

  CCList;
  isLoadingCCList;
  DeletingItemCC;
  isDeletingCC;
  isAddingCC;
  isEditingCC;
  EditCCModel: any = {};
  AddCCModel: any = {};
  DeletingItemMail;

  constructor(private router: Router,private modalService: NgbModal, private TokenService: TokenService, private toastr: ToastrService,private http: HttpClient) {
    const a = JSON.parse(this.TokenService.getUser().Authority);
    if (!a['MailContents']) {
      router.navigateByUrl('/');
    }
  }


  ngOnInit() {
    this.GetMailTemplates();
    this.GetSMTPTemplates();
    this.GetCCList();
  }
  GetMailTemplates() {
    this.http.get<any>(environment.url + 'Mail/GetMailTemplates').subscribe(data => {
      this.MailContents = data.MailTemplate;
      this.MailContents.sort((a, b) => {
        if (a['LangCode'] != null && b['LangCode'] != null) {
          if (a['LangCode'].toLowerCase() > b['LangCode'].toLowerCase()) {
            return -1;
          }
          if (b['LangCode'].toLowerCase() > a['LangCode'].toLowerCase()) {
            return 1;
          }
        }
        else {
          return 1;
        }
        return 0;
      });
      this.isLoading = true;
    });
  }
  GetSMTPTemplates() {
    this.http.get<any>(environment.url + 'Mail/GetSMTPTemplates').subscribe(data => {
      this.SMTPTemplates = data.SMTP;
      this.isLoadingSMTP = true;
    });
  }
  GetCCList(){
    this.http.get<any>(environment.url + 'Mail/GetMailCCList').subscribe(data => {
      this.CCList = data.MailTemplate;
      this.isLoadingCCList = true;
    });
  }


  OpenNewSMTP() {
    if (!this.isAddingSMTP) {
      this.isEditingSMTP = false;
      setTimeout(() => {
        this.EditSMTPModel = {};
        this.isAddingSMTP = true;
      }, 300);
    }
    else {
      this.isAddingSMTP = false;
    }
  }
  AddNewSMTP() {
    this.isAdding = true;

    const body = { TemplateName: this.AddSMTPModel.TemplateName, SMTPMail: this.AddSMTPModel.SMTPMail , IsActive: this.AddSMTPModel.IsActive, SMTPServer: this.AddSMTPModel.SMTPServer,
      SMTPUser: this.AddSMTPModel.SMTPUser, SMTPPass: this.AddSMTPModel.SMTPPass , UseSSL: this.AddSMTPModel.UseSSL, Port: this.AddSMTPModel.Port };
    this.http.post<any>(environment.url + 'Mail/AddSMTPTemplate', body).subscribe(data => {
      if (data) {
        this.isAdding = false;
        this.isAddingSMTP = false;
        this.AddSMTPModel = {};
        this.toastr.success("SMTP Eklendi !", '', {
          timeOut: 2000,
        });
        this.GetSMTPTemplates();
      }
    },
      error => {
        this.toastr.error('SMTP Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });
  }
  OpenEditSMTP(item) {
    if (this.isEditingSMTP && this.EditSMTPModel.Ref == item.Ref) {
      this.isEditing = false;
    }
    else {
      this.EditSMTPModel = Object.assign({}, item);
      this.isAddingSMTP = false;
      this.isEditingSMTP = false;
      setTimeout(() => {
        this.isEditingSMTP = true;
        this.AddSMTPModel = {};
      }, 300);
    }
  }
  EditSMTP() {
    this.isEditing = true;

    const body = { Ref: this.EditSMTPModel.Ref, TemplateName: this.EditSMTPModel.TemplateName, SMTPMail: this.EditSMTPModel.SMTPMail , IsActive: this.EditSMTPModel.IsActive, SMTPServer: this.EditSMTPModel.SMTPServer,
      SMTPUser: this.EditSMTPModel.SMTPUser, SMTPPass: this.EditSMTPModel.SMTPPass , UseSSL: this.EditSMTPModel.UseSSL, Port: this.EditSMTPModel.Port };
    this.http.post<any>(environment.url + 'Mail/UpdateSMTPTemplate', body).subscribe(data => {
      if (data) {
        this.isEditing = false;
        this.isEditingSMTP = false;
        this.EditSMTPModel = {};
        this.toastr.success("SMTP Düzenlendi !", '', {
          timeOut: 2000,
        });
        this.GetSMTPTemplates();
      }
    },
      error => {
        this.toastr.error('SMTP Düzenlenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });
  }

  DeleteSMTP() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItem };
    this.http.post<any>(environment.url + 'Mail/DeleteSMTPTemplate', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItem = "";
      this.isAddingSMTP = false;
      this.isEditingSMTP = false;
      this.EditSMTPModel = {};
      if (data) {
        this.modalService.dismissAll();
        this.GetSMTPTemplates();
        this.toastr.success("SMTP Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("SMTP Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  DeleteSMTPModal(file,ref) {
    this.DeletingItem = ref;
    this.modalService.open(file, { centered: true });
  }

  

  OpenNewCC() {
    if (!this.isAddingCC) {
      this.isEditingCC = false;
      setTimeout(() => {
        this.EditCCModel = {};
        this.isAddingCC = true;
      }, 300);
    }
    else {
      this.isAddingCC = false;
    }
  }
  AddNewCC() {
    this.isAdding = true;

    const body = { Name: this.AddCCModel.Name, MailTo: this.AddCCModel.MailTo };
    this.http.post<any>(environment.url + 'Mail/AddMailCC', body).subscribe(data => {
      if (data) {
        this.isAdding = false;
        this.isAddingCC = false;
        this.AddCCModel = {};
        this.toastr.success("CC Eklendi !", '', {
          timeOut: 2000,
        });
        this.GetCCList();
      }
    },
      error => {
        this.toastr.error('CC Eklenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isAdding = false;
      });
  }
  OpenEditCC(item) {
    if (this.isEditingCC && this.EditCCModel.Ref == item.Ref) {
      this.isEditing = false;
    }
    else {
      this.EditCCModel = Object.assign({}, item);
      this.isAddingCC = false;
      this.isEditingCC = false;
      setTimeout(() => {
        this.isEditingCC = true;
        this.AddCCModel = {};
      }, 300);
    }
  }
  EditCC() {
    this.isEditing = true;

    const body = { Ref: this.EditCCModel.Ref, Name: this.EditCCModel.Name, MailTo: this.EditCCModel.MailTo };
    this.http.post<any>(environment.url + 'Mail/UpdateMailCC', body).subscribe(data => {
      if (data) {
        this.isEditing = false;
        this.isEditingCC = false;
        this.EditCCModel = {};
        this.toastr.success("CC Düzenlendi !", '', {
          timeOut: 2000,
        });
        this.GetCCList();
      }
    },
      error => {
        this.toastr.error('CC Düzenlenemedi!', 'Hata!', {
          timeOut: 2000,
        });
        this.isEditing = false;
      });
  }


  DeleteCC() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItemCC };
    this.http.post<any>(environment.url + 'Mail/DeleteMailCC', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItemCC = "";
      this.isAddingCC = false;
      this.isEditingCC = false;
      this.EditCCModel = {};
      if (data) {
        this.modalService.dismissAll();
        this.GetCCList();
        this.toastr.success("CC Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("CC Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  DeleteCCModal(file,ref) {
    this.DeletingItemCC = ref;
    this.modalService.open(file, { centered: true });
  }

  
  DeleteMail() {
    this.isDeleting = true;
    var body = { Ref: this.DeletingItemMail };
    this.http.post<any>(environment.url + 'Mail/DeleteMailTemplate', body).subscribe(data => {
      this.isDeleting = false;
      this.DeletingItemMail = "";
      this.EditCCModel = {};
      if (data) {
        this.modalService.dismissAll();
        this.GetMailTemplates();
        this.toastr.success("Mail Şablonu Silindi", '', {
          timeOut: 3000,
        });
      }
      else {
        this.toastr.error("Mail Şablonu Silinemedi", 'Hata !', {
          timeOut: 3000,
        });
      }
    });
  }
  DeleteMailModal(file,ref) {
    this.DeletingItemMail = ref;
    this.modalService.open(file, { centered: true });
  }
  CloseDeleteModalClose(){
    this.modalService.dismissAll();
    this.DeletingItem = "";
    this.DeletingItemCC = "";
  }
  gotoMailEdit(item: any) {
    const navigatioExtra: NavigationExtras = {
      queryParams: { id: item }
    }
    this.router.navigate(['/home/cms/edit-mail-content'], navigatioExtra);
  }

}
